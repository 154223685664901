/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
  Text,
  Box,
  Button,
  Select,
  Spinner,
  PageHeader,
  Breadcrumbs,
  useConfirm,
  Tooltip,
  IconButton,
  TextInput,
  Label,
  FormControl,
} from '@primer/react'
import {
  getDevice,
  getScreenshot,
  patchDevice,
  getPlaylists,
  getSchedules,
  getShowDeviceId,
  getReboot,
  getSilentUpdate,
  getEmptyStorage,
} from '@/lib/calls'
import { Icons } from '@/components/icons'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

export function DeviceDetailPage() {
  const [reloadContent, setReloadContent] = useState(false)
  const { deviceUUID } = useParams()
  const [device, setDevice] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [restricted, setRestricted] = useState(false)
  const [isLoadingScreenshot, setIsLoadingScreenshot] = useState(false)
  const [errorScreenshot, setErrorScreenshot] = useState(null)
  const [imageKey, setImageKey] = useState(Date.now())
  const [schedules, setSchedules] = useState([])
  const [playlists, setPlaylists] = useState([])
  const [extraInfo, setExtraInfo] = useState(null)
  const [scheduleError, setScheduleError] = useState(false)
  const [playlistError, setPlaylistError] = useState(false)
  const [isLoadingEmptyStorage, setIsLoadingEmptyStorage] = useState(false)
  const [isLoadingShowDeviceId, setIsLoadingShowDeviceId] = useState(false)
  const [latValue, setLatValue] = useState(null)
  const [lngValue, setLngValue] = useState(null)
  const [validationResultLat, setValidationResultLat] = useState('empty')
  const [validationResultLng, setValidationResultLng] = useState('empty')
  const [screenOrientationSelected, setScreenOrientationSelected] = useState(
    'choose_screen_orientation'
  )
  const [isLoadingReboot, setIsLoadingReboot] = useState(false)
  const [isLoadingSilentUpdate, setIsLoadingSilentUpdate] = useState(false)

  const handleScreeenOrientationChange = e => {
    setScreenOrientationSelected(e.currentTarget.value)
  }

  const handleSubmitReboot = async () => {
    setIsLoadingReboot(true)

    try {
      await getReboot(deviceUUID)
      // const response = await getEmptyStorage(deviceUUID)
      // console.log(response)
      toast.custom(t => (
        <CustomToast message='Device is set to reboot' type='success' />
      ))
    } catch (error) {
      console.error(error)
      toast.custom(t => (
        <CustomToast message='Error sending command to device' type='error' />
      ))
    } finally {
      setIsLoadingReboot(false)
    }
  }

  const handleSubmitSilentUpdate = async () => {
    setIsLoadingSilentUpdate(true)

    try {
      await getSilentUpdate(deviceUUID)
      toast.custom(t => (
        <CustomToast message='Device is set to silent update' type='success' />
      ))
    } catch (error) {
      console.error(error)
      toast.custom(t => (
        <CustomToast message='Error sending command to device' type='error' />
      ))
    } finally {
      setIsLoadingSilentUpdate(false)
    }
  }

  const handleSubmitShowDeviceId = async () => {
    setIsLoadingShowDeviceId(true)

    try {
      await getShowDeviceId(deviceUUID)
      toast.custom(t => (
        <CustomToast
          message='Device is set to be shown on screen'
          type='success'
        />
      ))
    } catch (error) {
      console.error(error)
      toast.custom(t => (
        <CustomToast message='Error sending command to device' type='error' />
      ))
    } finally {
      setIsLoadingShowDeviceId(false)
    }
  }

  const handleSubmitEmptyStorage = async () => {
    setIsLoadingEmptyStorage(true)

    try {
      await getEmptyStorage(deviceUUID)
      // const response = await getEmptyStorage(deviceUUID)
      // console.log(response)
      toast.custom(t => (
        <CustomToast message='Device is set to empty storage' type='success' />
      ))
    } catch (error) {
      console.error(error)
      toast.custom(t => (
        <CustomToast message='Error sending command to device' type='error' />
      ))
    } finally {
      setIsLoadingEmptyStorage(false)
    }
  }

  const confirm = useConfirm()

  const onButtonClick = useCallback(async () => {
    if (
      await confirm({
        title: 'Are you sure?',
        content: `You are about to delete the app local storage for device ${deviceUUID}. This action cannot be undone.`,
        confirmButtonContent: 'Delete it!',
        confirmButtonType: 'danger',
      })
    ) {
      handleSubmitEmptyStorage()
    }
  }, [confirm, deviceUUID])

  const onButtonClickReboot = useCallback(async () => {
    if (
      await confirm({
        title: 'Are you sure?',
        content: `You are about to reboot device ${deviceUUID}. Make sure the device is set as the default input source for the screen. This action works only on rooted Android devices with app version >= 1.0.6-android.`,
        confirmButtonContent: 'Reboot device',
        confirmButtonType: 'default',
      })
    ) {
      handleSubmitReboot()
    }
  }, [confirm, deviceUUID])

  const onButtonClickSilentUpdate = useCallback(async () => {
    if (
      await confirm({
        title: 'Are you sure?',
        content: `You are about to update the app version on device ${deviceUUID}. Make sure the device is connected to the internet. This action works only on rooted Android devices with app version >= 1.0.7-android.`,
        confirmButtonContent: 'Update app',
        confirmButtonType: 'default',
      })
    ) {
      handleSubmitSilentUpdate()
    }
  }, [confirm, deviceUUID])

  const onButtonClickShowDeviceId = useCallback(async () => {
    if (
      await confirm({
        title: 'Are you sure?',
        content: `You are about to put the device ${deviceUUID} in identification mode. A message with the device ID will be shown on screen.`,
        confirmButtonContent: 'Confirm',
        confirmButtonType: 'default',
      })
    ) {
      handleSubmitShowDeviceId()
    }
  }, [confirm, deviceUUID])

  const handleSubmitGetScreenshot = async () => {
    setIsLoadingScreenshot(true)
    let intervalId = null // This will hold the interval ID for clearing it later

    try {
      await getScreenshot(deviceUUID)
      // const response = await getScreenshot(deviceUUID)
      // console.log(response)
    } catch (error) {
      console.error(error)
      setErrorScreenshot(error)
    }

    const checkForScreenshotUpdate = async () => {
      try {
        const response = await getDevice(deviceUUID)
        const newDeviceData = response.data.data

        // Check if the ScreenshotPath has changed
        if (device && newDeviceData.ScreenshotPath !== device.ScreenshotPath) {
          // Update device state with the new device data
          setDevice(newDeviceData)
          // const newDeviceData = device.data.data;
          if (
            device &&
            newDeviceData.ScreenshotPath !== device.ScreenshotPath
          ) {
            setDevice(newDeviceData)
            setImageKey(Date.now()) // Update the key to force re-render
          }

          // Stop trying and clean up
          clearInterval(intervalId)
          setIsLoadingScreenshot(false)
          setErrorScreenshot(null) // Clear any previous errors
        }
      } catch (error) {
        // If there's an error (other than 403 which is handled in useEffect),
        // you might want to handle it differently or just log it
        console.error(error)
        setErrorScreenshot(error) // Set error state
      }
    }

    // Start the interval
    intervalId = setInterval(() => {
      checkForScreenshotUpdate()
    }, 15000) // Retry every 15 seconds

    // Immediately invoke once before the interval starts
    checkForScreenshotUpdate()
  }

  const handleSubmitUpdateDevice = async () => {
    if (
      validationResult === 'validName' &&
      validationResultLat === 'validLat' &&
      validationResultLng === 'validLng'
    ) {
      // Sanitize the values before making the API call
      let sanitizedScreenOrientation = screenOrientationSelected
      if (sanitizedScreenOrientation === 'choose_screen_orientation') {
        sanitizedScreenOrientation = null
      }

      // Convert lat/lng values to float64
      const sanitizedLatValue = latValue ? parseFloat(latValue) : null
      const sanitizedLngValue = lngValue ? parseFloat(lngValue) : null

      setIsLoading(true)
      try {
        await patchDevice(
          nameValue,
          deviceUUID,
          sanitizedLatValue,
          sanitizedLngValue,
          sanitizedScreenOrientation
        )
        // const response = await patchDevice(
        //   nameValue,
        //   deviceUUID,
        //   sanitizedLatValue,
        //   sanitizedLngValue,
        //   sanitizedScreenOrientation
        // )
        // console.log(response)
        setIsLoading(false)
        toast.custom(t => (
          <CustomToast message='Device updated' type='success' />
        ))
        setReloadContent(!reloadContent)
      } catch (error) {
        console.error(error)
        toast.custom(t => (
          <CustomToast message='Error updating device' type='error' />
        ))
      } finally {
        setIsLoading(false)
      }
    } else {
      toast.custom(t => <CustomToast message='Invalid input' type='error' />)
    }
  }
  function findScheduleAndPlaylist(deviceGroupId) {
    if (!deviceGroupId) {
      return null
    }
    // Find schedule by deviceGroupId
    const schedule = schedules.find(
      schedule => schedule.group_id === deviceGroupId
    )
    if (!schedule) {
      return null // No schedule found for the given deviceGroupId
    }

    // Find playlist by playlist_id from the found schedule
    const playlistEntry = playlists.find(
      playlist => playlist.playlist.id === schedule.playlist_id
    )
    if (!playlistEntry) {
      return { schedule } // Schedule found but no playlist found for the given playlist_id
    }

    return {
      schedule,
      playlist: playlistEntry.playlist,
    }
  }

  useEffect(() => {
    const fetchDevice = async () => {
      try {
        const device = await getDevice(deviceUUID)
        setDevice(device.data.data)
        const newDeviceData = device.data.data
        if (device && newDeviceData.ScreenshotPath !== device.ScreenshotPath) {
          setDevice(newDeviceData)
          setImageKey(Date.now()) // Update the key to force re-render
          setNameValue(newDeviceData.name)
          setLatValue(newDeviceData.latitude)
          setLngValue(newDeviceData.longitude)
          setScreenOrientationSelected(newDeviceData.screen_orientation)
        }
      } catch (error) {
        if (error.response.status === 403) {
          setRestricted(true)
        }
        setFetchError(error)
      } finally {
        setIsLoading(false)
      }
    }
    const fetchPlaylists = async () => {
      try {
        setIsLoading(true)
        const response = await getPlaylists() // Your API call to fetch items
        if (!response.error) {
          setIsLoading(false)
          setPlaylists(response.data)
        } else {
          setIsLoading(false)
          console.error('API returned an error:', response.msg)
          // Handle API error state here, e.g., set an error message state and display it
        }
      } catch (error) {
        setIsLoading(false)
        setPlaylistError(true)
        console.error('Failed to fetch playlists:', error)
        // Handle fetching error state here
      } finally {
        setIsLoading(false)
      }
    }

    const fetchSchedules = async () => {
      try {
        setIsLoading(true)
        const response = await getSchedules() // Your API call to fetch items
        if (!response.error) {
          setIsLoading(false)
          setSchedules(response.data)
        } else {
          setIsLoading(false)
          console.error('API returned an error:', response.msg)
          // Handle API error state here, e.g., set an error message state and display it
        }
      } catch (error) {
        setIsLoading(false)
        setScheduleError(true)
        console.error('Failed to fetch schedules:', error)
        // Handle fetching error state here
      } finally {
        setIsLoading(false)
      }
    }
    fetchDevice()
    fetchPlaylists()
    fetchSchedules()
  }, [reloadContent])

  const [nameValue, setNameValue] = useState('')
  const [validationResult, setValidationResult] = useState('empty')

  const handleInputChange = e => {
    setNameValue(e.currentTarget.value)
  }

  const handleInputChangeLat = e => {
    setLatValue(e.currentTarget.value)
  }

  const handleInputChangeLng = e => {
    setLngValue(e.currentTarget.value)
  }

  useEffect(() => {
    // console.log(selectedRoleId)
    if (nameValue.length === 0) {
      setValidationResult('empty')
    } else if (nameValue.length > 255) {
      setValidationResult('tooLong')
    } else if (nameValue.length < 3) {
      setValidationResult('tooShort')
    } else {
      setValidationResult('validName')
    }
  }, [nameValue])

  useEffect(() => {
    if (latValue === '' || latValue === null) {
      setValidationResultLat('validLat')
    } else if (!isNaN(latValue) && latValue >= -90 && latValue <= 90) {
      setValidationResultLat('validLat')
    } else {
      setValidationResultLat('invalidLat')
    }
  }, [latValue])

  useEffect(() => {
    if (lngValue === '' || lngValue === null) {
      setValidationResultLng('validLng')
    } else if (!isNaN(lngValue) && lngValue >= -180 && lngValue <= 180) {
      setValidationResultLng('validLng')
    } else {
      setValidationResultLng('invalidLng')
    }
  }, [lngValue])
  useEffect(() => {
    if (device && !scheduleError && !playlistError) {
      const extraInfo = findScheduleAndPlaylist(device.group_id)
      setExtraInfo(extraInfo)
    }
  }, [schedules, playlists, device])

  return (
    <>
      <Box sx={{}}>
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Breadcrumbs>
            <Link to='/'>
              <Breadcrumbs.Item href='#'>Home</Breadcrumbs.Item>
            </Link>
            <Link to='/dashboard/devices'>
              <Breadcrumbs.Item href='#'>Devices</Breadcrumbs.Item>
            </Link>
            <Breadcrumbs.Item href='#' selected>
              {device && device.name
                ? device.name.length > 25
                  ? `${device.name.slice(0, 25)}...`
                  : device.name
                : 'Device details'}
            </Breadcrumbs.Item>
          </Breadcrumbs>
        </Box>

        <Box
          sx={{
            padding: 3,
          }}
        >
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as='h2'>
                {device && device.name
                  ? device.name.length > 25
                    ? `${device.name.slice(0, 25)}...`
                    : device.name
                  : 'Device details'}
              </PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Description
              sx={{
                fontSize: 1,
                color: 'fg.muted',
              }}
            >
              Device details
            </PageHeader.Description>
          </PageHeader>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                // justifyContent: 'center',
                // alignContent: 'center',
                alignItems: 'center',
                fontSize: 1,
                color: 'attention.fg',
                ml: 0.5,
              }}
            >
              {/* <Icons.Alert />

              <Text>Beta</Text> */}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            width: '100%',
            height: '100%',
            marginTop: 4,
            // backgroundColor: 'canvas.default',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              // backgroundColor: 'green'
            }}
          >
            {fetchError && !isLoading && !restricted && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Text>Error fetching device details</Text>
                <Button
                  onClick={() => {
                    // setIsLoading(true);
                    // setFetchError(null);
                    window.location.reload()
                  }}
                >
                  Retry
                </Button>
              </Box>
            )}

            {restricted && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Text>Access Denied</Text>
              </Box>
            )}

            {isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                {/* <Text>Loading device details...</Text> */}
                <Spinner />
              </Box>
            )}

            {device && !isLoading && !fetchError && !restricted && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: ['column', 'row'],
                  gap: 4,
                  // backgroundColor: 'canvas.default',
                  // height: '500px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // backgroundColor: 'danger.muted',
                    width: ['100%', '50%'],
                    gap: 2,
                    // height: '800px',
                    overflow: 'auto',
                  }}
                >
                  <FormControl required>
                    <FormControl.Label htmlFor='deviceName'>
                      Device name
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      id='deviceName'
                      value={nameValue}
                      onChange={handleInputChange}
                    />
                    {validationResult === 'empty' && (
                      <FormControl.Validation variant='error'>
                        Name cannot be empty
                      </FormControl.Validation>
                    )}
                    {validationResult === 'tooLong' && (
                      <FormControl.Validation variant='error'>
                        Name cannot be longer than 255 characters
                      </FormControl.Validation>
                    )}
                    {validationResult === 'tooShort' && (
                      <FormControl.Validation variant='error'>
                        Name cannot be less than 3 characters
                      </FormControl.Validation>
                    )}
                    {validationResult === 'validName' && (
                      <FormControl.Validation variant='success'>
                        Valid name
                      </FormControl.Validation>
                    )}
                    <FormControl.Caption>
                      Please enter a name (3 to 255 characters).
                    </FormControl.Caption>
                  </FormControl>
                  <FormControl>
                    <FormControl.Label htmlFor='deviceId'>
                      Device id
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      disabled
                      id='deviceId'
                      value={device.id}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label htmlFor='deviceId'>
                      Device serial number
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      disabled
                      id='deviceSerialNumber'
                      value={device.device_unique_id}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label htmlFor='deviceOs'>
                      Device OS
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      disabled
                      id='deviceOs'
                      value={device.device_os}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label htmlFor='deviceManufacturer'>
                      Device manufacturer
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      disabled
                      id='deviceManufacturer'
                      value={device.device_manufacturer}
                    />
                  </FormControl>
                  {/* <FormControl>
                    <FormControl.Label htmlFor="deviceBrand">
                      Device brand
                    </FormControl.Label>
                    <TextInput
                      disabled
                      id="deviceBrand"
                      value={device.device_brand}
                    />
                  </FormControl> */}
                  <FormControl>
                    <FormControl.Label htmlFor='deviceSystemName'>
                      Device system name
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      disabled
                      id='deviceSystemName'
                      value={`${device.device_system_name} - version ${device.device_system_version}`}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label htmlFor='deviceInternalName'>
                      Device internal name
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      disabled
                      id='deviceInternalName'
                      value={device.device_name}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label htmlFor='deviceResultion'>
                      Device resolution
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      disabled
                      id='deviceResultion'
                      value={`${device.device_screen_width}x${device.device_screen_height}`}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label htmlFor='deviceEnrolledAt'>
                      Enrolled at
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      disabled
                      id='deviceEnrolledAt'
                      value={`${device.device_enrolled_at}`}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label htmlFor='deviceGroupId'>
                      <Link
                        to={`/dashboard/device-groups?device_group_id=${device.group_id}`}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 1,
                          alignItems: 'center',
                        }}
                      >
                        <Icons.LinkIcon sx={{ mr: 1, size: 14 }} />
                        Group id
                      </Link>
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      disabled
                      id='deviceGroupId'
                      value={`${device.group_id}`}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label htmlFor='deviceSyncRuleId'>
                      Sync rule id
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      disabled
                      id='deviceSyncRuleId'
                      value={`${device.sync_rule_id}`}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label htmlFor='deviceLastSeen'>
                      Last seen
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      disabled
                      id='deviceLastSeen'
                      value={`${device.last_seen}`}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label htmlFor='appVersion'>
                      App version
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      disabled
                      id='appVersion'
                      value={device.app_version}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label htmlFor='orientation'>
                      Screen orientation note
                    </FormControl.Label>
                    <FormControl.Caption
                      sx={{
                        color: 'attention.fg',
                      }}
                    >
                      To avoid complications, the screen orientation setting
                      should remain a note on how the screen is positioned. This
                      option does not convert or rotate the content. Appropiate
                      media content for the display must be set.
                    </FormControl.Caption>

                    <Select
                      sx={{
                        width: '100%',
                      }}
                      defaultValue={
                        device.screen_orientation || 'choose_screen_orientation'
                      }
                      onChange={handleScreeenOrientationChange}
                    >
                      <Select.Option value='choose_screen_orientation' disabled>
                        Choose screen orientation
                      </Select.Option>

                      <Select.Option value='landscape'>Landscape</Select.Option>
                      <Select.Option value='portrait'>Portrait</Select.Option>
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormControl.Label htmlFor='volume'>
                      Audio volume control
                    </FormControl.Label>
                    <FormControl.Caption
                      sx={{
                        color: 'attention.fg',
                      }}
                    >
                      Audio volume control for this device is not supported.
                    </FormControl.Caption>

                    <input
                      type='range'
                      min='0'
                      max='100'
                      value={device.audio_volume ? device.audio_volume : '0'}
                      step='1'
                    />
                  </FormControl>

                  <FormControl>
                    <FormControl.Label htmlFor='latitude'>
                      Latitude
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      id='latitude'
                      value={latValue}
                      onChange={handleInputChangeLat}
                    />
                    {validationResultLat === 'invalidLat' && (
                      <FormControl.Validation variant='error'>
                        Latitude is invalid
                      </FormControl.Validation>
                    )}
                    {validationResultLat === 'validLat' && (
                      <FormControl.Validation variant='success'>
                        Valid latitude
                      </FormControl.Validation>
                    )}
                    <FormControl.Caption>
                      Please enter latitude
                    </FormControl.Caption>
                  </FormControl>

                  <FormControl>
                    <FormControl.Label htmlFor='longitude'>
                      Longitude
                    </FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      id='longitude'
                      value={lngValue}
                      onChange={handleInputChangeLng}
                    />
                    {validationResultLng === 'invalidLng' && (
                      <FormControl.Validation variant='error'>
                        Longitude is invalid
                      </FormControl.Validation>
                    )}
                    {validationResultLng === 'validLng' && (
                      <FormControl.Validation variant='success'>
                        Valid longitude
                      </FormControl.Validation>
                    )}
                    <FormControl.Caption>
                      Please enter longitude
                    </FormControl.Caption>
                  </FormControl>

                  {/* <Box
                    sx={{
                      border: "1px solid",
                      borderColor: "border.default",
                      borderRadius: 2,
                      backgroundColor: "canvas.default",
                      height: "375px",
                      overflow: "auto",
                      mt: 3,
                      padding: 2,
                    }}
                  >
                    <pre>{JSON.stringify(device, null, 2)}</pre>
                  </Box> */}
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'row',
                      // backgroundColor: 'canvas.default',
                      mt: 4,
                      justifyContent: 'end',
                    }}
                  >
                    <Button
                      variant='primary'
                      disabled={isLoading}
                      onClick={() => {
                        handleSubmitUpdateDevice()
                      }}
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        width: '65px', // Set a fixed width
                      }}
                    >
                      {isLoading ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '47px',
                          }}
                        >
                          <Spinner size='small' />
                        </Box>
                      ) : (
                        <Text>Save changes</Text>
                      )}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      // backgroundColor: 'canvas.default',
                    }}
                  >
                    <Text
                      sx={{
                        fontSize: 1,
                        color: 'fg.muted',
                      }}
                    >
                      Device actions
                    </Text>
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        // backgroundColor: 'canvas.default',
                        gap: 3,
                        mt: 3,
                        alignItems: 'flex-end',
                      }}
                    >
                      <Button
                        variant='danger'
                        disabled={isLoadingEmptyStorage}
                        onClick={onButtonClick}
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                          alignItems: 'center',
                          width: '65px', // Set a fixed width
                        }}
                      >
                        {isLoadingEmptyStorage ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '47px',
                            }}
                          >
                            <Spinner size='small' />
                          </Box>
                        ) : (
                          <Text>Empty storage</Text>
                        )}
                      </Button>

                      <Button
                        variant='default'
                        disabled={isLoadingShowDeviceId}
                        onClick={onButtonClickShowDeviceId}
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                          alignItems: 'center',
                          width: '65px', // Set a fixed width
                        }}
                      >
                        {isLoadingShowDeviceId ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '47px',
                            }}
                          >
                            <Spinner size='small' />
                          </Box>
                        ) : (
                          <Text>Show device id</Text>
                        )}
                      </Button>

                      <Button
                        variant='default'
                        disabled={isLoadingSilentUpdate}
                        onClick={onButtonClickSilentUpdate}
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                          alignItems: 'center',
                          width: '65px', // Set a fixed width
                        }}
                      >
                        {isLoadingSilentUpdate ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '47px',
                            }}
                          >
                            <Spinner size='small' />
                          </Box>
                        ) : (
                          <Text>Update app</Text>
                        )}
                      </Button>

                      <Button
                        variant='default'
                        disabled={isLoadingReboot}
                        onClick={onButtonClickReboot}
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                          alignItems: 'center',
                          width: '65px', // Set a fixed width
                        }}
                      >
                        {isLoadingReboot ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '47px',
                            }}
                          >
                            <Spinner size='small' />
                          </Box>
                        ) : (
                          <Text>Reboot</Text>
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: ['100%', '50%'],
                    gap: 4,
                    // backgroundColor: "danger.muted",
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      // backgroundColor: 'success.muted',
                      // width: '50%',
                      // width: ["100%", "50%"],
                      height: '500px',
                      borderRadius: 2,
                      border: '1px solid',
                      borderColor: 'border.default',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        // backgroundColor: 'darkseagreen',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: 2,
                          paddingLeft: 3,
                          paddingTop: 3,
                          // backgroundColor: 'canvas.default',
                        }}
                      >
                        <Text
                          sx={{
                            fontSize: 2,
                            fontWeight: 'bold',
                            color: 'fg.default',
                          }}
                        >
                          Screen preview
                        </Text>
                        <IconButton
                          disabled={
                            isLoadingScreenshot ||
                            new Date() - new Date(device?.last_seen) >
                              2 * 60 * 1000
                          }
                          variant='invisible'
                          icon={Icons.Sync}
                          aria-label='Sync device screenshot'
                          onClick={() => handleSubmitGetScreenshot()}
                        />

                        {/* <Icons.Sync /> */}
                      </Box>
                      <Box
                        sx={{
                          width: '100%',
                          // backgroundColor: 'canvas.default',
                          display: 'flex',
                          alignContent: 'center',
                          justifyContent: 'center',
                          justifyItems: 'center',
                          alignItems: 'center',
                          height: '350px',
                          overflow: 'auto',
                        }}
                      >
                        {!device.ScreenshotPath &&
                          !isLoadingScreenshot &&
                          !errorScreenshot && (
                            <Text
                              sx={{
                                color: 'fg.muted',
                              }}
                            >
                              No preview available
                            </Text>
                          )}

                        {isLoadingScreenshot && !errorScreenshot && <Spinner />}

                        {errorScreenshot && !isLoadingScreenshot && (
                          <Text
                            sx={{
                              color: 'danger.fg',
                            }}
                          >
                            Error fetching screenshot
                          </Text>
                        )}

                        {device.ScreenshotPath &&
                          !isLoadingScreenshot &&
                          !errorScreenshot && (
                            <img
                              style={{ maxWidth: '80%', maxHeight: '80%' }}
                              src={`${device.ScreenshotPath}?${imageKey}`}
                              alt='device'
                            />
                          )}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: 2,
                      border: '1px solid',
                      borderColor: 'border.default',
                      padding: 3,
                      gap: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    {device.latitude && device.longitude ? (
                      <img
                        src={`https://maps.googleapis.com/maps/api/staticmap?center=${device.latitude},${device.longitude}&zoom=13&size=400x400&key=AIzaSyBDzoaE7Izf9LfjsHwBgXQqOGGFNiJicz0`}
                        alt='geolocation'
                      />
                    ) : (
                      <Text
                        sx={{
                          color: 'fg.muted',
                        }}
                      >
                        No geolocation available
                      </Text>
                    )}
                  </Box>

                  {extraInfo && schedules && playlists && (
                    <Box
                      sx={{
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'border.default',
                        padding: 3,
                        gap: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      {/* <pre>{JSON.stringify(extraInfo, null, 2)}</pre> */}

                      <Text
                        sx={{
                          fontSize: 12,
                          color: 'fg.muted',
                        }}
                      >
                        Assigned to{' '}
                      </Text>
                      {extraInfo.schedule && (
                        <Link
                          to={`/dashboard/schedules?schedule_id=${extraInfo.schedule.id}`}
                        >
                          <Tooltip
                            aria-label={
                              extraInfo.schedule.name.length > 50
                                ? `${extraInfo.schedule.name.slice(0, 60)}...`
                                : extraInfo.schedule.name
                            }
                          >
                            <Label variant='sponsors'>
                              <Icons.Calendar sx={{ mr: 1 }} />{' '}
                              {extraInfo.schedule.name.length > 10
                                ? `${extraInfo.schedule.name.slice(0, 10)}...`
                                : extraInfo.schedule.name}
                            </Label>
                          </Tooltip>
                        </Link>
                      )}
                      {extraInfo.playlist && (
                        <Link
                          to={`/dashboard/playlists/editor?playlist_id=${extraInfo.playlist.id}`}
                        >
                          <Tooltip
                            aria-label={
                              extraInfo.playlist.name.length > 50
                                ? `${extraInfo.playlist.name.slice(0, 60)}...`
                                : extraInfo.playlist.name
                            }
                          >
                            <Label variant='done'>
                              <Icons.Play sx={{ mr: 1 }} />
                              {extraInfo.playlist.name.length > 10
                                ? `${extraInfo.playlist.name.slice(0, 10)}...`
                                : extraInfo.playlist.name}
                            </Label>
                          </Tooltip>
                        </Link>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
