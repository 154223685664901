import React from 'react'
import { Box, Text, LinkButton } from '@primer/react'

export function NotFoundPage() {
  return (
    <Box
      sx={{
        // height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        placeItems: 'center',
        gap: 3,
        // backgroundColor: 'coral',
      }}
    >
      <Text
        sx={{
          fontSize: 3,
          fontWeight: 'bold',
          color: 'fg.default',
        }}
      >
        page not found
      </Text>
      <LinkButton as='a' href='/' variant='invisible'>
        Back to /
      </LinkButton>
    </Box>
  )
}
