/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useAuth } from '@/contexts/AuthContext'
import { Text, Box, Button, Spinner, Label, PageHeader } from '@primer/react'
import { getStripeUrl, getOrgSubscriptionPlan } from '@/lib/calls'
import { stripe } from '@/lib/stripe'
import { toast } from 'react-hot-toast'
import { CustomToast } from '@/components/custom-toast'
import {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
} from '@/components/ui/card-primer'
// import { Icons } from '@/components/icons'

function formatDate(input) {
  const date = new Date(input)
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

export function BillingPage() {
  const { user } = useAuth()
  const [subscriptionPlan, setSubscriptionPlan] = useState(null)

  const [isCanceled, setIsCanceled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    // Get a Stripe session URL.
    setIsLoading(!isLoading)
    const response = await getStripeUrl(subscriptionPlan.org.id)
    // console.log("url resp", response)

    if (response?.error) {
      return toast.custom(t => (
        <CustomToast
          message='Something went wrong. Please refresh the page and try again.'
          type='error'
        />
      ))
    }

    if (response?.url && response?.url.length > 0) {
      // console.log("url", response.url)
      window.location.href = response?.url
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await getOrgSubscriptionPlan(user.data.org_id)
      // console.log('response: ', response)

      if (response.isPro && response.stripeSubscriptionId) {
        try {
          const stripePlan = await stripe.subscriptions.retrieve(
            response.stripeSubscriptionId
          )
          // console.log('stripePlan', stripePlan)
          setIsCanceled(stripePlan.cancel_at_period_end)
        } catch (error) {
          console.error('Stripe fetch error: ', error)
        }
      }

      setSubscriptionPlan(response)
    }

    fetchData()
  }, [])

  return (
    <>
      <Box sx={{}}>
        <Box
          sx={{
            padding: 3,
          }}
        >
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as='h2'>Billing</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Description
              sx={{
                fontSize: 1,
                color: 'fg.muted',
              }}
            >
              For any billing inquiries, please contact us at
              <b>
                <a href='mailto:help@signgrid.io'>help@signgrid.io</a>
              </b>
            </PageHeader.Description>
          </PageHeader>
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            marginTop: 4,
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            {!subscriptionPlan ? (
              <Card>
                <CardHeader>
                  <CardTitle></CardTitle>
                  <CardDescription></CardDescription>
                </CardHeader>
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '105px',
                    }}
                  >
                    <Spinner />
                  </Box>
                </CardContent>
                <CardFooter></CardFooter>
              </Card>
            ) : (
              <Card>
                <CardHeader>
                  <CardTitle>Subscription plan</CardTitle>
                  <CardDescription
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Label
                      variant={
                        subscriptionPlan.name === 'PRO' ? 'done' : 'default'
                      }
                    >
                      {subscriptionPlan.name}
                    </Label>
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  {/* <Button
                      variant="primary"
                      disabled={isLoading || authProvider.user.current_org.org.owner_id !== authProvider.user.data.id}
                      onClick={handleSubmit}
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        width: '150px' // Set a fixed width
                      }}
                    >
                      {isLoading ? (
                        <Spinner size="small" />
                      ) : (
                        <Text>
                          {subscriptionPlan.isPro ? "Manage Subscription" : "Upgrade to PRO"}
                        </Text>
                      )}
                    </Button> */}
                  <Button
                    variant='primary'
                    disabled={
                      isLoading ||
                      user.current_org.org.owner_id !== user.data.id
                    }
                    onClick={handleSubmit}
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                      width: '150px', // Set a fixed width
                    }}
                  >
                    {isLoading ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '47px',
                        }}
                      >
                        <Spinner size='small' />
                      </Box>
                    ) : (
                      <Text>
                        {subscriptionPlan.isPro
                          ? 'Manage subscription'
                          : 'Upgrade plan'}
                      </Text>
                    )}
                  </Button>
                </CardContent>
                <CardFooter>
                  {subscriptionPlan.isPro ? (
                    <Text fontSize={14} color='fg.muted'>
                      {isCanceled
                        ? 'Your plan will be canceled on '
                        : 'Your plan renews on '}
                      {formatDate(subscriptionPlan.stripeCurrentPeriodEnd)}.
                    </Text>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      <Text fontSize={14} color='fg.muted'>
                        Your current plan is limited to:{' '}
                      </Text>{' '}
                      <Text fontSize={12} color='fg.muted'>
                        1 user
                      </Text>
                      <Text fontSize={12} color='fg.muted'>
                        1 organization
                      </Text>
                      <Text fontSize={12} color='fg.muted'>
                        2GB of media content storage
                      </Text>
                      <Text fontSize={12} color='fg.muted'>
                        2 devices
                      </Text>
                    </Box>
                  )}
                </CardFooter>
              </Card>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
