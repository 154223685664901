import React from 'react'
import { createBrowserRouter, redirect } from 'react-router-dom'
import { authProvider } from '@/lib/auth'
import { AuthProvider } from '@/contexts/AuthContext'
import { ProtectedRoute } from '@/components/protected-route' // Import the ProtectedRoute component
// import Logout from '@/components/logout' // Import the Logout component
import { ChangeOrgPage } from '@/components/change-org-page'
// import { LoginPage } from '@/components/login'
import { LoginPageBeta } from '@/components/login-beta'
import { ResetPasswordPage } from '@/components/reset-password'
import { DashboardLayout } from '@/components/dashboard-layout'
import { DashboardPage } from '@/components/dashboard-page'
import { ContentPage } from '@/components/content-page'
import { NotFoundPage } from '@/components/not-found-page' // Import the new component
import { AccountSettingsPage } from '@/components/account-settings-page'
import { BillingPage } from '@/components/billing-page'
import { TeamPage } from '@/components/team-page'
import { RolesPage } from '@/components/roles-page'
import { DevicesPage } from '@/components/devices-page'
import { SyncPage } from '@/components/sync-page'
import { SyncRulesPage } from '@/components/sync-rules-page'
import { ApiKeysPage } from '@/components/api-keys-page'
import { DeviceGroupsPage } from '@/components/device-groups-page'
import { PlaylistsListPage } from '@/components/playlists-list-page'
import { AppsPage } from '@/components/apps-page'
import { OrganizationPage } from '@/components/organization-page'
import {
  ReloadTableProvider,
  ReloadContentProvider,
} from '@/lib/devices-reload-context'
import { PlaylistsPage } from '@/components/playlists-page'
import { SchedulesPage } from '@/components/schedules-page'
import { DownloadsPage } from '@/components/downloads-page'
import { AlertsPage } from '@/components/alerts-page'
import { DeviceDetailPage } from '@/components/device-detail-page'
// import { ViewFile } from '@/components/view-file'

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <ProtectedRoute />
      </AuthProvider>
    ),
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    element: (
      <AuthProvider>
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      </AuthProvider>
    ),
    children: [
      {
        index: true,
        element: <DashboardPage />,
        title: 'Dashboard',
        loader: () => {
          document.title = 'Dashboard'
          return null
        },
      },

      {
        path: 'content',
        element: <ContentPage />,
        title: 'Content',
        loader: () => {
          document.title = 'Content'
          return null
        },
      },
      {
        path: 'devices',
        element: (
          <ReloadTableProvider>
            <DevicesPage />
          </ReloadTableProvider>
        ),
        title: 'Devices',
        loader: () => {
          document.title = 'Devices'
          return null
        },
      },
      {
        path: 'devices/:deviceUUID', // Define the dynamic segment for deviceUUID
        element: <DeviceDetailPage />, // Render DeviceDetailPage for this route
        title: 'Device Details',
        loader: async ({ params }) => {
          // Optionally, you can add a loader function to fetch device details based on deviceUUID
          // For example:
          // const deviceDetails = await fetchDeviceDetails(params.deviceUUID);
          // return deviceDetails;

          document.title = 'Device Details' // Set the document title
          return null
        },
      },
      {
        path: 'device-groups',
        element: <DeviceGroupsPage />,
        title: 'Device groups',
        loader: () => {
          document.title = 'Device groups'
          return null
        },
      },
      {
        path: 'sync', // Define the dynamic segment for deviceUUID
        element: <SyncPage />, // Render DeviceDetailPage for this route
        title: 'Sync',
        loader: async ({ params }) => {
          // Optionally, you can add a loader function to fetch device details based on deviceUUID
          // For example:
          // const deviceDetails = await fetchDeviceDetails(params.deviceUUID);
          // return deviceDetails;

          document.title = 'Sync' // Set the document title
          return null
        },
      },
      {
        path: 'sync/rules', // Define the dynamic segment for deviceUUID
        element: <SyncRulesPage />, // Render DeviceDetailPage for this route
        title: 'Sync rules',
        loader: async ({ params }) => {
          // Optionally, you can add a loader function to fetch device details based on deviceUUID
          // For example:
          // const deviceDetails = await fetchDeviceDetails(params.deviceUUID);
          // return deviceDetails;

          document.title = 'Sync rules' // Set the document title
          return null
        },
      },
      {
        path: 'schedules',
        element: <SchedulesPage />,
        title: 'Schedules',
        loader: () => {
          document.title = 'Schedules'
          return null
        },
      },
      {
        path: 'alerts',
        element: <AlertsPage />,
        title: 'Alerts',
        loader: () => {
          document.title = 'Alerts'
          return null
        },
      },
      {
        path: 'downloads',
        element: <DownloadsPage />,
        title: 'Downloads',
        loader: () => {
          document.title = 'Downloads'
          return null
        },
      },
      {
        path: 'playlists/editor',
        element: (
          <ReloadContentProvider>
            <PlaylistsPage />
          </ReloadContentProvider>
        ),
        title: 'Playlist editor',
        loader: () => {
          document.title = 'Playlist editor'
          return null
        },
      },
      {
        path: 'playlists',
        element: <PlaylistsListPage />,
        title: 'Playlists',
        loader: () => {
          document.title = 'Playlists'
          return null
        },
      },
      {
        path: 'apps',
        element: <AppsPage />,
        title: 'Apps',
        loader: () => {
          document.title = 'Apps'
          return null
        },
      },
      {
        path: 'settings/account',
        element: <AccountSettingsPage />,
        title: 'Account settings',
        loader: () => {
          document.title = 'Account settings'
          return null
        },
      },
      {
        path: 'settings/billing',
        element: <BillingPage />,
        title: 'Billing',
        loader: () => {
          document.title = 'Billing'
          return null
        },
      },
      {
        path: 'settings/team',
        element: <TeamPage />,
        title: 'Team management',
        loader: () => {
          document.title = 'Team management'
          return null
        },
      },
      {
        path: 'settings/roles',
        element: <RolesPage />,
        title: 'Roles & Permissions',
        loader: () => {
          document.title = 'Roles & Permissions'
          return null
        },
      },
      {
        path: 'settings/developers/api',
        element: <ApiKeysPage />,
        title: 'Developers API',
        loader: () => {
          document.title = 'Developers API'
          return null
        },
      },
      {
        path: 'settings/organization',
        element: <OrganizationPage />,
        title: 'Organization',
        loader: () => {
          document.title = 'Organization'
          return null
        },
      },

      // ... add more child routes as needed ...
    ],
  },
  {
    path: '/login',
    // element: <LoginPage />,
    element: <LoginPageBeta />,
    loader: async () => {
      await authProvider.signin()
      // console.log("login loader")
      // console.log(authProvider);
      if (authProvider.isAuthenticated) {
        return redirect('/dashboard')
      }
      document.title = 'Sign in'
      return null
    },
  },
  // {
  //   path: 'view',
  //   element: <ViewFile />,
  //   title: 'View file',
  //   loader: () => {
  //     document.title = 'View file'
  //     return null
  //   },
  // },

  // {
  //   path: '/login_beta',
  //   element: <LoginPageBeta />,
  //   loader: async () => {
  //     await authProvider.signin()
  //     // console.log("login loader")
  //     // console.log(authProvider);
  //     if (authProvider.isAuthenticated) {
  //       return redirect('/dashboard')
  //     }
  //     document.title = '[Beta] Sign in'
  //     return null
  //   },
  // },
  {
    path: 'change-org',
    element: <ChangeOrgPage />,
    title: 'Change organization',
    loader: async () => {
      if (authProvider.isAuthenticated) {
        return redirect('/dashboard')
      }

      document.title = 'Change organization'
      return null
    },
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />,
    loader: async () => {
      await authProvider.signin()
      // console.log("login loader")
      // console.log(authProvider);
      if (authProvider.isAuthenticated) {
        return redirect('/dashboard')
      }
      document.title = 'Reset password'
      return null
    },
  },

  {
    path: '/logout',
    // element: <Logout />,
    loader: async () => {
      await authProvider.signout()
      // Redirect to login after successful or failed logout attempt
      return redirect('/login')
    },
  },
  {
    path: '*', // This will match any route not matched above
    element: <NotFoundPage />, // Your 404 component
    loader: () => {
      document.title = 'Page not found'
      return null
    },
  },
])
