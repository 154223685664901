/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  TreeView,
  Text,
  Button,
  Spinner,
  LinkButton,
  Label,
  PageHeader,
  AnchoredOverlay,
  Autocomplete,
  Breadcrumbs,
  FormControl,
  TextInput,
  Truncate,
  ActionList,
  IconButton,
  ButtonGroup,
  Tooltip,
  AvatarToken,
} from '@primer/react'
import { getFoldersAndFiles, patchPlaylistApps } from '@/lib/calls'
import {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
} from '@/components/ui/card-primer'
import { Icons } from '@/components/icons'
import {
  createPlaylist,
  createPlaylistApps,
  getPlaylists,
  patchPlaylist,
  getApps,
} from '@/lib/calls'
import { toast } from 'react-hot-toast'
import { CustomToast } from '@/components/custom-toast'
import { EditPlaylistMenu } from '@/components/edit-playlist-menu'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useReloadContent } from '@/lib/devices-reload-context'

export function PlaylistsPage() {
  const [useApps, setUseApps] = useState(false)
  const [apps, setApps] = useState(null)
  const [isLoadingApps, setIsLoadingApps] = useState(false)
  const { reloadContent } = useReloadContent()
  // const { setReloadContent } = useReloadContent();

  const [restricted, setRestricted] = useState(false)
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const navigate = useNavigate()

  const formatSize = bytes => {
    if (bytes >= 1e9) {
      // Gigabytes
      return `${(bytes / 1e9).toFixed(2)} GB`
    } else if (bytes >= 1e6) {
      // Megabytes
      return `${(bytes / 1e6).toFixed(2)} MB`
    } else if (bytes >= 1e3) {
      // Kilobytes
      return `${(bytes / 1e3).toFixed(2)} KB`
    } else {
      // Bytes
      return `${bytes} bytes`
    }
  }

  const query = useQuery()
  // const [playlistId, setPlaylistId] = useState(query.get('playlist_id'));
  const playlistId = query.get('playlist_id')
  const [playlistName, setPlaylistName] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [isLoadingTree, setIsLoadingTree] = useState(false)

  const [fileId, setFileId] = useState(null)
  const [folderId, setFolderId] = useState(null)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [nameValue, setNameValue] = useState('')
  const [selectedApps, setSelectedApps] = useState([])

  const addFile = (fileId, fileName, duration) => {
    if (duration === undefined) {
      duration = 0
    }

    if (duration === 0) {
      duration = 10
    }

    const file = { id: fileId, name: fileName, duration: duration }
    setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, file])
  }

  // const addApp = (appId, appName, duration) => {
  //   if (duration === undefined) {
  //     duration = 0
  //   }
  //
  //   if (duration === 0) {
  //     duration = 10
  //   }
  //
  //   const app = { id: appId, name: appName, duration: duration }
  //   setSelectedApps(prevSelectedApps => [...prevSelectedApps, app])
  // }

  const addApp = (appId, appName, duration = 10, appUrl) => {
    // Note: The default parameter syntax is used to simplify the logic.
    // It automatically assigns a default value of 10 to `duration` if it's not provided or is undefined.
    const app = { id: appId, name: appName, duration: duration, url: appUrl }

    // Set the single app as the selected app, replacing any previous selections.
    setSelectedApps([app])
  }

  const removeFile = index => {
    setSelectedFiles(prevSelectedFiles =>
      prevSelectedFiles.filter((_, idx) => idx !== index)
    )
    setShowVideo(false)
  }

  const removeApp = index => {
    setSelectedApps(prevSelectedApps =>
      prevSelectedApps.filter((_, idx) => idx !== index)
    )
  }

  // const handleFileClick = (fileId, fileName, duration) => {
  //   console.log("duration: ", duration);
  //   if (duration === undefined) {
  //     duration = 0;
  //   }

  //   if (duration === 0) {
  //     duration = 10;
  //   }
  //   const file = { id: fileId, name: fileName, duration: duration }; // Initialize duration as 0
  //   setSelectedFiles((prevSelectedFiles) => {
  //     if (
  //       prevSelectedFiles.some((selectedFile) => selectedFile.id === fileId)
  //     ) {
  //       return prevSelectedFiles.filter(
  //         (selectedFile) => selectedFile.id !== fileId,
  //       );
  //     } else {
  //       return [...prevSelectedFiles, file];
  //     }
  //   });
  // };

  const moveItem = (index, direction) => {
    const newIndex = direction === 'up' ? index - 1 : index + 1
    const updatedSelectedFiles = [...selectedFiles]
      ;[updatedSelectedFiles[index], updatedSelectedFiles[newIndex]] = [
        updatedSelectedFiles[newIndex],
        updatedSelectedFiles[index],
      ]
    setSelectedFiles(updatedSelectedFiles) // Assuming you are using setState or similar
    setShowVideo(false)
  }

  const moveItemApp = (index, direction) => {
    const newIndex = direction === 'up' ? index - 1 : index + 1
    const updatedSelectedApps = [...selectedApps]
      ;[updatedSelectedApps[index], updatedSelectedApps[newIndex]] = [
        updatedSelectedApps[newIndex],
        updatedSelectedApps[index],
      ]
    setSelectedApps(updatedSelectedApps) // Assuming you are using setState or similar
  }

  const updateDurationApps = (index, duration) => {
    const updatedSelectedApps = [...selectedApps]
    if (duration === '') {
      updatedSelectedApps[index].duration = 0
    } else {
      updatedSelectedApps[index].duration = parseInt(duration, 10)
    }
    setSelectedApps(updatedSelectedApps)
  }

  const updateDuration = (index, duration) => {
    const updatedSelectedFiles = [...selectedFiles]
    if (duration === '') {
      updatedSelectedFiles[index].duration = 0
    } else {
      updatedSelectedFiles[index].duration = parseInt(duration, 10)
    }
    setSelectedFiles(updatedSelectedFiles)
  }

  const matchFiles = (playlistFiles, folderStructure) => {
    const matchedFiles = []
    // console.log(playlistFiles)
    // console.log(folderStructure)

    const findFileById = (id, folder) => {
      if (folder.files) {
        const matchedFile = folder.files.find(file => file.id === id)
        if (matchedFile) {
          return matchedFile
        }
      }
      if (folder.children) {
        for (const childFolder of folder.children) {
          const matchedFile = findFileById(id, childFolder)
          if (matchedFile) {
            return matchedFile
          }
        }
      }
      return null
    }

    playlistFiles.forEach(playlistFile => {
      const matchedFile = findFileById(playlistFile.id, folderStructure)
      if (matchedFile) {
        matchedFiles.push({
          id: matchedFile.id,
          name: matchedFile.name,
          duration: playlistFile.duration,
        })
      }
    })

    return matchedFiles
  }

  const matchApps = (playlistApps, apps) => {
    const matchedApps = []
    // console.log('playlistsApp', playlistApps)
    // console.log('APPS', apps)

    const findAppById = (id, appsContainer) => {
      // Ensure appsContainer.data is present and is an array
      if (appsContainer && Array.isArray(appsContainer.data)) {
        const matchedApp = appsContainer.data.find(app => app.id === id)
        if (matchedApp) {
          return matchedApp
        }
      }
      return null
    }

    playlistApps.forEach(playlistApp => {
      const matchedApp = findAppById(playlistApp.id, apps)
      if (matchedApp) {
        matchedApps.push({
          id: matchedApp.id,
          name: matchedApp.name,
          duration: playlistApp.duration,
        })
      }
    })

    return matchedApps
  }

  const [validationResult, setValidationResult] = useState('')

  const handleInputChange = event => {
    setShowVideo(false)
    setNameValue(event.currentTarget.value)
  }

  useEffect(() => {
    if (nameValue.length === 0) {
      setValidationResult('empty')
    } else if (nameValue.length > 255) {
      setValidationResult('tooLong')
    } else if (nameValue.length < 3) {
      setValidationResult('tooShort')
    } else {
      setValidationResult('validName')
    }
  }, [nameValue])

  const handleSubmit = async () => {
    // alert(1);
    if (validationResult === 'validName') {
      try {
        setIsLoading(true)
        // if (selectedApps.length > 0 && useApps) {
        //   const response = await createPlaylistApps(nameValue, selectedApps)
        //   setSubmitResponse(response)
        // } else {
        //   const response = await createPlaylist(nameValue, selectedFiles)
        //   setSubmitResponse(response)
        // }

        const response = useApps
          ? await createPlaylistApps(nameValue, selectedApps)
          : await createPlaylist(nameValue, selectedFiles)

        // console.log(response.data.id)
        if (response.data.id) {
          navigate(`?playlist_id=${response.data.id}`)
          // setPlaylistId(response.data.id);
        }
        // navigate('?playlist_id=')
        toast.custom(t => (
          <CustomToast message='Playlist created' type='success' />
        ))
        setIsLoading(false)
        // Handle success - e.g., show a success message
      } catch (error) {
        setIsLoading(false)
        // Handle errors - e.g., show error message

        toast.custom(t => (
          <CustomToast message='Error creating new playlist' type='error' />
        ))

        // console.log(error);
        console.error('Error creating playlist:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleUpdate = async () => {
    // alert(1);
    if (validationResult === 'validName') {
      try {
        setIsLoading(true)
        if (selectedApps.length > 0 && useApps) {
          await patchPlaylistApps(nameValue, playlistId, selectedApps)
        } else {
          await patchPlaylist(nameValue, selectedFiles, playlistId)
        }

        toast.custom(t => (
          <CustomToast message='Playlist updated' type='success' />
        ))
        setIsLoading(false)
        // Handle success - e.g., show a success message
      } catch (error) {
        setIsLoading(false)
        // Handle errors - e.g., show error message

        toast.custom(t => (
          <CustomToast message='Error updating playlist' type='error' />
        ))

        // console.log(error);
        console.error('Error updating playlist:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const [content, setContent] = useState(null)

  const findFileById = (folders, fileId) => {
    for (const folder of folders) {
      // Search in the current folder's files
      const foundFile = folder.files?.find(file => file.id === fileId)
      if (foundFile) {
        return foundFile
      }

      // Recursively search in children folders
      if (folder.children) {
        const fileInChild = findFileById(folder.children, fileId)
        if (fileInChild) {
          return fileInChild
        }
      }
    }

    return null
  }

  useEffect(() => {
    setShowVideo(false)
    const fetchFoldersAndFiles = async () => {
      try {
        setIsLoadingTree(true)
        const response = await getFoldersAndFiles()
        setContent(response)

        if (fileId) {
          const foundFile = findFileById([response.data], fileId)
          setSelectedFile(foundFile)
        } else {
          setSelectedFile(null)
        }

        const foundFolder = findFolderById([response.data], folderId)
        setSelectedFolder(foundFolder)
        setIsLoadingTree(false)
      } catch (error) {
        console.error('Error fetching folders and files:', error)
        if (error?.response?.status === 403) {
          setRestricted(true)
        }
      } finally {
        setIsLoadingTree(false)
      }

      try {
        setIsLoadingTree(true)
        await getPlaylists()
      } catch (error) {
        console.error('Error fetching playlists:', error)
        if (error?.response?.status === 403) {
          setRestricted(true)
        }
      }
    }

    const fetchApps = async () => {
      try {
        setIsLoadingApps(true)
        const response = await getApps()
        setApps(response)
      } catch (error) {
        console.error('Error fetching apps:', error)
        if (error?.response?.status === 403) {
          setRestricted(true)
        }
      } finally {
        setIsLoadingApps(false)
      }
    }

    fetchFoldersAndFiles()
    fetchApps()
    setValidationResult('')
    setNameValue('')
    setSelectedFiles([])
    setSelectedApps([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadContent])

  useEffect(() => {
    const fetchFoldersAndFiles = async () => {
      try {
        setIsLoadingTree(true)
        const response = await getFoldersAndFiles()
        setContent(response)

        if (fileId) {
          const foundFile = findFileById([response.data], fileId)
          setSelectedFile(foundFile)
        } else {
          setSelectedFile(null)
        }

        const foundFolder = findFolderById([response.data], folderId)
        setSelectedFolder(foundFolder)
        setIsLoadingTree(false)
      } catch (error) {
        console.error('Error fetching folders and files:', error)
        if (error?.response?.status === 403) {
          setRestricted(true)
        }
      } finally {
        setIsLoadingTree(false)
      }

      try {
        setIsLoadingTree(true)
        await getPlaylists()
      } catch (error) {
        console.error('Error fetching playlists:', error)
        if (error?.response?.status === 403) {
          setRestricted(true)
        }
      }
    }

    fetchFoldersAndFiles()
    setValidationResult('')
    setNameValue('')
    setSelectedFiles([])
    setSelectedApps([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (playlistId && content && apps) {
      try {
        const fetchPlaylist = async () => {
          setIsLoadingTree(true)
          const response = await getPlaylists()
          // find playlist by id
          const playlist = response.data.find(
            playlist => playlist.playlist.id === playlistId
          )
          if (!playlist) {
            setIsLoadingTree(false)
            navigate('?playlist_id=')
            return
          }
          if (playlist.playlist.apps && playlist.playlist.apps.length > 0) {
            // console.log(matchApps(playlist.playlist.apps, apps))
            setSelectedApps(matchApps(playlist.playlist.apps, apps))
            setUseApps(true)
            setNameValue(playlist.playlist.name)
            setPlaylistName(playlist.playlist.name)
          } else {
            setNameValue(playlist.playlist.name)
            // console.log(content.data);
            setPlaylistName(playlist.playlist.name)
            // console.log(matchFiles(playlist.playlist.files, content.data))
            setSelectedFiles(matchFiles(playlist.playlist.files, content.data))
          }

          setIsLoadingTree(false)
        }
        fetchPlaylist()

        if (!playlistId) {
          setSelectedFiles([])
          setSelectedApps([])
          setNameValue('')
        }
      } catch (error) {
        console.error('Error fetching playlist:', error)
        if (error?.response?.status === 403) {
          setRestricted(true)
        }
      } finally {
        setIsLoadingTree(false)
      }
    }
  }, [playlistId, content, apps])

  useEffect(() => {
    // console.log("are we getting here?")
    if (!content) {
      return
    }

    if (!apps) {
      return
    }

    if (fileId) {
      const foundFile = findFileById([content.data], fileId)
      setSelectedFile(foundFile)
    } else {
      setSelectedFile(null)
    }

    const foundFolder = findFolderById([content.data], folderId)
    setSelectedFolder(foundFolder)
    setIsLoadingTree(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId, folderId, content])

  const findFolderById = (folders, folderId) => {
    let queue = [...folders]

    while (queue.length) {
      let current = queue.shift()

      if (current.id === folderId) {
        return current
      }

      if (current.children) {
        queue.push(...current.children)
      }
    }

    return null
  }

  const FileTreeView = ({ data, currentFolderId, currentFileId }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [items, setItems] = useState(['test'])
    const [textInputArgs] = useState({})
    const [menuArgs] = useState({})
    const inputRef = useRef()
    const scrollContainerRef = useRef()
    const handleOpen = () => {
      setIsOpen(true)
    }

    useEffect(() => {
      const fileNames = extractFileNames(data)
      setItems(fileNames)
    }, [data])

    const handleAutocompleteSelect = selectedItems => {
      if (selectedItems.length > 0) {
        const selectedItem = selectedItems[0]
        handleItemClick(false, null, selectedItem.id)
      }
    }

    const extractFileNames = (node, fileNames = []) => {
      if (node.is_folder) {
        // Traverse files in the current folder
        ; (node.files || []).forEach(file => {
          fileNames.push({ id: file.id, name: file.name })
        })

          // Recursively traverse child folders
          ; (node.children || []).forEach(child => {
            extractFileNames(child, fileNames)
          })
      }
      return fileNames
    }

    // const  = useNavigate();

    const handleItemClick = (isFolder, folderId, fileId = null) => {
      // navigate(`?folder=${folderId}&file_id=${fileId}`);
      setFolderId(folderId)
      setFileId(fileId)
      setShowVideo(false)
    }

    const isCurrent = (node, isFolder) => {
      return isFolder ? node.id === currentFolderId : node.id === currentFileId
    }

    const renderTreeItems = (node, parentFolderId = null) => {
      const isFolder = node.is_folder
      if (isFolder) {
        return (
          <TreeView.Item
            id={node.id}
            key={node.id}
            defaultExpanded
            current={isCurrent(node, true)}
            onSelect={event => {
              event.preventDefault()
              handleItemClick(true, node.id)
            }}
          >
            <TreeView.LeadingVisual>
              <TreeView.DirectoryIcon />
            </TreeView.LeadingVisual>
            {node.name}
            <TreeView.SubTree>
              {(node.files || []).map(file => renderTreeItems(file, node.id))}
              {(node.children || []).map(child =>
                renderTreeItems(child, node.id)
              )}
            </TreeView.SubTree>
          </TreeView.Item>
        )
      } else {
        return (
          <TreeView.Item
            id={node.id}
            key={node.id}
            current={isCurrent(node, false)}
            onSelect={event => {
              event.preventDefault()
              handleItemClick(false, parentFolderId, node.id)
            }}
          >
            <TreeView.LeadingVisual>
              {/* Insert appropriate file icon based on the file type */}
              {/* {node.meta.type.toString()} */}
              {node.meta && node.meta.type.startsWith('image') && (
                <Icons.FileMedia />
              )}
              {node.meta && node.meta.type.startsWith('video') && (
                <Icons.Video />
              )}
            </TreeView.LeadingVisual>
            {node.name}
          </TreeView.Item>
        )
      }
    }

    return    <Box
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: 14,
      height: '100%',
    }}
  >
    <AnchoredOverlay
      open={isOpen}
      onOpen={handleOpen}
      onClose={() => setIsOpen(false)}
      width='large'
      height='xsmall'
      focusTrapSettings={{
        initialFocusRef: inputRef,
      }}
      side='inside-top'
      renderAnchor={props => (
        <Button {...props} trailingVisual={Icons.SearchIcon}>
          Find files
        </Button>
      )}
    >
      <Autocomplete>
        <Box display='flex' flexDirection='column' height='100%'>
          <Box
            borderWidth={0}
            borderBottomWidth={1}
            borderColor='border.default'
            borderStyle='solid'
          >
            <Autocomplete.Input
              ref={inputRef}
              sx={{
                display: 'flex',
                border: '0',
                paddingX: 3,
                paddingY: 1,
                boxShadow: 'none',
                ':focus-within': {
                  border: '0',
                  boxShadow: 'none',
                },
              }}
              {...textInputArgs}
              size={textInputArgs.inputSize}
              block
            />
          </Box>
          <Box overflow='auto' flexGrow={1}>
            <Autocomplete.Menu
              items={items.map(item => ({
                text: item.name,
                id: item.id,
              }))}
              selectedItemIds={[]}
              customScrollContainerRef={scrollContainerRef}
              aria-labelledby='autocompleteLabel'
              {...menuArgs}
              onSelectedChange={handleAutocompleteSelect}
            />
          </Box>
        </Box>
      </Autocomplete>
    </AnchoredOverlay>
    <TreeView aria-label='Files'>{renderTreeItems(data)}</TreeView>
  </Box>
  }

  const Preview = ({ file, folder }) => {
    if (!file && !folder) {
      return (
        <Card
          sx={{
            // backgroundColor: 'green',
            height: '485px',
            width: ['200px', '200px', '200px', '200px'],
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '420px',
              // backgroundColor: 'red',
              // height: ['250px', '150px', '150px', '420px'],
              // height: '420px',
              // height: ['250px', '150px', '150px', '425px'],
            }}
          >
            <Box
              sx={{
                // backgroundColor: 'danger.emphasis',
                height: '100%',
                mt: 8,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Text
                sx={{
                  color: 'fg.muted',
                }}
              >
                No file selected
              </Text>
            </Box>
          </CardContent>
        </Card>
      )
    }

    return (
      <Card
        sx={{
          // backgroundColor: 'green',
          height: '485px',
          width: ['200px', '200px', '200px', '200px'],
        }}
      >
        <CardHeader>
          <CardTitle>
            <Tooltip
              aria-label={file ? file.name : folder.name}
              sx={{
                maxWidth: '100%',
              }}
            >
              <Truncate maxWidth={['100%']}>
                {file ? file.name : folder.name}
              </Truncate>
            </Tooltip>
          </CardTitle>
        </CardHeader>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '420px',
            // backgroundColor: 'red',
            // height: ['250px', '150px', '150px', '420px'],
            // height: '420px',
            // height: ['250px', '150px', '150px', '425px'],
          }}
        >
          {file ? (
            <>
              {/* File details */}
              <Box>
                {file.meta && (
                  <Box
                    sx={{
                      display: 'flex',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      // height: '60px',
                      // backgroundColor: 'red',
                      mb: 3,
                    }}
                  >
                    {file.meta.type.startsWith('video') ? (
                      <>
                        {!showVideo && (
                          <Tooltip
                            aria-label='Click to play video'
                            sx={{
                              maxWidth: '100%',
                            }}
                          >
                            <img
                              src={
                                'https://cdn.signgrid.io/file/' +
                                file.id +
                                '_thumbnail.jpg'
                              }
                              alt={file.name}
                              onClick={() => setShowVideo(!showVideo)}
                              style={{
                                maxWidth: '100%',
                                height: '200px',
                                cursor: 'pointer',
                                maxHeight: '200px',
                              }}
                            />
                          </Tooltip>
                        )}

                        {showVideo && (
                          <video
                            controls
                            style={{
                              maxWidth: '100%',
                              height: '200px',
                              cursor: 'pointer',
                              maxHeight: '200px',
                            }}
                            src={`https://cdn.signgrid.io/file/${file.id}-${file.name}`}
                          ></video>
                        )}
                      </>
                    ) : (
                      <img
                        src={
                          'https://cdn.signgrid.io/file/' +
                          file.id +
                          '_thumbnail.jpg'
                        }
                        alt={file.name}
                        style={{
                          maxWidth: '100%',
                          height: '100px',
                          maxHeight: '100px',
                        }}
                      />
                    )}
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'fg.muted',
                    fontSize: 1,
                    gap: 3,
                  }}
                >
                  <Text>
                    {' '}
                    <Label>
                      Size:{' '}
                      {file.meta ? formatSize(file.meta.size) : 'Unknown size'}
                    </Label>
                  </Text>
                  <Text>
                    {' '}
                    <Label>
                      Resolution:{' '}
                      {file.meta ? file.meta.width : 'Unknown width'}x
                      {file.meta ? file.meta.height : 'Unknown height'}px
                    </Label>
                  </Text>
                  <Text>
                    {' '}
                    <Label>
                      Type: {file.meta ? file.meta.type : 'Unknown file'}
                    </Label>
                  </Text>
                  {file.meta.type.startsWith('video') ? (
                    <Text>
                      {' '}
                      <Label>Seconds: {file.meta.length}</Label>
                    </Text>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  // backgroundColor: 'red',
                  color: 'fg.muted',
                  justifyItems: 'space-between',
                  justifyContent: 'space-between',
                  alignContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    mt: 2,
                    color: 'fg.muted',
                  }}
                >
                  <LinkButton
                    variant='default'
                    // onClick={() => (handleAddToPlaylist(file))}
                    onClick={() =>
                      addFile(file.id, file.name, file.meta.length)
                    }
                  >
                    Add to playlist
                  </LinkButton>
                  {/* <Button variant="danger" onClick={() => (setIsOpenDeleteFileDialog(true))}>Delete</Button> */}
                </Box>
                {/* Add more file details here */}
              </Box>
            </>
          ) : (
            <>
              {/* Optionally list folder contents here */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'space-between',
                  justifyItems: 'space-between',
                  color: 'fg.muted',
                }}
              >
                {/* <Text>{folder.name}</Text> */}
                <Box></Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    mt: 2,
                    color: 'fg.muted',
                  }}
                >
                  {/* <Button variant="default" onClick={() => (setIsOpenRenameFolderDialog(true))}>Update name</Button> */}

                  {/* <Button variant="danger" onClick={() => (setIsOpenDeleteFolderDialog(true))}>Delete folder</Button> */}
                </Box>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    )
  }

  const handleItemClickTwo = (fileId = null) => {
    setFolderId(null)
    setFileId(fileId)
    setShowVideo(false)
  }

  const [showVideo, setShowVideo] = useState(false)

  return (
    <Box sx={{}}>

<Box
            sx={{
              mt: 2,
            }}
          >
            <Breadcrumbs>
              <Link to='/'>
                <Breadcrumbs.Item href='#'>Home</Breadcrumbs.Item>
              </Link>
              <Link to='/dashboard/playlists'>
                <Breadcrumbs.Item href='#'>Playlists</Breadcrumbs.Item>
              </Link>
              <Tooltip
                aria-label={playlistName ? playlistName : 'Playlist editor'}
              >
                <Breadcrumbs.Item href='#' selected>
                  {/* Playlist editor */}
                  {playlistName
                    ? playlistName.length > 30
                      ? playlistName.slice(0, 30) + '...'
                      : playlistName
                    : 'Playlist editor'}
                </Breadcrumbs.Item>
              </Tooltip>
            </Breadcrumbs>
          </Box>



<Box sx={{
        padding: 3
      }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Playlist editor</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Actions>

          <EditPlaylistMenu
            disabled={restricted}
            name={playlistName}
            playlistid={playlistId}
          />
            </PageHeader.Actions>
          </PageHeader>
        </Box>



      

      {restricted ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            justifyItems: 'center',
            position: 'relative',
            // backgroundColor: 'canvas.default',
            alignContent: 'center',
            alignItems: 'center',
            gap: 3,
            mt: 4,
          }}
        >
          <Text
            sx={{
              fontSize: 4,
              fontWeight: 'bold',
              // color: 'danger.',
              textAlign: 'center',
            }}
          >
            You don't have access to this content.
          </Text>
          <Text
            sx={{
              fontSize: 1,
              color: 'fg.muted',
            }}
          >
            <Icons.Alert /> Insufficient permissions to manage playlists.
          </Text>
          {/* <LinkButton variant="primary" as="a" href="/dashboard/devices">
          Retry
        </LinkButton> */}
          {/* <LinkButton variant="invisible" href="#">
          Installation instructions
        </LinkButton> */}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'column', 'column', 'row', 'row'],
            width: '100%',
            height: '100%',
            marginTop: 4,
            gap: 3,
            // backgroundColor: 'canvas.default',
          }}
        >
          {useApps && (
            <Box
              sx={{
                display: 'flex',
                // backgroundColor: 'yellow',
                width: ['100%', '100%', '100%', '50%'],
                // flexDirection: ["column", "column", "column", "row", "row"],
                gap: 3,
              }}
            >
              <Box
                sx={{
                  width: ['100%', '100%', '100%', '100%'],
                  // backgroundColor: 'green',
                  overflow: 'auto',
                }}
              >
                {!apps || isLoadingApps ? (
                  <Card>
                    <CardHeader>
                      <CardTitle></CardTitle>
                      <CardDescription></CardDescription>
                    </CardHeader>
                    <CardContent>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          // height: '382px',
                          height: '420px',
                        }}
                      >
                        <Spinner />
                      </Box>
                    </CardContent>
                    <CardFooter></CardFooter>
                  </Card>
                ) : (
                  <Box
                    sx={{
                      // height: '420px',
                      // width: ['100%', '100%', '100%', '50%'],
                      height: '485px',
                      borderRadius: 2,
                      border: '1px solid',
                      borderColor: 'border.default',
                      padding: 3,
                      // height: ['500px', '500px', '500px', '500px'],
                      // height: '485px',
                      // backgroundColor: 'red',
                      overflow: 'auto',
                    }}
                  >
                    {apps.data.length === 0 && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <Text color='fg.muted'>
                          No apps available. Add a new app to continue.
                        </Text>
                      </Box>
                    )}
                    <ActionList>
                      {apps.data.map((app, index) => (
                        <>
                          <ActionList.Item
                            onClick={() =>
                              addApp(app.id, app.name, app.duration, app.url)
                            }
                          >
                            <Tooltip aria-label={app.name}>
                              <Icons.Apps />{' '}
                              {app.name.length > 25
                                ? `${app.name.slice(0, 25)}...`
                                : app.name}{' '}
                            </Tooltip>
                            <Tooltip aria-label={app.url}>
                              <Label>
                                {app.url.length > 25
                                  ? app.url.slice(0, 25) + '...'
                                  : app.url}
                              </Label>
                            </Tooltip>
                          </ActionList.Item>
                        </>
                      ))}
                    </ActionList>
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    mt: 2,
                  }}
                >
                  <IconButton
                    sx={{}}
                    onClick={() => {
                      setUseApps(!useApps)
                    }}
                    variant='invisible'
                    icon={useApps ? Icons.Apps : Icons.FileMedia}
                    aria-label='switch type'
                  ></IconButton>

                  <Text color='attention.fg' fontSize='small'>
                    <Icons.Alert />{' '}
                    {`using apps is experimental and works on
                    android app version >=1.0.1`}
                  </Text>
                </Box>
              </Box>
              {/* <Box */}
              {/*   sx={ */}
              {/*     { */}
              {/*       // width: ['190px', '190px', '190px', '190px'], */}
              {/*       // height: ['100%', '100%', '100%', '100%'], */}
              {/*       // backgroundColor: 'red', */}
              {/*     } */}
              {/*   } */}
              {/* > */}
              {/*   <Preview file={selectedFile} folder={selectedFolder} /> */}
              {/* </Box> */}
            </Box>
          )}

          {!useApps && (
            <Box
              sx={{
                display: 'flex',
                // backgroundColor: 'yellow',
                width: ['100%', '100%', '100%', '50%'],
                // flexDirection: ["column", "column", "column", "row", "row"],
                gap: 3,
              }}
            >
              <Box
                sx={{
                  width: ['100%', '100%', '100%', '100%'],
                  // backgroundColor: 'green',
                  overflow: 'auto',
                }}
              >
                {!content || isLoadingTree ? (
                  <Card>
                    <CardHeader>
                      <CardTitle></CardTitle>
                      <CardDescription></CardDescription>
                    </CardHeader>
                    <CardContent>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          // height: '382px',
                          height: '420px',
                        }}
                      >
                        <Spinner />
                      </Box>
                    </CardContent>
                    <CardFooter></CardFooter>
                  </Card>
                ) : (
                  <Box
                    sx={{
                      // height: '420px',
                      // width: ['100%', '100%', '100%', '50%'],
                      height: '485px',
                      borderRadius: 2,
                      border: '1px solid',
                      borderColor: 'border.default',
                      padding: 3,
                      // height: ['500px', '500px', '500px', '500px'],
                      // height: '485px',
                      // backgroundColor: 'red',
                      overflow: 'auto',
                    }}
                  >
                    <FileTreeView
                      data={content?.data}
                      currentFolderId={folderId}
                      currentFileId={fileId}
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    mt: 2,
                  }}
                >
                  <IconButton
                    sx={{}}
                    onClick={() => {
                      setShowVideo(false)
                      setUseApps(!useApps)
                    }}
                    variant='invisible'
                    icon={useApps ? Icons.Apps : Icons.FileMedia}
                    aria-label='switch type'
                  ></IconButton>

                  <Text color='fg.muted' fontSize='small'>
                    using files
                  </Text>
                </Box>
              </Box>

              <Box
                sx={
                  {
                    // width: ['190px', '190px', '190px', '190px'],
                    // height: ['100%', '100%', '100%', '100%'],
                    // backgroundColor: 'red',
                  }
                }
              >
                <Preview file={selectedFile} folder={selectedFolder} />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              width: ['100%', '100%', '100%', '50%'],
            }}
          >
            <Card
              sx={
                {
                  // backgroundColor: "#21262D",
                }
              }
            >
              <CardHeader>
                <CardTitle>Manage playlist</CardTitle>
              </CardHeader>
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // justifyContent: 'space-between',
                    // overflow: 'auto',
                    gap: 2,
                    height: '22.3rem',
                  }}
                >
                  <FormControl id='name' required>
                    <FormControl.Label htmlFor='name'>Name</FormControl.Label>
                    <TextInput
                      sx={{
                        width: '100%',
                      }}
                      value={nameValue}
                      onChange={handleInputChange}
                      name='name'
                      placeholder='Name'
                    />
                    {validationResult === 'empty' && (
                      <FormControl.Validation variant='error'>
                        Name cannot be empty
                      </FormControl.Validation>
                    )}
                    {validationResult === 'tooLong' && (
                      <FormControl.Validation variant='error'>
                        Name cannot be longer than 255 characters
                      </FormControl.Validation>
                    )}
                    {validationResult === 'tooShort' && (
                      <FormControl.Validation variant='error'>
                        Name cannot be less than 3 characters
                      </FormControl.Validation>
                    )}
                    {validationResult === 'validName' && (
                      <FormControl.Validation variant='success'>
                        Valid name
                      </FormControl.Validation>
                    )}
                    <FormControl.Caption>
                      Please enter a name (3 to 255 characters).
                    </FormControl.Caption>
                  </FormControl>

                  {!useApps && (
                    <FormControl
                      required
                      sx={{
                        // backgroundColor: 'blue',
                        height: '300px',
                        overflow: 'auto',
                      }}
                    >
                      <FormControl.Label htmlFor='files'>
                        Files
                      </FormControl.Label>
                      {selectedFiles.length > 0 && (
                        <>
                          {selectedFiles.map((file, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                mt: 2,
                                // justifyContent: 'space-between',
                                // backgroundColor: 'canvas.default',
                                width: '100%',
                                // alignItems: 'center',
                                gap: 2,
                                // backgroundColor: 'canvas.default',
                              }}
                            >
                              <FormControl
                                sx={{
                                  // backgroundColor: 'green',
                                  width: '30%',
                                }}
                                id='seconds'
                              >
                                <FormControl.Label>
                                  <Tooltip aria-label='Seconds'>
                                    Duration
                                  </Tooltip>
                                </FormControl.Label>
                                <TextInput
                                  defaultValue={0}
                                  width='100%'
                                  name='seconds'
                                  placeholder='Seconds'
                                  type='number'
                                  value={file.duration}
                                  onChange={e =>
                                    updateDuration(index, e.target.value)
                                  }
                                />
                                {/*
                              <FormControl.Caption>
                                Duration in seconds.
                              </FormControl.Caption> */}
                              </FormControl>

                              <FormControl
                                sx={{
                                  width: '70%',
                                  // backgroundColor: 'blue',
                                }}
                              >
                                <FormControl.Label>File</FormControl.Label>
                                <Box
                                  key={file.id}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',

                                    // alignItems: 'center',
                                    width: '100%',
                                    mt: 2,
                                    gap: 2,
                                    // backgroundColor: 'red',
                                    // padding: 2,
                                    // borderRadius: 2,
                                  }}
                                >
                                  {/* <Text fontSize={1} color={'fg.default'}><Truncate sx={{}}>{file.name}</Truncate></Text> */}
                                  <Tooltip aria-label={file.name}>
                                    <AvatarToken
                                      size='large'
                                      onClick={() => {
                                        handleItemClickTwo(file.id)
                                      }}
                                      text={
                                        file.name.length > 11
                                          ? file.name.substring(0, 11) + '...'
                                          : file.name
                                      }
                                      avatarSrc={
                                        'https://cdn.signgrid.io/file/' +
                                        file.id +
                                        '_thumbnail.jpg'
                                      }
                                    />
                                  </Tooltip>
                                  <ButtonGroup
                                    sx={{
                                      mr: 1,
                                    }}
                                  >
                                    <IconButton
                                      variant='default'
                                      icon={Icons.ArrowUp}
                                      aria-label=''
                                      onClick={() => {
                                        moveItem(index, 'up')
                                      }}
                                      disabled={index === 0}
                                    />
                                    <IconButton
                                      variant='default'
                                      icon={Icons.ArrowDown}
                                      aria-label=''
                                      onClick={() => {
                                        moveItem(index, 'down')
                                      }}
                                      disabled={
                                        index === selectedFiles.length - 1
                                      }
                                    />
                                    {/* <IconButton variant="danger" icon={Icons.Trash} aria-label="" onClick={() => {setFiles(files.filter((_, i) => i !== index))}}/> */}
                                    <IconButton
                                      variant='danger'
                                      icon={Icons.Trash}
                                      aria-label=''
                                      onClick={() => {
                                        // handleFileClick(file.id, file.name);
                                        removeFile(index)
                                      }}
                                    />
                                  </ButtonGroup>
                                </Box>
                              </FormControl>
                            </Box>
                          ))}
                        </>
                      )}
                      <FormControl.Caption>
                        Add files to playlist and set their order.
                      </FormControl.Caption>
                    </FormControl>
                  )}

                  {useApps && (
                    <FormControl required>
                      <FormControl.Label htmlFor='apps'>Apps</FormControl.Label>
                      {selectedApps.length > 0 && (
                        <>
                          {selectedApps.map((app, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                mt: 2,
                                // justifyContent: 'space-between',
                                // backgroundColor: 'canvas.default',
                                width: '100%',
                                // alignItems: 'center',
                                gap: 2,
                                // backgroundColor: 'canvas.default',
                              }}
                            >
                              <FormControl
                                sx={{
                                  // backgroundColor: 'green',
                                  width: '30%',
                                }}
                                id='seconds'
                              >
                                <FormControl.Label>
                                  <Tooltip aria-label='Seconds'>
                                    Refresh interval
                                  </Tooltip>
                                </FormControl.Label>
                                <TextInput
                                  defaultValue={0}
                                  width='100%'
                                  name='seconds'
                                  placeholder='Seconds'
                                  type='number'
                                  value={app.duration}
                                  onChange={e =>
                                    updateDurationApps(index, e.target.value)
                                  }
                                />
                                {/*
                              <FormControl.Caption>
                                Duration in seconds.
                              </FormControl.Caption> */}
                              </FormControl>

                              <FormControl
                                sx={{
                                  width: '70%',
                                  // backgroundColor: 'blue',
                                }}
                              >
                                <FormControl.Label>App</FormControl.Label>
                                <Box
                                  key={app.id}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',

                                    // alignItems: 'center',
                                    width: '100%',
                                    mt: 2,
                                    gap: 2,
                                    // backgroundColor: 'red',
                                    // padding: 2,
                                    // borderRadius: 2,
                                  }}
                                >
                                  {/* <Text fontSize={1} color={'fg.default'}><Truncate sx={{}}>{file.name}</Truncate></Text> */}
                                  <Tooltip
                                    aria-label={`${app.name} ${app.url ? ' - ' + app.url : ''}`}
                                  >
                                    <Label>
                                      {app.name.length > 11
                                        ? app.name.substring(0, 11) + '...'
                                        : app.name}
                                    </Label>
                                  </Tooltip>
                                  <ButtonGroup
                                    sx={{
                                      mr: 1,
                                    }}
                                  >
                                    <IconButton
                                      variant='default'
                                      icon={Icons.ArrowUp}
                                      aria-label=''
                                      onClick={() => {
                                        moveItemApp(index, 'up')
                                      }}
                                      disabled={index === 0}
                                    />
                                    <IconButton
                                      variant='default'
                                      icon={Icons.ArrowDown}
                                      aria-label=''
                                      onClick={() => {
                                        moveItemApp(index, 'down')
                                      }}
                                      disabled={
                                        index === selectedApps.length - 1
                                      }
                                    />
                                    {/* <IconButton variant="danger" icon={Icons.Trash} aria-label="" onClick={() => {setFiles(files.filter((_, i) => i !== index))}}/> */}
                                    <IconButton
                                      variant='danger'
                                      icon={Icons.Trash}
                                      aria-label=''
                                      onClick={() => {
                                        // handleFileClick(file.id, file.name);
                                        removeApp(index)
                                      }}
                                    />
                                  </ButtonGroup>
                                </Box>
                              </FormControl>
                            </Box>
                          ))}
                        </>
                      )}
                      <FormControl.Caption>
                        Add apps to playlist. Limited to 1 app per playlist.
                      </FormControl.Caption>
                    </FormControl>
                  )}
                </Box>
              </CardContent>
              <CardFooter>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    gap: 2,
                  }}
                >
                  <Button
                    variant='default'
                    onClick={() => {
                      setSelectedFiles([])
                      setNameValue('')
                      setShowVideo(false)
                    }}
                  >
                    Clear
                  </Button>
                  {/* <Button variant="primary">Save</Button> */}
                  {playlistId ? (
                    <Button
                      variant='primary'
                      disabled={isLoading}
                      onClick={() => {
                        handleUpdate()
                      }}
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        width: '65px', // Set a fixed width
                      }}
                    >
                      {isLoading ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '47px',
                          }}
                        >
                          <Spinner size='small' />
                        </Box>
                      ) : (
                        <Text>Update</Text>
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant='primary'
                      disabled={isLoading}
                      onClick={() => {
                        handleSubmit()
                      }}
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        width: '65px', // Set a fixed width
                      }}
                    >
                      {isLoading ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '47px',
                          }}
                        >
                          <Spinner size='small' />
                        </Box>
                      ) : (
                        <Text>Submit</Text>
                      )}
                    </Button>
                  )}
                </Box>
              </CardFooter>
            </Card>
          </Box>
        </Box>
      )}
    </Box>
  )
}
