import { Box, NavList } from '@primer/react'
import { NavLink, useLocation } from 'react-router-dom'
import { sidebarNavItems, sidebarSettingsNavItems } from '@/lib/nav-items'
import { Icons } from '@/components/icons'

export function Sidebar() {
  const location = useLocation()

  const isCurrentPath = path => {
    // Check if the path matches the current location, considering potential query parameters or child paths
    const currentPath = location.pathname
    const isExactMatch = currentPath === path
    const isChildPath = currentPath.startsWith(`${path}/`)
    const isQueryMatch = currentPath.startsWith(`${path}?`)

    return isExactMatch || isChildPath || isQueryMatch
  }

  const shouldMarkAsCurrent = item => {
    // Special handling for "/dashboard" to avoid marking it as current on deeper paths
    if (item.path === '/dashboard') {
      return (
        location.pathname === '/dashboard' ||
        location.pathname.startsWith('/dashboard?')
      )
    }
    return isCurrentPath(item.path)
  }

  return (
    <Box>
      <NavList aria-label='Main navigation'>
        {sidebarNavItems.map((item, idx) => (
          <NavList.Item
            key={idx}
            as={NavLink}
            to={item.path}
            aria-current={shouldMarkAsCurrent(item) ? 'page' : ''}
          >
            {item.icon && <item.icon sx={{ mr: 2 }} />}
            {item.name}
          </NavList.Item>
        ))}
      </NavList>
      <NavList aria-label='Settings navigation'>
        <NavList.Item defaultOpen={false} href='#'>
          <Icons.Gear sx={{ mr: 2 }} />
          Settings
          <NavList.SubNav>
            {sidebarSettingsNavItems.map((item, idx) => (
              <NavList.Item
                key={idx}
                as={NavLink}
                to={item.path}
                aria-current={shouldMarkAsCurrent(item) ? 'page' : ''}
              >
                {item.icon && <item.icon sx={{ mr: 2 }} />}
                {item.name}
              </NavList.Item>
            ))}
          </NavList.SubNav>
        </NavList.Item>
      </NavList>
    </Box>
  )
}
