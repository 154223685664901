import { useEffect, useCallback, useRef, useState } from 'react'
import {
  Text,
  Box,
  FormControl,
  TextInput,
  Button,
  Dialog,
  Tooltip,
  PageHeader,
  ToggleSwitch,
  IconButton,
  ActionMenu,
  ActionList,
  Spinner,
} from '@primer/react'
import { DataTable, Table } from '@primer/react/drafts'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'
import { createRole, updateRole, deleteRole } from '@/lib/calls'
import { Icons } from '@/components/icons'
import { currentAuthenticatedUser } from '@/lib/auth'

export function RolesPage() {
  const [pageIndex, setPageIndex] = useState(0)
  const pageSize = 10 // You can adjust this value as needed
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const buttonRef = useRef()
  const onDialogClose = useCallback(() => {
    if (!isLoading) {
      setIsOpen(false)
    }
  }, [isLoading])
  const onDialogEditClose = useCallback(() => {
    if (!isLoading) {
      setIsOpenEdit(false)
    }
  }, [isLoading])
  // const onDialogClose = useCallback(() => setIsOpen(false), []);
  // const onDialogEditClose = useCallback(() => setIsOpenEdit(false), []);

  const [editRoleId, setEditRoleId] = useState('')
  // const [editRoleName, setEditRoleName] = useState('');
  const [editPermissions, setEditPermissions] = useState('')

  const [value, setValue] = useState('')
  const [validationResult, setValidationResult] = useState('')

  const [playlists, setPlaylists] = useState(false)
  const [schedules, setSchedules] = useState(false)
  const [devices, setDevices] = useState(false)
  const [content, setContent] = useState(false)
  const [billing, setBilling] = useState(false)

  const [editPlaylists, setEditPlaylists] = useState(false)
  const [editSchedules, setEditSchedules] = useState(false)
  const [editDevices, setEditDevices] = useState(false)
  const [editContent, setEditContent] = useState(false)
  const [editBilling, setEditBilling] = useState(false)

  const [reloadTable, setReloadTable] = useState(false)
  const [localProvider, setLocalProvider] = useState('')

  const [isOpenDeleteRole, setIsOpenDeleteRole] = useState(false)
  const onDialogDeleteRoleClose = useCallback(
    () => setIsOpenDeleteRole(false),
    []
  )
  const [deleteRoleId, setDeleteRoleId] = useState('')

  const handleInputChange = e => {
    setValue(e.currentTarget.value)
  }

  const handleOpenEdit = row => {
    setEditRoleId(row.id)
    // setEditRoleName(row.name)
    setValue(row.name)
    setValidationResult('validName')
    setEditPlaylists(row.permissions.playlists)
    setEditSchedules(row.permissions.schedules)
    setEditDevices(row.permissions.devices)
    setEditContent(row.permissions.content)
    setEditBilling(row.permissions.billing)
    setEditPermissions(row.permissions)
    setIsOpenEdit(true)
    // console.log('handleOpenEdit,editPermissions', editPermissions)
    console.log(editPermissions)
  }

  const handleOpenDelete = row => {
    setDeleteRoleId(row.id)
    // setEditRoleName(row.name)
    setIsOpenDeleteRole(true)
  }

  const handleOpenAddRole = () => {
    setValue('')
    setIsOpen(true)
  }

  const handleSubmit = async () => {
    if (validationResult === 'validName') {
      try {
        const permissions = {
          playlists: playlists,
          schedules: schedules,
          devices: devices,
          content: content,
          billing: billing,
        }
        // console.log(permissions)
        setIsLoading(true)
        await createRole(value, permissions)
        toast.custom(t => <CustomToast message='Role created' type='success' />)
        setReloadTable(true)
        setIsLoading(false)
        // Handle success - e.g., show a success message
        onDialogClose() // Close the dialog if successful
      } catch (error) {
        // Handle errors - e.g., show error message
        setIsLoading(false)
        toast.custom(t => (
          <CustomToast message='Error creating new role' type='error' />
        ))

        // console.log(error)
        console.error('Error creating role:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleEdit = async () => {
    if (validationResult === 'validName') {
      try {
        const permissions = {
          playlists: editPlaylists,
          schedules: editSchedules,
          devices: editDevices,
          content: editContent,
          billing: editBilling,
        }
        // console.log('handleEdit,permissions', permissions)
        // console.log('handleEdit,editRoleId', editRoleId)
        setIsLoading(true)
        await updateRole(value, permissions, editRoleId)
        toast.custom(t => <CustomToast message='Role updated' type='success' />)
        setReloadTable(true)
        setIsLoading(false)
        // Handle success - e.g., show a success message
        onDialogEditClose() // Close the dialog if successful
      } catch (error) {
        // Handle errors - e.g., show error message

        setIsLoading(false)
        toast.custom(t => (
          <CustomToast message='Error updating role' type='error' />
        ))

        // console.log(error)
        console.error('Error creating role:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleDeleteRole = async () => {
    try {
      // console.log(deleteRoleId)
      setIsLoading(true)
      await deleteRole(deleteRoleId)
      toast.custom(t => <CustomToast message='Role deleted' type='success' />)
      setIsLoading(false)
      setReloadTable(true)
      // Handle success - e.g., show a success message
      onDialogDeleteRoleClose() // Close the dialog if successful
    } catch (error) {
      // Handle errors - e.g., show error message
      setIsLoading(false)
      toast.custom(t => (
        <CustomToast message='Error deleting role' type='error' />
      ))

      // console.log(error)
      console.error('Error creating role:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await currentAuthenticatedUser()
        setLocalProvider(user)
        // console.log(user)
      } catch (err) {
        console.error('Error fetching user data:', err)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (value.length === 0) {
      setValidationResult('empty')
    } else if (value.length > 255) {
      setValidationResult('tooLong')
    } else {
      setValidationResult('validName')
    }
  }, [value])

  useEffect(() => {
    const fetchData = async () => {
      if (reloadTable) {
        try {
          const user = await currentAuthenticatedUser()
          setLocalProvider(user)
          setReloadTable(false)
        } catch (err) {
          console.error('Error reloading data:', err)
        }
      }
    }

    fetchData()
  }, [reloadTable])

  return (
    <>
      <Box sx={{}}>
      <Box sx={{ padding: 3 }}>
        <PageHeader>
          <PageHeader.TitleArea>
            <PageHeader.Title as="h2">Roles & Permissions</PageHeader.Title>
          </PageHeader.TitleArea>
          <PageHeader.Actions>
          <Button variant='primary' onClick={() => handleOpenAddRole()}>
              New role
            </Button>
          </PageHeader.Actions>
        </PageHeader>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            width: '100%',
            height: '100%',
            marginTop: 4,
            // backgroundColor: 'canvas.default',
          }}
        >
          <Box
            sx={{
              // width: ['100%', '50%'],
              // height: ['50%', '100%'],
              width: '100%',
              /* backgroundColor: 'green' */
            }}
          >
            {/* Roles & Permissions table goes here   */}
            {/* <pre>{JSON.stringify(localProvider, null, 2)}</pre> */}
            <Table.Container>
              {localProvider.user && (
                <>
                <DataTable
                  aria-labelledby='repositories'
                  aria-describedby='repositories-subtitle'
                  data={localProvider.user.current_org.roles.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)}
                  columns={[
                    // {
                    //   header: 'ID',
                    //   field: 'id',
                    //   rowHeader: true
                    // },
                    {
                      header: 'Name',
                      field: 'name',
                      renderCell: row => {
                        return (
                          <Tooltip aria-label={row.name}>
                            <Text>
                              {row.name.length > 25
                                ? `${row.name.slice(0, 25)}...`
                                : row.name}
                            </Text>
                          </Tooltip>
                        )
                      },
                    },
                    {
                      header: 'Playlists',
                      field: 'permissions.playlists',
                      renderCell: row => {
                        return row.permissions.playlists ? (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Check}
                            aria-label=''
                          />
                        ) : (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Close}
                            aria-label=''
                          />
                        )
                      },
                    },
                    {
                      header: 'Schedules',
                      field: 'permissions.schedules',
                      renderCell: row => {
                        return row.permissions.schedules ? (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Check}
                            aria-label=''
                          />
                        ) : (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Close}
                            aria-label=''
                          />
                        )
                      },
                    },
                    {
                      header: 'Devices',
                      field: 'permissions.devices',
                      renderCell: row => {
                        return row.permissions.devices ? (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Check}
                            aria-label=''
                          />
                        ) : (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Close}
                            aria-label=''
                          />
                        )
                      },
                    },
                    {
                      header: 'Content',
                      field: 'permissions.content',
                      renderCell: row => {
                        return row.permissions.content ? (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Check}
                            aria-label=''
                          />
                        ) : (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Close}
                            aria-label=''
                          />
                        )
                      },
                    },
                    {
                      header: 'Billing',
                      field: 'permissions.billing',
                      renderCell: row => {
                        return row.permissions.billing ? (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Check}
                            aria-label=''
                          />
                        ) : (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Close}
                            aria-label=''
                          />
                        )
                      },
                    },
                    {
                      header: 'Actions',
                      field: 'actions',
                      renderCell: row => {
                        return (
                          <ActionMenu>
                            <ActionMenu.Anchor>
                              <IconButton
                                variant='invisible'
                                icon={Icons.Menu}
                                aria-label=''
                              />
                            </ActionMenu.Anchor>
                            <ActionMenu.Overlay width='medium'>
                              <ActionList>
                                {/* <ActionList.Item onSelect={() => alert('Copy role id clicked')}>
                                Copy role id
                              </ActionList.Item> */}
                                <ActionList.Item
                                  onSelect={
                                    () =>
                                      // setEditRoleId(row.id)
                                      handleOpenEdit(row)
                                    // setIsOpenEdit(true)
                                  }
                                >
                                  Edit role
                                </ActionList.Item>
                                <ActionList.Divider />
                                <ActionList.Item
                                  variant='danger'
                                  onSelect={() => handleOpenDelete(row)}
                                >
                                  Delete role
                                </ActionList.Item>
                              </ActionList>
                            </ActionMenu.Overlay>
                          </ActionMenu>
                        )
                      },
                    },
                  ]}
                />
                <Table.Pagination
                  aria-label="Pagination for Roles"
                  pageSize={pageSize}
                  totalCount={localProvider.user.current_org.roles.length}
                  pageIndex={pageIndex}
                  onChange={({ pageIndex }) => setPageIndex(pageIndex)}
                />
                </>
                
              )}
     
            </Table.Container>
            {/* {authProvider.user.current_org.roles.map((role, idx) => (
                <Box key={idx}>{role.name}</Box>
              ))} */}
          </Box>
        </Box>
      </Box>
      {isOpenDeleteRole && (
        <Dialog
          isOpen={isOpenDeleteRole}
          onDismiss={onDialogDeleteRoleClose}
          // returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Are you sure?</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' sx={{ color: 'fg.muted' }} fontSize={1}>
              This action cannot be undone.
            </Text>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                variant='invisible'
                onClick={onDialogDeleteRoleClose}
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button
                variant='danger'
                disabled={isLoading}
                onClick={() => {
                  handleDeleteRole()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '120px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Delete role</Text>
                )}
              </Button>
              {/* <Button type="submit" onClick={handleDeleteRole} variant="danger">Delete role</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}
      {isOpen && (
        <Dialog
          isOpen={isOpen}
          onDismiss={onDialogClose}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Create a new role</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' sx={{ color: 'fg.muted' }} fontSize={1}>
              Create a new role and assign permissions.
            </Text>
            <Box mt={3}>
              <FormControl required>
                <FormControl.Label>Name</FormControl.Label>
                <TextInput block value={value} onChange={handleInputChange} />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'tooLong' && (
                  <FormControl.Validation variant='error'>
                    Name is invalid
                  </FormControl.Validation>
                )}
                {validationResult === 'validName' && (
                  <FormControl.Validation variant='success'>
                    Valid name
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a valid role name.
                </FormControl.Caption>
              </FormControl>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Playlists
                </Text>
                <ToggleSwitch
                  checked={playlists}
                  onClick={() => setPlaylists(!playlists)}
                  aria-labelledby='toggle'
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Schedules
                </Text>
                <ToggleSwitch
                  checked={schedules}
                  onClick={() => setSchedules(!schedules)}
                  aria-labelledby='toggle'
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Devices
                </Text>
                <ToggleSwitch
                  checked={devices}
                  onClick={() => setDevices(!devices)}
                  aria-labelledby='toggle'
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Content
                </Text>
                <ToggleSwitch
                  checked={content}
                  onClick={() => setContent(!content)}
                  aria-labelledby='toggle'
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Billing
                </Text>
                <ToggleSwitch
                  checked={billing}
                  onClick={() => setBilling(!billing)}
                  aria-labelledby='toggle'
                  disabled
                />
              </Box>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogClose}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleSubmit()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
              {/* <Button onClick={() => (handleSubmit())} variant="primary">Submit</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}
      {isOpenEdit && (
        <Dialog
          isOpen={isOpenEdit}
          onDismiss={onDialogEditClose}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Update role</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' sx={{ color: 'fg.muted' }} fontSize={1}>
              Update the existing role and assign permissions.
            </Text>
            <Box mt={3}>
              <FormControl required>
                <FormControl.Label>Name</FormControl.Label>
                <TextInput block value={value} onChange={handleInputChange} />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'tooLong' && (
                  <FormControl.Validation variant='error'>
                    Name is invalid
                  </FormControl.Validation>
                )}
                {validationResult === 'validName' && (
                  <FormControl.Validation variant='success'>
                    Valid name
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a valid role name.
                </FormControl.Caption>
              </FormControl>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Playlists
                </Text>
                <ToggleSwitch
                  checked={editPlaylists}
                  onClick={() => setEditPlaylists(!editPlaylists)}
                  aria-labelledby='toggle'
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Schedules
                </Text>
                <ToggleSwitch
                  checked={editSchedules}
                  onClick={() => setEditSchedules(!editSchedules)}
                  aria-labelledby='toggle'
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Devices
                </Text>
                <ToggleSwitch
                  checked={editDevices}
                  onClick={() => setEditDevices(!editDevices)}
                  aria-labelledby='toggle'
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Content
                </Text>
                <ToggleSwitch
                  checked={editContent}
                  onClick={() => setEditContent(!editContent)}
                  aria-labelledby='toggle'
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Billing
                </Text>
                <ToggleSwitch
                  checked={editBilling}
                  onClick={() => setEditBilling(!editBilling)}
                  aria-labelledby='toggle'
                  disabled
                />
              </Box>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogEditClose}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleEdit()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
              {/* <Button onClick={() => (handleEdit())} variant="primary">Save changes</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  )
}
