/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Text,
  Box,
  SubNav,
  NavList,
  PageLayout,
  PageHeader,
  Label,
  FormControl,
  Button,
  Spinner,
  TextInput,
  Select,
  ActionList,
  Tooltip,
  Dialog,
} from '@primer/react';
import { SelectPanel } from '@primer/react/drafts';
import { debounce } from 'lodash';
import { Icons } from '@/components/icons';
import {
  getSyncRules,
  getSyncRule,
  patchSyncRule,
  deleteSyncRule,
  getDeviceGroup,
} from '@/lib/calls';
import { toast } from 'react-hot-toast';
import { CustomToast } from './custom-toast';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function SyncRulesPage() {
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [selectedSyncRuleStatus, setSelectedSyncRuleStatus] = useState(null);

  const [reloadPage, setReloadPage] = useState(false);

  const [isOpenDeleteSyncRuleDialog, setIsOpenDeleteSyncRuleDialog] =
    useState(false);
  const onDialogCloseDeleteSyncRuleDialog = () =>
    setIsOpenDeleteSyncRuleDialog(false);
  const [isLoadingDeleteSyncRule, setIsLoadingDeleteSyncRule] = useState(false);
  const [syncRuleIdToDelete, setSyncRuleIdToDelete] = useState(null);

  const handleSubmitDeleteSyncRule = async () => {
    setIsLoadingDeleteSyncRule(true);
    try {
      await deleteSyncRule(syncRuleIdToDelete);
      setIsLoadingDeleteSyncRule(false);
      setIsOpenDeleteSyncRuleDialog(false);
      toast.custom(t => (
        <CustomToast message='Sync rule deleted' type='success' />
      ));
      setIsLoadingDeleteSyncRule(false);
      setReloadPage(prev => !prev);
      setSyncRulePreview(false);
    } catch (error) {
      setIsLoadingDeleteSyncRule(false);
      setIsOpenDeleteSyncRuleDialog(false);
      toast.custom(t => (
        <CustomToast message='Error could not delete sync rule' type='error' />
      ));
      console.error('Error deleting sync rule:', error);
    }
  };

  const buttonRef = useRef(null);

  const queryParam = useQuery();
  const syncRuleIdParam = queryParam.get('sync_rule_id');

  const [isLoading, setIsLoading] = useState(false);
  const [restricted, setRestricted] = useState(false);
  const [syncRules, setSyncRules] = useState(null);
  const [syncRuleId, setSyncRuleId] = useState(null);
  const [selectedSyncRule, setSelectedSyncRule] = useState(null);
  const [syncRulePreview, setSyncRulePreview] = useState(false);
  const [restartValue, setRestartValue] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSyncRules = async () => {
      setIsLoading(true);
      try {
        const response = await getSyncRules();
        const sortedSyncRules = response.data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setSyncRules(sortedSyncRules);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching sync rules', error);
        if (error?.response?.status === 403) {
          setRestricted(true);
        }
        setFetchError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSyncRules();
  }, [reloadPage]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (syncRuleIdParam) {
        setSyncRuleId(syncRuleIdParam);
        const fetchSyncRule = async () => {
          try {
            const response = await getSyncRule(syncRuleIdParam);
            setSelectedSyncRuleStatus(response.data.data);
          } catch (error) {
            console.error('Error fetching sync rule', error);
            if (error.response.status === 403) {
              setRestricted(true);
            }
            setFetchError(error);
          }
        };
        fetchSyncRule();
      }
    }, 5000); // Fetch data every 5 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [syncRuleIdParam]);

  const handleSubmitClick = async (id, rule) => {
    setSyncRuleId(id);
    setSelectedSyncRule(rule);
    setSelectedSyncRuleStatus(rule);
    setSyncRulePreview(true);
    navigate('?sync_rule_id=' + id);
  };

  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState(null);
  const [nameValue, setNameValue] = useState('');
  const [validationResult, setValidationResult] = useState('empty');
  const [isLoadingDeviceGroup, setIsLoadingDeviceGroup] = useState(false);

  const [devices, setDevices] = useState([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  const handleOpen = async () => {
    setSelectedDeviceIds(selectedDeviceGroup.map(item => item.id));
    setIsOpen(!isOpen);
    setIsLoading(true);
    try {
      if (selectedSyncRule.group_id) {
        const devices = await getDeviceGroup(selectedSyncRule.group_id);
        if (devices) {
          setDevices(devices.data.data.devices);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setFetchError(error);
      setIsLoading(false);
      console.error('Error fetching devices', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeviceSelect = deviceId => {
    if (!selectedDeviceIds.includes(deviceId))
      setSelectedDeviceIds([...selectedDeviceIds, deviceId]);
    else setSelectedDeviceIds(selectedDeviceIds.filter(id => id !== deviceId));
  };

  const onClearSelection = () => {
    setSelectedDeviceIds([]);
  };

  const handleSubmitPatchSyncRule = async () => {
    try {
      setIsLoading(true);

      const preparePayload = selectedDeviceIds.map(deviceId => ({
        id: deviceId,
      }));

      await patchSyncRule(
        nameValue,
        syncRuleId,
        restartValue,
        selectedSyncRule.group_id,
        preparePayload
      );

      setIsLoading(false);
      toast.custom(t => (
        <CustomToast message='Sync rule updated' type='success' />
      ));
      setReloadPage(prev => !prev);

    } catch (error) {
      setIsLoading(false);
      toast.custom(t => (
        <CustomToast message='Error could not update sync rule' type='error' />
      ));
      console.error('Error updating sync rule:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    setIsOpen(!isOpen);
  };

  const [filteredDevices, setFilteredDevices] = useState([]);
  const [query, setQuery] = useState('');

  const debouncedSearch = useCallback(
    debounce(query => {
      if (query === '') setFilteredDevices(devices);
      else {
        setFilteredDevices(
          devices
            .map(device => {
              if (device.name.toLowerCase().startsWith(query))
                return { priority: 1, device };
              else if (device.name.toLowerCase().includes(query))
                return { priority: 2, device };
              else return { priority: -1, device };
            })
            .filter(result => result.priority > 0)
            .map(result => result.device)
        );
      }
    }, 300),
    [devices]
  );

  const onSearchInputChange = event => {
    const query = event.currentTarget.value.toLowerCase();
    setQuery(query);
    debouncedSearch(query);
  };

  const handleScroll = useCallback(() => {
    if (!isLoading && isOpen) {
      // Check if the user has scrolled to the bottom
      if (buttonRef.current.scrollHeight - buttonRef.current.scrollTop === buttonRef.current.clientHeight) {
        setIsLoading(true);
        // Load more items here
        setIsLoading(false);
      }
    }
  }, [isLoading, isOpen]);

  const sortingFn = (itemA, itemB) => {
    const initialSelectedIds = selectedDeviceGroup.map(item => item.id);
    if (initialSelectedIds.includes(itemA.id) && initialSelectedIds.includes(itemB.id)) return 1;
    else if (initialSelectedIds.includes(itemA.id)) return -1;
    else if (initialSelectedIds.includes(itemB.id)) return 1;
    else return 1;
  };

  const itemsToShow = query ? filteredDevices : devices.sort(sortingFn);

  const title = <Text sx={{ color: 'fg.default' }}>Select devices</Text>;
  const notFound = <Text sx={{ color: 'fg.default' }}>No devices found</Text>;

  const handleInputChange = e => {
    setNameValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (nameValue.length === 0) {
      setValidationResult('empty');
    } else if (nameValue.length > 255) {
      setValidationResult('tooLong');
    } else if (nameValue.length < 3) {
      setValidationResult('tooShort');
    } else {
      setValidationResult('validName');
    }
  }, [nameValue]);

  useEffect(() => {
    if (syncRuleIdParam) {
      setSyncRuleId(syncRuleIdParam);
      const fetchSyncRule = async () => {
        setIsLoadingPreview(true);
        setIsLoadingDeviceGroup(true);
        try {
          const response = await getSyncRule(syncRuleIdParam);
          setRestartValue(response.data.data.restart);
          const deviceGroupData = response.data.data.devices.map(device => ({
            name: device.name,
            text: device.name,
            id: device.id,
          }));
          setSelectedDeviceGroup(deviceGroupData);
          setSelectedDeviceIds(deviceGroupData.map(item => item.id));
          setNameValue(response.data.data.name);
          setIsLoadingPreview(false);
          setIsLoadingDeviceGroup(false);
        } catch (error) {
          console.error('Error fetching sync rule', error);
          if (error?.response?.status === 403) {
            setRestricted(true);
          }
          setFetchError(error);
          setIsLoadingPreview(false);
          setIsLoadingDeviceGroup(false);
        }
      };
      fetchSyncRule();
    }
  }, [syncRuleIdParam]);

  return (
    <>
      <Box sx={{}}>
        <Box sx={{ padding: 3 }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Sync rules</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Description sx={{ fontSize: 1, color: 'fg.muted' }}>
              Synchronize rendering on a subset of devices under a device group.
            </PageHeader.Description>
          </PageHeader>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], width: '100%', height: '100%', marginTop: 4 }}>
          <Box sx={{ width: '100%', height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <SubNav>
                <SubNav.Links>
                  <SubNav.Link as={Link} to='/dashboard/sync'>
                    New sync rule
                  </SubNav.Link>

                  <SubNav.Link href='#' selected>
                    Sync rules
                  </SubNav.Link>
                </SubNav.Links>
              </SubNav>

              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {restricted && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      position: 'relative',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: 3,
                      mt: 4,
                    }}
                  >
                    <Text sx={{ fontSize: 4, fontWeight: 'bold', textAlign: 'center' }}>
                      You don't have access to this content.
                    </Text>
                    <Text sx={{ fontSize: 1, color: 'fg.muted' }}>
                      <Icons.Alert /> Insufficient permissions to manage devices.
                    </Text>
                  </Box>
                )}

                {isLoading && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      position: 'relative',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: 3,
                      mt: 4,
                    }}
                  >
                    <Spinner />
                  </Box>
                )}

                {fetchError && !restricted && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      position: 'relative',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: 3,
                      mt: 4,
                    }}
                  >
                    <Text sx={{ fontSize: 4, fontWeight: 'bold', textAlign: 'center' }}>
                      An error occurred fetching content.
                    </Text>
                    <Text sx={{ fontSize: 1, color: 'fg.muted' }}>
                      <Icons.Alert /> Please try again later.
                    </Text>
                  </Box>
                )}

                {syncRules &&
                  syncRules.length === 0 &&
                  !isLoading &&
                  !fetchError &&
                  !restricted && (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        position: 'relative',
                        alignContent: 'center',
                        alignItems: 'center',
                        gap: 3,
                      }}
                    >
                      <Text sx={{ fontSize: 4, fontWeight: 'bold' }}>
                        No sync rules found.
                      </Text>
                      <Text sx={{ fontSize: 1, color: 'fg.muted' }}>
                        Create a new sync rule to get started.
                      </Text>
                    </Box>
                  )}

                {syncRules && (
                  <PageLayout sx={{ width: '100%' }}>
                    <PageLayout.Pane
                      position='start'
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '640px',
                        '@media (max-width: 900px)': {
                          width: '100%', // Make the pane full width on small screens
                        },
                      }}
                    >
                      <NavList>
                        {syncRules &&
                          syncRules.length > 0 &&
                          syncRules.map(rule => (
                            <NavList.Item
                              key={rule.id}
                              aria-current={syncRuleIdParam === rule.id.toString()}
                              onClick={() => handleSubmitClick(rule.id, rule)}
                            >
                              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, alignItems: 'center' }}>
                                <Text sx={{ color: 'fg.muted' }}>
                                  <Icons.Sync />
                                </Text>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                  <Text>
                                    {rule.name.length > 25
                                      ? `${rule.name.slice(0, 25)}...`
                                      : rule.name}
                                  </Text>
                                  <Text sx={{ fontSize: 1, color: 'fg.muted' }}>
                                    <Label variant='accent'>
                                      <Icons.Devices sx={{ width: 14, height: 14, mr: 1 }} />{' '}
                                      {rule.devices ? rule.devices.length : 0}
                                    </Label>{' '}
                                    devices in sync
                                  </Text>
                                </Box>
                              </Box>
                            </NavList.Item>
                          ))}
                      </NavList>
                    </PageLayout.Pane>
                    <PageLayout.Content>
                      {syncRulePreview && (
                        <>
                          {isLoadingPreview && (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                              }}
                            >
                              <Spinner />
                            </Box>
                          )}
                          {!isLoadingPreview && (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: ['770px', '710px'],
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'border.default',
                                padding: 4,
                                gap: 2,
                              }}
                            >
                              {syncRuleId && selectedSyncRule && (
                                <>
                                  <Text sx={{ fontSize: 2, fontWeight: 'bold', color: 'fg.default', mb: 3 }}>
                                    Edit sync rule
                                  </Text>
                                  <FormControl>
                                    <FormControl.Label htmlFor='id'>Sync status</FormControl.Label>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                      <Tooltip aria-label={`${selectedSyncRuleStatus.ready ? 'ready' : 'pending'}`}>
                                        <Text>
                                          {selectedSyncRuleStatus.ready ? (
                                            <Box sx={{ display: 'flex' }}>
                                              <Box
                                                sx={{
                                                  backgroundColor: 'success.fg',
                                                  borderColor: 'success.fg',
                                                  width: 14,
                                                  height: 14,
                                                  borderRadius: 10,
                                                  margin: 'auto',
                                                  borderWidth: '1px',
                                                  borderStyle: 'solid',
                                                }}
                                              />
                                              <Text fontSize={1} color='success.fg' ml={1}>
                                                Sync ready
                                              </Text>
                                            </Box>
                                          ) : (
                                            <Box sx={{ display: 'flex' }}>
                                              <Box
                                                sx={{
                                                  backgroundColor: 'attention.fg',
                                                  borderColor: 'attention.fg',
                                                  width: 14,
                                                  height: 14,
                                                  borderRadius: 10,
                                                  margin: 'auto',
                                                  borderWidth: '1px',
                                                  borderStyle: 'solid',
                                                }}
                                              />
                                              {!selectedSyncRuleStatus.timed_out && (
                                                <Text fontSize={1} color='attention.fg' ml={1}>
                                                  Sync pending
                                                </Text>
                                              )}
                                            </Box>
                                          )}
                                        </Text>
                                      </Tooltip>
                                      {selectedSyncRuleStatus.timed_out && (
                                        <Text fontSize={1} color='danger.fg'>
                                          <Icons.Alert /> Sync failed with timeout due to device inactivity.
                                          Resync will be attempted according to the restart policy.
                                        </Text>
                                      )}
                                    </Box>

                                    <FormControl.Caption>
                                      The sync status your devices receives from the cloud.
                                    </FormControl.Caption>
                                  </FormControl>

                                  <FormControl disabled>
                                    <FormControl.Label htmlFor='lastSync'>Last sync</FormControl.Label>
                                    <TextInput
                                      value={selectedSyncRule.last_sync !== null ? selectedSyncRule.last_sync : 'N/A'}
                                      width='100%'
                                    />
                                    <FormControl.Caption>
                                      The time of last successful sync.
                                    </FormControl.Caption>
                                  </FormControl>

                                  <FormControl required id='name'>
                                    <FormControl.Label htmlFor='name'>Name</FormControl.Label>
                                    <TextInput
                                      value={nameValue}
                                      onChange={handleInputChange}
                                      name='name'
                                      placeholder='Name'
                                      width='100%'
                                    />
                                    {validationResult === 'empty' && (
                                      <FormControl.Validation variant='error'>Name cannot be empty</FormControl.Validation>
                                    )}
                                    {validationResult === 'tooLong' && (
                                      <FormControl.Validation variant='error'>Name cannot be longer than 255 characters</FormControl.Validation>
                                    )}
                                    {validationResult === 'tooShort' && (
                                      <FormControl.Validation variant='error'>Name cannot be less than 3 characters</FormControl.Validation>
                                    )}
                                    {validationResult === 'validName' && (
                                      <FormControl.Validation variant='success'>Valid name</FormControl.Validation>
                                    )}
                                    <FormControl.Caption>
                                      Please enter a name (3 to 255 characters).
                                    </FormControl.Caption>
                                  </FormControl>

                                  <FormControl disabled id='deviceGroup'>
                                    <FormControl.Label htmlFor='group'>Group</FormControl.Label>
                                    <TextInput width='100%' value={selectedSyncRule.group_id} />
                                    <FormControl.Caption>
                                      The group can't be changed for sync rules.
                                    </FormControl.Caption>
                                  </FormControl>

                                  <FormControl required>
                                    <FormControl.Label id='autocompleteLabel'>Synced devices</FormControl.Label>
                                    {isLoadingDeviceGroup && <Spinner size='small' />}
                                    {!isLoadingDeviceGroup && (
                                      <>
                                        <Button
                                          ref={buttonRef}
                                          disabled={!selectedDeviceGroup}
                                          variant='default'
                                          trailingAction={() => (
                                            <Label variant='accent'>
                                              <Icons.Devices sx={{ width: 14, height: 14, mr: 1 }} />
                                              {selectedDeviceIds.length}
                                            </Label>
                                          )}
                                          onClick={() => {
                                            handleOpen();
                                          }}
                                          sx={{ width: '100%', '[data-component=buttonContent]': { justifyContent: 'start' } }}
                                        >
                                          Select devices to sync
                                        </Button>

                                        <SelectPanel
                                          title={title}
                                          onSubmit={onSubmit}
                                          open={isOpen}
                                          anchorRef={buttonRef}
                                          variant='anchored'
                                          onCancel={() => {
                                            setIsOpen(!isOpen);
                                          }}
                                          onClearSelection={onClearSelection}
                                        >
                                          <SelectPanel.Header>
                                            <SelectPanel.SearchInput onChange={onSearchInputChange} />
                                          </SelectPanel.Header>

                                          {itemsToShow.length === 0 && !fetchError && (
                                            <SelectPanel.Message variant='empty' title={notFound}>
                                              Try a different search term
                                            </SelectPanel.Message>
                                          )}

                                          {itemsToShow.length > 0 && !isLoading && !fetchError && (
                                            <Box sx={{ height: '200px', overflowY: 'auto' }} onScroll={handleScroll}>
                                              <ActionList>
                                                {itemsToShow.map(device => (
                                                  <ActionList.Item
                                                    key={device.id}
                                                    onSelect={() => onDeviceSelect(device.id)}
                                                    selected={selectedDeviceIds.includes(device.id)}
                                                  >
                                                    <ActionList.LeadingVisual>
                                                      <Box
                                                        sx={{
                                                          width: 14,
                                                          height: 14,
                                                          borderRadius: '100%',
                                                        }}
                                                        style={{
                                                          backgroundColor: `#${device.color}`,
                                                        }}
                                                      />
                                                    </ActionList.LeadingVisual>
                                                    {device.name.length > 25 ? `${device.name.slice(0, 25)}...` : device.name}
                                                  </ActionList.Item>
                                                ))}
                                              </ActionList>
                                            </Box>
                                          )}

                                          {isLoading && (
                                            <Box sx={{ display: 'flex', color: 'fg.default', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                                              <Spinner />
                                            </Box>
                                          )}

                                          {fetchError && (
                                            <SelectPanel.Message variant='error' title='Error fetching devices'>
                                              Unable to fetch devices
                                            </SelectPanel.Message>
                                          )}
                                        </SelectPanel>
                                      </>
                                    )}

                                    <FormControl.Caption>
                                      Selected devices will start playing the playlist content at the same time.
                                    </FormControl.Caption>
                                  </FormControl>

                                  <FormControl required>
                                    <FormControl.Label>Restart</FormControl.Label>
                                    <Select
                                      value={restartValue}
                                      onChange={e => setRestartValue(e.target.value)}
                                      sx={{ width: '100%' }}
                                    >
                                      <Select.Option value='hourly'>Hourly</Select.Option>
                                      <Select.Option value='daily'>Daily</Select.Option>
                                      <Select.Option value='never'>Never</Select.Option>
                                    </Select>

                                    <FormControl.Caption>
                                      Resyncronization interval.
                                    </FormControl.Caption>
                                  </FormControl>

                                  <Box sx={{ display: 'flex', gap: 3, justifyContent: 'flex-end', mt: 4 }}>
                                    <Button
                                      variant='danger'
                                      onClick={() => {
                                        setIsOpenDeleteSyncRuleDialog(true);
                                        setSyncRuleIdToDelete(syncRuleId);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                    <Button
                                      variant='primary'
                                      disabled={isLoading}
                                      onClick={() => {
                                        handleSubmitPatchSyncRule();
                                      }}
                                      sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '65px' }}
                                    >
                                      {isLoading ? (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '94px' }}>
                                          <Spinner size='small' />
                                        </Box>
                                      ) : (
                                        <Text>Save and sync</Text>
                                      )}
                                    </Button>
                                  </Box>
                                </>
                              )}
                            </Box>
                          )}
                        </>
                      )}
                    </PageLayout.Content>
                  </PageLayout>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {isOpenDeleteSyncRuleDialog && (
        <Dialog
          isOpen={isOpenDeleteSyncRuleDialog}
          onDismiss={onDialogCloseDeleteSyncRuleDialog}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Delete sync rule</Dialog.Header>
          <Box p={3}>
            <Text fontSize={1} id='dialog-label' color='fg.muted'>
              This action can't be undone. Are you sure you want to delete this sync rule?
            </Text>
            <Box mt={3}></Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogCloseDeleteSyncRuleDialog}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='danger'
                disabled={isLoadingDeleteSyncRule}
                onClick={() => {
                  handleSubmitDeleteSyncRule();
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '120px', // Set a fixed width
                }}
              >
                {isLoadingDeleteSyncRule ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', width: '47px' }}>
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Delete sync</Text>
                )}
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
}
