import React from 'react'
import ReactDOM from 'react-dom/client'
// import './index.css';
// import '@primer/css/utilities/index.scss';
import App from './App'
// import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import reportWebVitals from './reportWebVitals'

// const config = {
//   apiKey: 'hbp_7RuaUzTWSVNoZVR26nPW5MHW',
//   environment: 'production',
// }

// const honeybadger = Honeybadger.configure(config)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
