import { useEffect, useRef, useState } from 'react'
import { Turnstile } from '@marsidev/react-turnstile'
import { useLocation } from 'react-router-dom'

import { API_ROUTE } from '@/lib/constants'
import {
  Button,
  Box,
  Spinner,
  Link,
  Text,
  FormControl,
  TextInput,
} from '@primer/react'
import { Icons } from '@/components/icons'
import Logo from '@/components/ui/logo'
import {
  checkEmail,
  loginEmail,
  registerEmail,
  verifyEmailCode,
  forgotPassword,
} from '@/lib/calls'
import { Toaster } from 'react-hot-toast'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

export function LoginPageBeta() {
  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState('')
  const [tokenRegister, setTokenRegister] = useState('')
  const ref = useRef()

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const confirmEmailParam = query.get('confirm_email_code')

  useEffect(() => {
    if (confirmEmailParam) {
      try {
        setIsLoading(true)
        verifyEmailCode(confirmEmailParam)
        setIsLoading(false)
        toast.custom(t => (
          <CustomToast message='Email verified' type='success' />
        ))
      } catch (error) {
        setIsLoading(false)
        toast.custom(t => (
          <CustomToast message='Error verifying email' type='error' />
        ))
      } finally {
        setIsLoading(false)
      }
    }
  }, [confirmEmailParam])

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      // console.log('event target', event.target)
      if (!verifyEmail && !showResetPassword && !newAccount) {
        handleSubmitCheckEmail()
      }
      if (newAccount) {
        handleSubmitRegister()
      }
      if (showPassword && !newAccount && !verifyEmail) {
        handleSubmitLoginEmail()
      }
      if (verifyEmail) {
        handleSubmitVerifyCode()
      }
      if (showResetPassword) {
        handleSubmitForgotPassword()
      }
    }
  }

  const [valueEmail, setValueEmail] = useState('')
  const [validationResultEmail, setValidationResultEmail] = useState('')
  const [valuePassword, setValuePassword] = useState('')
  const [validationResultPassword, setValidationResultPassword] = useState('')
  const [valueConfirmPassword, setValueConfirmPassword] = useState('')
  const [validationResultConfirmPassword, setValidationResultConfirmPassword] =
    useState('')
  // const [valueOTP, setValueOTP] = useState('')
  // const [validationResultOTP, setValidationResultOTP] = useState('')
  const [startedTypingEmail, setStartedTypingEmail] = useState(false)
  // const [checkEmailResponse, setCheckEmailResponse] = useState({})
  // const [loginEmailResponse, setLoginEmailResponse] = useState({})
  const [startedTypingPassword, setStartedTypingPassword] = useState(false)
  const [valueName, setValueName] = useState('')
  const [validationResultName, setValidationResultName] = useState('')
  const [startedTypingName, setStartedTypingName] = useState(false)
  const [startedTypingConfirmPassword, setStartedTypingConfirmPassword] =
    useState(false)
  // const [registerResponse, setRegisterResponse] = useState({})
  const [verifyEmail, setVerifyEmail] = useState(false)
  const [valueEmailVerifyCode, setValueEmailVerifyCode] = useState('')
  const [validationResultEmailVerifyCode, setValidationResultEmailVerifyCode] =
    useState('')
  const [startedTypingEmailVerifyCode, setStartedTypingEmailVerifyCode] =
    useState('')

  const handleInputChangeEmail = e => {
    setStartedTypingEmail(true)
    setValueEmail(e.currentTarget.value)
  }

  const handleInputChangeName = e => {
    setStartedTypingName(true)
    setValueName(e.currentTarget.value)
  }

  const handleInputChangeConfirmPassword = e => {
    setStartedTypingConfirmPassword(true)
    setValueConfirmPassword(e.currentTarget.value)
  }

  const handleInputChangePassword = e => {
    setStartedTypingPassword(true)
    setValuePassword(e.currentTarget.value)
  }

  const handleInputChangeEmailVerifyCode = e => {
    setStartedTypingEmailVerifyCode(true)
    setValueEmailVerifyCode(e.currentTarget.value)
  }

  const isValidEmail = email => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
    return emailRegex.test(email)
  }

  const handleSubmitCheckEmail = async () => {
    try {
      if (validationResultEmail === 'validEmail') {
        setIsLoading(true)
        const resp = await checkEmail(valueEmail)
        // setCheckEmailResponse(resp)
        if (resp.data === 'new') {
          setShowPassword(false)
          setNewAccount(true)
        }
        if (resp.data === 'email') {
          setShowPassword(true)
        }

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmitForgotPassword = async () => {
    setShowResetPassword(true)
    setNewAccount(false)
    setShowPassword(false)
    try {
      if (valueEmail.length === 0) {
        setValidationResultEmail('empty')
      }
      if (validationResultEmail === 'validEmail' && token !== '') {
        setIsLoading(true)
        await forgotPassword(valueEmail, token)
        setIsLoading(false)
        toast.custom(t => (
          <CustomToast
            message='Password reset link sent to email'
            type='success'
          />
        ))
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response.status === 401) {
        if (error.response.data.msg === 'invalid cf_token') {
          ref.current?.reset()
        }
      } else {
        toast.custom(t => (
          <CustomToast
            message='Error sending password reset link'
            type='error'
          />
        ))
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmitVerifyCode = async () => {
    try {
      if (validationResultEmailVerifyCode === 'validCode') {
        setIsLoading(true)
        // const resp = await verifyEmailCode(valueEmailVerifyCodee)
        await verifyEmailCode(valueEmailVerifyCode)
        toast.custom(t => (
          <CustomToast message='Email verified' type='success' />
        ))
        // setCheckEmailResponse(resp)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      toast.custom(t => (
        <CustomToast message='Error verifying email' type='error' />
      ))
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmitRegister = async () => {
    // alert('correct')

    if (valueName.length === 0) {
      setValidationResultName('empty')
    }
    if (valuePassword.length === 0) {
      setValidationResultPassword('empty')
    }
    if (valueConfirmPassword.length === 0) {
      setValidationResultConfirmPassword('empty')
    }

    if (
      validationResultEmail === 'validEmail' &&
      validationResultPassword === 'validPassword' &&
      validationResultName === 'validName' &&
      validationResultConfirmPassword === 'passwordMatch' &&
      tokenRegister !== ''
    ) {
      setIsLoading(true)
      try {
        await registerEmail(valueEmail, valuePassword, valueName, tokenRegister)

        setIsLoading(false)
        // setRegisterResponse(resp)
        // todo some things here
        toast.custom(t => (
          <CustomToast message='Successfully signed up' type='success' />
        ))

        setNewAccount(false)
        setShowPassword(true)
      } catch (error) {
        setIsLoading(false)
        if (error.response.status === 401) {
          if (error.response.data.msg === 'invalid cf_token') {
            alert('error')
            ref.current?.reset()
          }
        } else {
          toast.custom(t => (
            <CustomToast message='Error signing up' type='error' />
          ))
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleSubmitLoginEmail = async () => {
    // alert('correct')
    try {
      if (
        validationResultEmail === 'validEmail' &&
        validationResultPassword === 'validPassword'
      ) {
        setIsLoading(true)
        await loginEmail(valueEmail, valuePassword)
        // setLoginEmailResponse(resp)
        // todo some things here
        toast.custom(t => (
          <CustomToast message='Successfully signed in' type='success' />
        ))

        setIsLoading(false)
      }
    } catch (error) {
      // setIsLoading(false)
      if (error.response.status !== 200) {
        if (error.response?.data?.msg === 'email not verified') {
          setVerifyEmail(true)
          toast.custom(t => (
            <CustomToast
              message='Code sent to email for verification'
              type='success'
            />
          ))
        }
        if (
          error.response?.data?.msg ===
          'error creating email verification token'
        ) {
          toast.custom(t => (
            <CustomToast message='Please check your email' type='error' />
          ))
        }

        if (error.response?.data?.msg === 'invalid email or password') {
          toast.custom(t => (
            <CustomToast message='Invalid credentials' type='error' />
          ))
        }
      } else {
        toast.custom(t => (
          <CustomToast message='Error signing in' type='error' />
        ))
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (startedTypingEmail && valueEmail.length === 0) {
      setValidationResultEmail('empty')
    }
    if (!isValidEmail(valueEmail) && startedTypingEmail) {
      setValidationResultEmail('invalidEmail')
    }
    if (startedTypingEmail && isValidEmail(valueEmail)) {
      setValidationResultEmail('validEmail')
    }
  }, [valueEmail, startedTypingEmail])

  useEffect(() => {
    if (startedTypingEmailVerifyCode) {
      if (valueEmailVerifyCode.length === 0) {
        setValidationResultEmailVerifyCode('empty')
      } else if (valueEmailVerifyCode.length !== 6) {
        setValidationResultEmailVerifyCode('invalidCode')
      } else {
        setValidationResultEmailVerifyCode('validCode')
      }
    }
  }, [valueEmailVerifyCode, startedTypingEmailVerifyCode])

  useEffect(() => {
    if (startedTypingName) {
      if (valueName.length === 0) {
        setValidationResultName('empty')
      } else if (valueName.length < 3) {
        setValidationResultName('tooShort')
      } else if (valueName.length > 60) {
        setValidationResultName('tooLong')
      } else if (!/^[A-Za-z\s]+$/.test(valueName)) {
        // If the name doesn't match the allowed pattern
        setValidationResultName('invalidCharacters')
      } else {
        // If none of the above, the name is valid
        setValidationResultName('validName')
      }
    }
  }, [valueName, startedTypingName])

  useEffect(() => {
    if (startedTypingConfirmPassword) {
      if (startedTypingConfirmPassword && valueConfirmPassword.length === 0) {
        setValidationResultConfirmPassword('empty')
      } else if (
        startedTypingConfirmPassword &&
        valueConfirmPassword.length < 16
      ) {
        setValidationResultConfirmPassword('tooShort')
      } else if (
        startedTypingConfirmPassword &&
        valueConfirmPassword.length > 60
      ) {
        setValidationResultConfirmPassword('tooLong')
      } else if (valueConfirmPassword !== valuePassword) {
        setValidationResultConfirmPassword('passwordMismatch')
      } else {
        setValidationResultConfirmPassword('passwordMatch')
      }
    }
  }, [valueConfirmPassword, startedTypingConfirmPassword, valuePassword])

  useEffect(() => {
    if (startedTypingPassword) {
      if (startedTypingPassword && valuePassword.length === 0) {
        setValidationResultPassword('empty')
      } else if (startedTypingPassword && valuePassword.length < 16) {
        setValidationResultPassword('tooShort')
      } else if (startedTypingPassword && valuePassword.length > 60) {
        setValidationResultPassword('tooLong')
      } else {
        setValidationResultPassword('validPassword')
      }
    }
  }, [valuePassword, startedTypingPassword])

  const [showPassword, setShowPassword] = useState(false)
  // const [showOtp, setShowOtp] = useState(false)
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [newAccount, setNewAccount] = useState(false)

  const handleClickRegister = () => {
    setNewAccount(true)
    setShowPassword(false)
    setShowResetPassword(false)
    setVerifyEmail(false)
  }

  const handleSignIn = () => {
    setIsLoading(true)

    if (valueEmail.length === 0) {
      setValidationResultEmail('empty')
    } else if (showPassword && valuePassword.length === 0) {
      setValidationResultPassword('empty')
    } else if (newAccount) {
      if (valueName.length === 0) {
        setValidationResultName('empty')
      }
      if (valuePassword.length === 0) {
        setValidationResultPassword('empty')
      }
      if (valueConfirmPassword.length === 0) {
        setValidationResultConfirmPassword('empty')
      }
    } else {
      handleSubmitCheckEmail()
      // setShowPassword(false)
      setNewAccount(false)
    }
    // setShowPassword(true)
    // setShowOtp(true)
    // setNewAccount(true)

    // if (showResetPassword) {
    // }

    if (newAccount) {
      if (valueName.length === 0) {
        setValidationResultName('empty')
      }
      if (valuePassword.length === 0) {
        setValidationResultPassword('empty')
      }
      if (valueConfirmPassword.length === 0) {
        setValidationResultConfirmPassword('empty')
      }

      if (
        validationResultEmail === 'validEmail' &&
        validationResultPassword === 'validPassword' &&
        validationResultName === 'validName' &&
        validationResultConfirmPassword === 'passwordMatch'
      ) {
        // alert('create new account')
        handleSubmitRegister()
      }
    }

    if (
      showPassword &&
      validationResultEmail === 'validEmail' &&
      validationResultPassword === 'validPassword'
    ) {
      handleSubmitLoginEmail()
    }
    setIsLoading(false)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        width: '100%',
        height: '100vh',
        // backgroundColor: 'danger.fg',
        // backgroundColor: 'canvas.overlay',
        // justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'canvas.subtle',
      }}
    >
      <Toaster />
      <Box
        sx={{
          marginTop: ['100px', '100px', '100px'],
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <Logo variant='color' size={32} />
          <Text fontSize={4}>Welcome back!</Text>
          <Text fontSize={2} sx={{ color: 'fg.muted' }}>
            Sign in to continue
          </Text>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 3,
            mt: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            {/* {showOtp && ( */}
            {/*   <FormControl> */}
            {/*     <FormControl.Label htmlFor='otp'>2FA code</FormControl.Label> */}
            {/*     <TextInput */}
            {/*       type='text' */}
            {/*       placeholder='123456' */}
            {/*       sx={{ */}
            {/*         width: '300px', */}
            {/*       }} */}
            {/*     /> */}
            {/*   </FormControl> */}
            {/* )} */}

            {verifyEmail && (
              <FormControl>
                <FormControl.Label htmlFor='verifyEmail'>
                  Code
                </FormControl.Label>
                <FormControl.Caption>
                  Please check your email
                </FormControl.Caption>
                <TextInput
                  onKeyPress={handleKeyPress}
                  type='text'
                  placeholder='123456'
                  value={valueEmailVerifyCode}
                  onChange={handleInputChangeEmailVerifyCode}
                  sx={{
                    width: '300px',
                  }}
                />
                {validationResultEmailVerifyCode === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Code cannot be empty
                  </FormControl.Validation>
                )}
                {validationResultEmailVerifyCode === 'invalidCode' && (
                  <FormControl.Validation variant='error'>
                    Code is invalid
                  </FormControl.Validation>
                )}
                {validationResultEmailVerifyCode === 'validCode' && (
                  <FormControl.Validation variant='success'>
                    Code is valid
                  </FormControl.Validation>
                )}
              </FormControl>
            )}

            {!verifyEmail && (
              <FormControl>
                <FormControl.Label htmlFor='email'>Email *</FormControl.Label>
                <TextInput
                  value={valueEmail}
                  onKeyPress={handleKeyPress}
                  onChange={handleInputChangeEmail}
                  placeholder='Email'
                  sx={{
                    width: '300px',
                  }}
                />
                {validationResultEmail === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Email cannot be empty
                  </FormControl.Validation>
                )}
                {validationResultEmail === 'invalidEmail' && (
                  <FormControl.Validation variant='error'>
                    Invalid email
                  </FormControl.Validation>
                )}
                {validationResultEmail === 'validEmail' && (
                  <FormControl.Validation variant='success'>
                    Valid email
                  </FormControl.Validation>
                )}
              </FormControl>
            )}
            {newAccount && !isLoading && !showPassword && (
              <>
                <FormControl>
                  <FormControl.Label htmlFor='name'>Name *</FormControl.Label>
                  <TextInput
                    type='name'
                    placeholder='Name'
                    value={valueName}
                    onChange={handleInputChangeName}
                    onKeyPress={handleKeyPress}
                    sx={{
                      width: '300px',
                    }}
                  />
                  <FormControl.Caption>
                    Name must be between 3 to 60 characters long{' '}
                  </FormControl.Caption>

                  {validationResultName === 'empty' && (
                    <FormControl.Validation variant='error'>
                      Name cannot be empty
                    </FormControl.Validation>
                  )}
                  {validationResultName === 'tooShort' && (
                    <FormControl.Validation variant='error'>
                      Name is too short
                    </FormControl.Validation>
                  )}
                  {validationResultName === 'tooLong' && (
                    <FormControl.Validation variant='error'>
                      Name is too long
                    </FormControl.Validation>
                  )}
                  {validationResultName === 'invalidCharacters' && (
                    <FormControl.Validation variant='error'>
                      Name contains invalid characters
                    </FormControl.Validation>
                  )}

                  {validationResultName === 'validName' && (
                    <FormControl.Validation variant='success'>
                      Name is valid
                    </FormControl.Validation>
                  )}
                </FormControl>

                <FormControl>
                  <FormControl.Label htmlFor='password'>
                    Password *
                  </FormControl.Label>
                  <TextInput
                    onKeyPress={handleKeyPress}
                    type='password'
                    placeholder='Password'
                    value={valuePassword}
                    onChange={handleInputChangePassword}
                    sx={{
                      width: '300px',
                    }}
                  />
                  <FormControl.Caption>
                    Password must be at least 16 characters long
                  </FormControl.Caption>
                  {validationResultPassword === 'empty' && (
                    <FormControl.Validation variant='error'>
                      Password cannot be empty
                    </FormControl.Validation>
                  )}
                  {validationResultPassword === 'tooShort' && (
                    <FormControl.Validation variant='error'>
                      Password too short
                    </FormControl.Validation>
                  )}
                  {validationResultPassword === 'tooLong' && (
                    <FormControl.Validation variant='error'>
                      Password too long
                    </FormControl.Validation>
                  )}
                  {validationResultPassword === 'validPassword' && (
                    <FormControl.Validation variant='success'>
                      Valid password
                    </FormControl.Validation>
                  )}
                </FormControl>
                <FormControl>
                  <FormControl.Label htmlFor='confirm_password'>
                    Confirm password *
                  </FormControl.Label>
                  <TextInput
                    onKeyPress={handleKeyPress}
                    type='password'
                    placeholder='Password'
                    value={valueConfirmPassword}
                    onChange={handleInputChangeConfirmPassword}
                    sx={{
                      width: '300px',
                    }}
                  />
                  {validationResultConfirmPassword === 'empty' && (
                    <FormControl.Validation variant='error'>
                      Password cannot be empty
                    </FormControl.Validation>
                  )}
                  {validationResultConfirmPassword === 'tooShort' && (
                    <FormControl.Validation variant='error'>
                      Password too short
                    </FormControl.Validation>
                  )}
                  {validationResultConfirmPassword === 'tooLong' && (
                    <FormControl.Validation variant='error'>
                      Password too long
                    </FormControl.Validation>
                  )}
                  {validationResultConfirmPassword === 'passwordMismatch' && (
                    <FormControl.Validation variant='error'>
                      Password mismatch
                    </FormControl.Validation>
                  )}
                  {validationResultConfirmPassword === 'passwordMatch' && (
                    <FormControl.Validation variant='success'>
                      Passwords match
                    </FormControl.Validation>
                  )}
                </FormControl>

                <>
                  {/* <Box */}
                  {/*   sx={{ */}
                  {/*     width: '300px', */}
                  {/*   }} */}
                  {/* > */}
                  {/*   <Text>{JSON.stringify(token, null, 2)}</Text> */}
                  {/* </Box> */}
                  <Turnstile
                    ref={ref}
                    siteKey='0x4AAAAAAAWvCNiqci-h9eGh'
                    onSuccess={setTokenRegister}
                    onError={() => window.location.reload()}
                  />
                  {/* <button onClick={ref.current?.reset}>Reset widget</button> */}
                  {/* <button onClick={() => alert(ref.current?.getResponse())}> */}
                  {/*   Get response */}
                  {/* </button> */}
                  {/**/}
                  {/* <button onClick={() => ref.current?.reset()}> */}
                  {/*   Reset widget */}
                  {/* </button> */}
                  {/**/}
                  {/* <button onClick={() => ref.current?.remove()}> */}
                  {/*   Remove widget */}
                  {/* </button> */}
                  {/**/}
                  {/* <button onClick={() => ref.current?.render()}> */}
                  {/*   Render widget */}
                  {/* </button> */}
                </>
              </>
            )}

            {showPassword && !isLoading && !newAccount && !verifyEmail && (
              <>
                <FormControl>
                  <FormControl.Label htmlFor='password'>
                    Password
                  </FormControl.Label>
                  <TextInput
                    onKeyPress={handleKeyPress}
                    type='password'
                    placeholder='Password'
                    value={valuePassword}
                    onChange={handleInputChangePassword}
                    sx={{
                      width: '300px',
                    }}
                  />
                  {validationResultPassword === 'empty' && (
                    <FormControl.Validation variant='error'>
                      Password cannot be empty
                    </FormControl.Validation>
                  )}
                  {validationResultPassword === 'tooShort' && (
                    <FormControl.Validation variant='error'>
                      Password too short
                    </FormControl.Validation>
                  )}
                  {validationResultPassword === 'tooLong' && (
                    <FormControl.Validation variant='error'>
                      Password too long
                    </FormControl.Validation>
                  )}
                  {validationResultPassword === 'validPassword' && (
                    <FormControl.Validation variant='success'>
                      Valid password
                    </FormControl.Validation>
                  )}
                </FormControl>
              </>
            )}
            {isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '300px',
                  height: '50px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '47px',
                  }}
                >
                  <Spinner size='small' />
                </Box>
              </Box>
            )}

            {showResetPassword && (
              <>
                <Turnstile
                  ref={ref}
                  siteKey='0x4AAAAAAAWvCNiqci-h9eGh'
                  onSuccess={setToken}
                  onError={() => window.location.reload()}
                />
                <Button
                  variant='primary'
                  size='medium'
                  sx={{ width: '300px' }}
                  onClick={() => {
                    handleSubmitForgotPassword()
                  }}
                >
                  {isLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        // width: '47px',
                        // width: '300px',
                      }}
                    >
                      <Spinner size='small' />
                    </Box>
                  ) : (
                    <Text>Send password reset link</Text>
                  )}
                </Button>
              </>
            )}

            {!verifyEmail && !showResetPassword && newAccount && (
              <Button
                variant='primary'
                size='medium'
                sx={{ width: '300px' }}
                onClick={() => {
                  handleSignIn()
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      // width: '47px',
                      // width: '300px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Sign up</Text>
                )}
              </Button>
            )}

            {!verifyEmail && !showResetPassword && !newAccount && (
              <Button
                variant='primary'
                size='medium'
                sx={{ width: '300px' }}
                onClick={() => {
                  handleSignIn()
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      // width: '47px',
                      // width: '300px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Sign in</Text>
                )}
              </Button>
            )}
            {verifyEmail && (
              <Button
                variant='primary'
                size='medium'
                sx={{ width: '300px' }}
                onClick={() => {
                  handleSubmitVerifyCode()
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      // width: '47px',
                      // width: '300px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Confirm email</Text>
                )}
              </Button>
            )}

            {/* <pre>{JSON.stringify(checkEmailResponse, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(loginEmailResponse, null, 2)}</pre> */}
            {verifyEmail && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                  }}
                >
                  <Text
                    sx={{
                      color: 'fg.muted',
                    }}
                    fontSize='small'
                  >
                    Did not receive the code?
                  </Text>
                  <Text
                    sx={{
                      color: 'accent.fg',
                      cursor: 'pointer',
                    }}
                    fontSize='small'
                    onClick={() => handleSignIn()}
                  >
                    Retry
                  </Text>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                  }}
                >
                  <Text
                    sx={{
                      color: 'accent.fg',
                      cursor: 'pointer',
                    }}
                    fontSize='small'
                    onClick={() => setVerifyEmail(false)}
                  >
                    Go back
                  </Text>
                </Box>
              </>
            )}
            {!verifyEmail && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    // alignContent: 'center',
                    // justifyContent: 'center',
                    width: '300px',
                    gap: 1,
                  }}
                >
                  <Text
                    sx={{
                      color: 'fg.muted',
                    }}
                    fontSize='small'
                  >
                    Forgot your password?
                  </Text>
                  <Text
                    sx={{
                      color: 'accent.fg',
                      cursor: 'pointer',
                    }}
                    fontSize='small'
                    onClick={() => {
                      setShowResetPassword(true)
                      setNewAccount(false)
                      setShowPassword(false)
                    }}
                  >
                    Reset password
                  </Text>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'left', gap: 1 }}>
                  {!newAccount && (
                    <>
                      <Text
                        sx={{
                          color: 'fg.muted',
                        }}
                        fontSize='small'
                      >
                        New account?
                      </Text>
                      <Text
                        sx={{
                          color: 'accent.fg',
                          cursor: 'pointer',
                        }}
                        fontSize='small'
                        onClick={() => handleClickRegister()}
                      >
                        Register
                      </Text>
                    </>
                  )}
                  {newAccount && (
                    <>
                      <Text
                        sx={{
                          color: 'fg.muted',
                        }}
                        fontSize='small'
                      >
                        Already have an account?
                      </Text>
                      <Text
                        sx={{
                          color: 'accent.fg',
                          cursor: 'pointer',
                        }}
                        fontSize='small'
                        onClick={() => {
                          setNewAccount(false)
                          setShowResetPassword(false)
                          setVerifyEmail(false)
                        }}
                      >
                        Sign in
                      </Text>
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
          {!verifyEmail && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '300px',
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    // line
                    backgroundColor: 'border.default',
                    height: '1px',
                    width: '125px',
                  }}
                ></Box>
                <Box
                  sx={{
                    width: '50px',
                    // backgroundColor: 'danger.fg',
                  }}
                >
                  <Text
                    sx={{
                      color: 'fg.muted',
                      fontSize: 1,
                    }}
                  >
                    or with
                  </Text>
                </Box>
                <Box
                  sx={{
                    // line
                    backgroundColor: 'border.default',
                    height: '1px',
                    width: '125px',
                  }}
                ></Box>
              </Box>
              <Button
                as='a'
                href={`${API_ROUTE}/auth/google/authorize`}
                variant='default'
                size='medium'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexGrow: 1,
                  width: '300px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Text>Continue with</Text> <Icons.Google size={14} />
                </Box>
              </Button>
              <Button
                as='a'
                href={`${API_ROUTE}/auth/github/authorize`}
                variant='default'
                size='medium'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexGrow: 1,
                  width: '300px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Text>Continue with</Text>
                  <Icons.Github size={14} />
                </Box>
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  mb: '250px',
                  mt: 3,
                  fontSize: 'small',
                }}
              >
                <Text>By signing in, you agree to our </Text>
                <Link href='https://signgrid.io/terms' as='a'>
                  Terms of Service
                </Link>
                <Text> and </Text>
                <Link href='https://signgrid.io/privacy' as='a'>
                  Privacy policy
                </Link>

                {/* <Text */}
                {/*   sx={{ */}
                {/*     color: 'fg.muted', */}
                {/*   }} */}
                {/*   fontSize='small' */}
                {/* > */}
                {/*   By signing in, you agree to our{' '} */}
                {/*   <Text */}
                {/*     fontSize='small' */}
                {/*     sx={{ color: 'accent.fg', cursor: 'pointer' }} */}
                {/*   > */}
                {/*     Terms of Service */}
                {/*   </Text>{' '} */}
                {/*   and{' '} */}
                {/*   <Text */}
                {/*     fontSize='small' */}
                {/*     sx={{ color: 'accent.fg', cursor: 'pointer' }} */}
                {/*   > */}
                {/*     Privacy policy */}
                {/*   </Text> */}
                {/* </Text> */}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}
