import React from 'react'
import { BarChart } from '@tremor/react'
import '../output.css'

interface DeviceCountByDay {
  date: string
  device_count: number
  org_id: string
  event_id: number
}

interface BarChartExampleWithGroupsProps {
  data: DeviceCountByDay[]
  totalDevices: number
}

const dataFormatter = (number: number) =>
  Intl.NumberFormat('us').format(number).toString()

// Utility function to get the last 7 days in a readable format
const getLast7Days = () => {
  return Array.from(
    { length: 7 },
    (_, index) =>
      new Date(Date.now() - 86400000 * index).toISOString().split('T')[0]
  ).reverse()
}

export const BarChartExampleWithGroups: React.FC<
  BarChartExampleWithGroupsProps
> = ({ data, totalDevices }) => {
  const last7Days = getLast7Days()
  // console.log('last7Days', last7Days)
  // console.log('data', data)

  // Prepare chart data
  const chartData = last7Days.map(day => {
    const deviceCount =
      data.find(item => item.date.split('T')[0] === day)?.device_count || 0
    const offlineDevices = totalDevices - deviceCount

    return {
      name: day,
      'Online devices': deviceCount,
      'Offline devices': offlineDevices < 0 ? 0 : offlineDevices,
    }
  })

  return (
    <>
      <BarChart
        className='mt-6'
        data={chartData}
        index='name'
        categories={['Online devices', 'Offline devices']}
        colors={['#1b7f38', '#f85149']}
        valueFormatter={dataFormatter}
        yAxisWidth={48}
      />
    </>
  )
}
