import { useEffect, useState, useRef, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Spinner,
  ActionMenu,
  ActionList,
  Truncate,
  Dialog,
  Box,
  Text,
  Button,
} from '@primer/react'
import { getPlaylists, deletePlaylist } from '@/lib/calls'
import { Icons } from '@/components/icons'
import { useReloadContent } from '@/lib/devices-reload-context'
import { toast } from 'react-hot-toast'
import { CustomToast } from '@/components/custom-toast'

export const EditPlaylistMenu = ({ name, disabled }) => {
  const { setReloadContent } = useReloadContent()
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  // const [playlistId, setPlaylistId] = useState(query.get('playlist_id'));
  const playlistId = query.get('playlist_id')
  // const [isOpenNewGroupDialog, setIsOpenNewGroupDialog] = useState(false);
  const [isOpenDeletePlaylistDialog, setIsOpenDeletePlaylistDialog] =
    useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [errorFetching, setErrorFetching] = useState(false)

  const onDialogCloseDeletePlaylistDialog = useCallback(() => {
    if (!isLoading) {
      setIsOpenDeletePlaylistDialog(false)
    }
  }, [isLoading])

  const buttonRef = useRef()
  // const onDialogCloseNewGroupDialog = useCallback(() => {
  //     if (!isLoading) {
  //         setIsOpenNewGroupDialog(false);
  //     }
  //   }, [isLoading]);

  const handleOpenDeletePlaylistDialog = () => {
    setIsOpenDeletePlaylistDialog(true)
  }

  const handleSubmitDeletePlaylist = async () => {
    // alert(playlistId)
    setIsLoading(true)
    try {
      await deletePlaylist(playlistId)
      toast.custom(t => (
        <CustomToast message='Playlist deleted' type='success' />
      ))
      // Handle success - e.g., show a success message
      onDialogCloseDeletePlaylistDialog() // Close the dialog if successful
      setReloadComponent(!reloadComponent) // Trigger a reload of the component (e.g., to refresh the list of items
    } catch (error) {
      // Handle errors - e.g., show error message

      toast.custom(t => (
        <CustomToast message='Error deleting playlist' type='error' />
      ))
      // console.log(error);
      console.error('Error deleting playlist:', error)
    } finally {
      setIsLoading(false)
      handleAction({ key: 'clear' })
    }
  }

  const navigate = useNavigate()
  // Optional: automatically close the menu if the user selects an item
  const handleAction = itemProps => {
    setOpen(false) // Close the menu after selection
    if (itemProps.key === 'clear') {
      setPlaylistName(null)
      setReloadComponent(!reloadComponent)
      setReloadContent(prev => !prev) // Toggle to trigger effect on parent component
      navigate(`/dashboard/playlists`) // Remove the groupId parameter
      // window.location.reload();
    } else {
      setPlaylistName(itemProps.text)
      navigate(`?playlist_id=${itemProps.key}`)
    }
  }

  const [items, setItems] = useState([])
  const triggerRef = useRef(null)

  const [open, setOpen] = useState(false)
  const [playlistName, setPlaylistName] = useState(null)
  const [reloadComponent, setReloadComponent] = useState(false)

  const handleOpenChange = state => {
    setOpen(!open)
    if (state && items.length === 0) {
      // Fetch items only if not already loaded
      fetchPlaylists()
    }
  }

  useEffect(() => {
    setPlaylistName(name)
  }, [name])

  useEffect(() => {
    fetchPlaylists()
  }, [reloadComponent])

  const fetchPlaylists = async () => {
    try {
      setIsLoading(true)
      const response = await getPlaylists() // Your API call to fetch items
      if (!response.error) {
        const playlists = response.data.map(playlist => ({
          key: playlist.playlist.id,
          text: playlist.playlist.name,
          // Additional data can be included here if needed for handling actions
        }))
        setIsLoading(false)
        setErrorFetching(false)
        setItems(playlists)
      } else {
        setIsLoading(false)
        console.error('API returned an error:', response.msg)
        // Handle API error state here, e.g., set an error message state and display it
      }
    } catch (error) {
      setIsLoading(false)
      setErrorFetching(true)
      console.error('Failed to fetch items:', error)
      // Handle fetching error state here
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <ActionMenu open={open} onOpenChange={handleOpenChange}>
        <ActionMenu.Button disabled={disabled}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
          <Text sx={{ color: 'fg.muted' }}>Edit: </Text>
          <Truncate>{playlistName ? playlistName : ''}</Truncate>
</Box>
        </ActionMenu.Button>

        <ActionMenu.Overlay
          sx={{
            minWidth: '400px',
            // backgroundColor: 'red',
          }}
          ignoreClickRefs={[
            // Because the component is controlled from outside, but the anchor is still internal,
            // clicking the external button should not be counted as "clicking outside"
            triggerRef,
          ]}
        >
          {items.length > 0 && (
            <ActionList>
              <ActionList.Group
                selectionVariant='single'
                title='Select playlist'
                sx={{
                  maxHeight: '250px',
                  overflow: 'auto',
                }}
              >
                {items.map(item => (
                  <ActionList.Item
                    key={item.key}
                    selected={playlistName === item.text}
                    onSelect={() => handleAction(item)}
                  >
                    <Truncate
                      sx={{
                        maxWidth: '250px',
                      }}
                    >
                      {item.text}
                    </Truncate>
                  </ActionList.Item>
                ))}
              </ActionList.Group>
              <ActionList.Divider />
              {/* <ActionList.Item onClick={() => (handleOpenNewGroupDialog())}><Icons.Add /> Create new group</ActionList.Item> */}

              <ActionList.Item
                disabled={!playlistName}
                onSelect={
                  playlistName ? () => handleAction({ key: 'clear' }) : null
                }
              >
                <Icons.Close /> Finish edit
              </ActionList.Item>

              <ActionList.Item
                disabled={!playlistName}
                variant='danger'
                onClick={
                  playlistName ? () => handleOpenDeletePlaylistDialog() : null
                }
              >
                <Icons.Trash /> Delete playlist
              </ActionList.Item>
            </ActionList>
          )}
          {isLoading && (
            <ActionList>
              <ActionList.Item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // width: '100%',
                  // backgroundColor: 'blue',
                }}
              >
                <Spinner size='small' />
              </ActionList.Item>
            </ActionList>
          )}
          {errorFetching && (
            <ActionList>
              <ActionList.Item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // width: '100%',
                  // backgroundColor: 'blue',
                }}
              >
                <Text color='fg.muted'>
                  <Icons.Alert /> Error fetching playlists
                </Text>
              </ActionList.Item>
            </ActionList>
          )}
          {items.length === 0 && (
            <ActionList>
              <ActionList.Group selectionVariant='single' title='Group by'>
                <ActionList.Item disabled>No playlists found</ActionList.Item>
              </ActionList.Group>
              <ActionList.Divider />
              {/* <ActionList.Item onClick={() => (handleOpenNewGroupDialog())}><Icons.Add /> Create new group</ActionList.Item> */}

              <ActionList.Item
                disabled={!playlistName}
                onSelect={
                  playlistName ? () => handleAction({ key: 'clear' }) : null
                }
              >
                <Icons.Close /> Finish edit
              </ActionList.Item>

              <ActionList.Item
                disabled={!playlistName}
                variant='danger'
                onClick={
                  playlistName ? () => handleOpenDeletePlaylistDialog() : null
                }
              >
                <Icons.Trash /> Delete playlist
              </ActionList.Item>
            </ActionList>
          )}
        </ActionMenu.Overlay>
      </ActionMenu>
      {isOpenDeletePlaylistDialog && (
        <Dialog
          isOpen={isOpenDeletePlaylistDialog}
          onDismiss={onDialogCloseDeletePlaylistDialog}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Delete playlist</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' color='fg.muted'>
              This action can't be undone. Are you sure you want to delete this
              playlist?
            </Text>
            <Box mt={3}></Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogCloseDeletePlaylistDialog}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='danger'
                disabled={isLoading}
                onClick={() => {
                  handleSubmitDeletePlaylist()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '120px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Delete playlist</Text>
                )}
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  )
}
