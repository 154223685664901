/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import { debounce } from 'lodash';
import { SelectPanel as SelectPanel2 } from '@primer/react/drafts';
import { ActionList, Text, Box, Tooltip, Spinner, Button } from '@primer/react';
import { getDevices, patchDeviceGroup } from '@/lib/calls';
import { Icons } from '@/components/icons';
import { toast } from 'react-hot-toast';
import { CustomToast } from './custom-toast';
import { useReloadTable } from '@/lib/devices-reload-context';

export const EditGroupButton = ({ items, groupName, groupId }) => {
  const { setReloadTable } = useReloadTable();
  const [devices, setDevices] = useState([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState(items.map(item => item.id));
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [displayLimit, setDisplayLimit] = useState(100);
  const loadMoreIncrement = 100;

  const handleOpen = async () => {
    setIsOpen(!isOpen);
    setIsLoading(true);
    try {
      const initialDevices = await getDevices({ limit: displayLimit });
      setDevices(initialDevices.data);
      setIsLoading(false);
    } catch (error) {
      setFetchError(error);
      setIsLoading(false);
      console.error('Error fetching devices', error);
    }
  };

  const onDeviceSelect = useCallback(
    deviceId => {
      setSelectedDeviceIds(prevSelectedIds =>
        prevSelectedIds.includes(deviceId)
          ? prevSelectedIds.filter(id => id !== deviceId)
          : [...prevSelectedIds, deviceId]
      );
    },
    [setSelectedDeviceIds]
  );

  const onClearSelection = useCallback(() => {
    setSelectedDeviceIds([]);
  }, [setSelectedDeviceIds]);

  const handleSubmitPatchDeviceGroup = async () => {
    try {
      setIsLoading(true);
      const preparePayload = selectedDeviceIds.map(deviceId => ({ id: deviceId }));
      await patchDeviceGroup(groupName, groupId, preparePayload);
      setIsLoading(false);
      setReloadTable(prev => !prev);
      toast.custom(t => <CustomToast message='Group updated' type='success' />);
    } catch (error) {
      setIsLoading(false);
      toast.custom(t => <CustomToast message={error?.response?.data?.detailed_msg || error?.response?.data?.message || 'Error updating device group'} type='error' />);
      console.error('Error updating device group:', error);
    }
  };

  const onSubmit = () => {
    handleSubmitPatchDeviceGroup();
    setIsOpen(!isOpen);
  };

  const [filteredDevices, setFilteredDevices] = useState(devices);
  const [query, setQuery] = useState('');

  const debouncedSearch = useMemo(() => debounce(queryString => {
    if (queryString === '') {
      setFilteredDevices(devices);
    } else {
      const queryTerms = queryString.split(/\s+/);
      const filteredResults = queryTerms
        .map(term =>
          devices
            .map(device => {
              if (device.name.toLowerCase().startsWith(term)) return { priority: 1, device };
              else if (device.name.toLowerCase().includes(term)) return { priority: 2, device };
              else return { priority: -1, device };
            })
            .filter(result => result.priority > 0)
            .map(result => result.device)
        )
        .flat()
        .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index);

      setFilteredDevices(filteredResults);
    }
  }, 300), [devices]);

  const onSearchInputChange = event => {
    const queryString = event.currentTarget.value.toLowerCase().trim();
    setQuery(queryString);
    debouncedSearch(queryString);
  };

  const sortingFn = (itemA, itemB) => {
    const initialSelectedIds = items.map(item => item.id);
    if (initialSelectedIds.includes(itemA.id) && initialSelectedIds.includes(itemB.id)) return 1;
    else if (initialSelectedIds.includes(itemA.id)) return -1;
    else if (initialSelectedIds.includes(itemB.id)) return 1;
    else return 1;
  };

  const loadMoreItems = () => {
    setDisplayLimit(prevLimit => prevLimit + loadMoreIncrement);
  };

  const handleScroll = ({ scrollOffset, scrollDirection }) => {
    if (scrollDirection === 'forward' && scrollOffset + 400 >= displayLimit * 50) {
      loadMoreItems();
    }
  };

  const memoizedDevices = useMemo(() => devices, [devices]);
  const memoizedFilteredDevices = useMemo(() => filteredDevices, [filteredDevices]);
  const memoizedItemsToShow = useMemo(
    () => (query ? memoizedFilteredDevices : memoizedDevices.sort(sortingFn)).slice(0, displayLimit),
    [query, memoizedFilteredDevices, memoizedDevices, displayLimit]
  );

  const onSelectAll = useCallback(() => {
    const currentlyDisplayedDeviceIds = memoizedItemsToShow.map(device => device.id);
    const newSelectedDeviceIds = Array.from(new Set([...selectedDeviceIds, ...currentlyDisplayedDeviceIds]));
    setSelectedDeviceIds(newSelectedDeviceIds);
  }, [memoizedItemsToShow, selectedDeviceIds]);

  const title = <Text sx={{ color: 'fg.default' }}>Select devices</Text>;
  const notFound = <Text sx={{ color: 'fg.default' }}>No devices found</Text>;

  return (
    <>
      <Button
        variant='default'
        trailingAction={Icons.TriangleDown}
        onClick={handleOpen}
        sx={{ '[data-component=buttonContent]': { justifyContent: 'start' } }}
      >
        Edit group
      </Button>

      <SelectPanel2
        title={title}
        onSubmit={onSubmit}
        open={isOpen}
        variant='modal'
        onCancel={() => setIsOpen(!isOpen)}
        onClearSelection={onClearSelection}
      >
        <SelectPanel2.Header>
          <SelectPanel2.SearchInput onChange={onSearchInputChange} />
          <SelectPanel2.Button sx={{ mt: 2 }} onClick={onSelectAll}>
            Select all {selectedDeviceIds.length}
          </SelectPanel2.Button>
        </SelectPanel2.Header>

        {memoizedItemsToShow.length === 0 && !fetchError && (
          <SelectPanel2.Message variant='empty' title={notFound}>
            Try a different search term
          </SelectPanel2.Message>
        )}

        {memoizedItemsToShow.length > 0 && !isLoading && !fetchError && (
          <>
            <ActionList selectionVariant="multiple">
              <List
                height={400}
                itemCount={memoizedItemsToShow.length}
                itemSize={50}
                width="100%"
                onScroll={handleScroll}
              >
                {({ index, style }) => (
                  <div style={style}>
                    <ActionList.Item
                      key={memoizedItemsToShow[index].id}
                      onSelect={() => onDeviceSelect(memoizedItemsToShow[index].id)}
                      selected={selectedDeviceIds.includes(memoizedItemsToShow[index].id)}
                    >
                      <ActionList.LeadingVisual>
                        <Box
                          sx={{ width: 14, height: 14, borderRadius: '100%' }}
                          style={{ backgroundColor: `#${memoizedItemsToShow[index].color}` }}
                        />
                      </ActionList.LeadingVisual>
<Tooltip aria-label={memoizedItemsToShow[index].name}>
                      {memoizedItemsToShow[index].name.length > 50
                        ? `${memoizedItemsToShow[index].name.substring(0, 50)}...`
                          : memoizedItemsToShow[index].name}
                      </Tooltip>
                    </ActionList.Item>
                  </div>
                )}
              </List>
            </ActionList>
            {/* {(query ? memoizedFilteredDevices : memoizedDevices).length > displayLimit && (
              <Button onClick={loadMoreItems} size='small' sx={{ mt: 2, width: '100%' }}>
                Load more
              </Button>
            )} */}
          </>
        )}

        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              color: 'fg.default',
              justifyContent: 'center',
              alignItems: 'center',
              height: 200,
            }}
          >
            <Spinner />
          </Box>
        )}

        {fetchError && (
          <SelectPanel2.Message variant='error' title='Error fetching devices'>
            Unable to fetch devices
          </SelectPanel2.Message>
        )}

        <SelectPanel2.Footer />
      </SelectPanel2>
    </>
  );
};
