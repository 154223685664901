/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom'
import {
  Spinner,
  ActionMenu,
  ActionList,
  FormControl,
  Label,
  Dialog,
  Box,
  Text,
  Button,
  TextInput,
  Tooltip,
  NavList,
} from '@primer/react';
import { NavLink } from 'react-router-dom';
import {
  getDeviceGroups,
  createDeviceGroup,
  deleteGroup,
  renameDeviceGroup,
  getPlaylists,
  getSchedules,
} from '@/lib/calls';
import { Icons } from '@/components/icons';
import { toast } from 'react-hot-toast';
import { CustomToast } from './custom-toast';
import { debounce } from 'lodash';

export const GroupByMenu = ({ name, groupId, disabled }) => {
  const [newGroupName, setNewGroupName] = useState('');
  const [isOpenNewGroupDialog, setIsOpenNewGroupDialog] = useState(false);
  const [isOpenDeleteGroupDialog, setIsOpenDeleteGroupDialog] = useState(false);
  const [isOpenRenameGroupDialog, setIsOpenRenameGroupDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorFetching, setErrorFetching] = useState(false);
  const [value, setValue] = useState('');
  const [validationResult, setValidationResult] = useState('');
  const [results, setResults] = useState([]);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [groupName, setGroupName] = useState(null);
  const [reloadComponent, setReloadComponent] = useState(false);
  const [deviceGroups, setDeviceGroups] = useState([]);

  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const triggerRef = useRef(null);

  const onDialogCloseDeleteGroupDialog = useCallback(() => {
    if (!isLoading) {
      setIsOpenDeleteGroupDialog(false);
    }
  }, [isLoading]);

  const onDialogCloseNewGroupDialog = useCallback(() => {
    if (!isLoading) {
      setIsOpenNewGroupDialog(false);
    }
  }, [isLoading]);

  const onDialogCloseRenameGroupDialog = useCallback(() => {
    if (!isLoading) {
      setIsOpenRenameGroupDialog(false);
    }
  }, [isLoading]);

  const handleInputChange = (e) => {
    setValue(e.currentTarget.value);
  };

  const filterSlowly = useCallback(async (queryString) => {
    await new Promise(resolve => setTimeout(resolve, 10)); // Simulate network delay

    const queryTerms = queryString.toLowerCase().trim().split(/\s+/); // Split the query string into terms

    // Return items that match any of the query terms
    return items.filter(item =>
      queryTerms.some(term => item.text.toLowerCase().includes(term))
    );
  }, [items]);

  const filter = useMemo(() => debounce(async (event) => {
    setIsLoadingFilter(true); // Enable loading state
    const filteredResults = await filterSlowly(event.target.value);
    setResults(filteredResults.slice(0, 6)); // Adjust the number of results as needed
    setIsLoadingFilter(false); // Disable loading state
  }, 300), [filterSlowly]);

  const findScheduleAndPlaylist = useCallback((deviceGroupId) => {
    const schedule = schedules.find(schedule => schedule.group_id === deviceGroupId);
    if (!schedule) {
      return null; // No schedule found for the given deviceGroupId
    }

    const playlistEntry = playlists.find(playlist => playlist.playlist.id === schedule.playlist_id);
    if (!playlistEntry) {
      return { schedule }; // Schedule found but no playlist found for the given playlist_id
    }

    return {
      schedule,
      playlist: playlistEntry.playlist,
    };
  }, [schedules, playlists]);

  const handleOpenNewGroupDialog = () => {
    setValue('');
    setIsOpenNewGroupDialog(true);
  };

  const handleOpenRenameGroupDialog = () => {
    setValue('');
    setIsOpenRenameGroupDialog(true);
  };

  const handleOpenDeleteGroupDialog = () => {
    setIsOpenDeleteGroupDialog(true);
  };

  const handleSubmitRenameGroup = async () => {
    if (validationResult === 'validName') {
      try {
        setIsLoading(true);

        await renameDeviceGroup(value, groupId);
        toast.custom(t => (
          <CustomToast message='Group updated' type='success' />
        ));
        setNewGroupName(value);
        onDialogCloseRenameGroupDialog();
        setReloadComponent(!reloadComponent);
        setIsLoading(false);
      } catch (error) {
        toast.custom(t => (
          <CustomToast message='Error updating group' type='error' />
        ));
        setIsLoading(false);
        console.error('Error updating group:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmitNewGroup = async () => {
    if (validationResult === 'validName') {
      try {
        setIsLoading(true);

        await createDeviceGroup(value);
        toast.custom(t => (
          <CustomToast message='Group created' type='success' />
        ));
        onDialogCloseNewGroupDialog();
        setReloadComponent(!reloadComponent);
        setIsLoading(false);
      } catch (error) {
        toast.custom(t => (
          <CustomToast message='Error creating group' type='error' />
        ));
        setIsLoading(false);
        console.error('Error creating group:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmitDeleteGroup = async () => {
    setIsLoading(true);
    try {
      await deleteGroup(groupId);
      toast.custom(t => <CustomToast message='Group deleted' type='success' />);
      onDialogCloseDeleteGroupDialog();
      setReloadComponent(!reloadComponent);
    } catch (error) {
      toast.custom(t => (
        <CustomToast message='Error deleting group' type='error' />
      ));
      console.error('Error deleting group:', error);
    } finally {
      setIsLoading(false);
      handleAction({ key: 'clear' });
    }
  };

  useEffect(() => {
    if (value.length === 0) {
      setValidationResult('empty');
    } else if (value.length > 255) {
      setValidationResult('tooLong');
    } else if (value.length < 3) {
      setValidationResult('tooShort');
    } else {
      setValidationResult('validName');
    }
  }, [value]);

  const handleAction = (itemProps) => {
    setOpen(false);
    if (itemProps.key === 'clear') {
      setGroupName(null);
      navigate(`/dashboard/devices`);
      setTimeout(() => {
        window.location.reload();
      }, 200);
    } else {
      setGroupName(itemProps.text);
      navigate(`?device_group_id=${itemProps.key}`);
    }
  };

  const handleOpenChange = (state) => {
    setOpen(state);
    if (state && items.length === 0) {
      fetchDeviceGroups();
    }
    if (playlists.length && schedules.length && deviceGroups.length) {
      const updatedItems = deviceGroups
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .map(group => {
          const scheduleAndPlaylist = findScheduleAndPlaylist(group.id);
          const devicesCount = group.devices ? group.devices.length : 0;
          return {
            key: group.id,
            text: group.name,
            schedule: scheduleAndPlaylist ? scheduleAndPlaylist.schedule : null,
            playlist: scheduleAndPlaylist ? scheduleAndPlaylist.playlist : null,
            devicesCount: devicesCount,
          };
        });
      setItems(updatedItems);
      setResults(updatedItems);
    }
  };

  useEffect(() => {
    setGroupName(name);
  }, [name]);

  useEffect(() => {
    fetchDeviceGroups();
    fetchPlaylists();
    fetchSchedules();
  }, [reloadComponent]);

  const fetchDeviceGroups = async () => {
    try {
      setIsLoading(true);
      const response = await getDeviceGroups();
      if (!response.error) {
        const groups = response.data.map(group => {
          const scheduleAndPlaylist = findScheduleAndPlaylist(group.id);
          return {
            key: group.id,
            text: group.name,
            schedule: scheduleAndPlaylist ? scheduleAndPlaylist.schedule : undefined,
            playlist: scheduleAndPlaylist ? scheduleAndPlaylist.playlist : undefined,
            devicesCount: group.devices ? group.devices.length : 0,
          };
        });
        setDeviceGroups(response.data);
        setItems(groups);
        setResults(groups);
        setIsLoading(false);
        setErrorFetching(false);
      } else {
        setIsLoading(false);
        console.error('API returned an error:', response.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorFetching(true);
      console.error('Failed to fetch items:', error);
    }
  };

  const fetchPlaylists = async () => {
    try {
      setIsLoading(true);
      const response = await getPlaylists();
      if (!response.error) {
        setPlaylists(response.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.error('API returned an error:', response.msg);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to fetch playlists:', error);
    }
  };

  const fetchSchedules = async () => {
    try {
      setIsLoading(true);
      const response = await getSchedules();
      if (!response.error) {
        setSchedules(response.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.error('API returned an error:', response.msg);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to fetch schedules:', error);
    }
  };

  return (
    <>
      <ActionMenu open={open} onOpenChange={handleOpenChange}>
        <ActionMenu.Button disabled={disabled}>
          <Tooltip
            aria-label={groupName ? `Group by ${groupName}` : `Group by`}
          >
            {newGroupName ? (
              <Box>
                <Text sx={{ color: 'fg.muted' }}>Group by: </Text>
                <Text>{newGroupName.length > 28 ? `${newGroupName.slice(0, 28)}...` : newGroupName}</Text>
              </Box>
            ) : groupName ? (
              <Box>
                <Text sx={{ color: 'fg.muted' }}>Group by: </Text>
                <Text>{groupName.length > 28 ? `${groupName.slice(0, 28)}...` : groupName}</Text>
              </Box>
            ) : (
              <Text sx={{ color: 'fg.muted' }}>Group by:</Text>
            )}
          </Tooltip>
        </ActionMenu.Button>

        <ActionMenu.Overlay
          ignoreClickRefs={[triggerRef]}
          sx={{ width: ['400px', '550px'] }}
        >
          {items.length > 0 && (
            <ActionList>
              <ActionList.Group
                sx={{ maxHeight: '250px', overflow: 'auto' }}
                selectionVariant='single'
                title='Group by'
              >
                <FormControl sx={{ m: 2, mb: 0, width: 'calc(100% - 16px)' }}>
                  <FormControl.Label visuallyHidden>
                    Filter groups
                  </FormControl.Label>
                  <TextInput
                    placeholder='Filter groups'
                    onChange={filter}
                    block
                  />
                </FormControl>
                <div role='status'>
                  {results.length === 0 && (
                    <Text
                      sx={{ display: 'flex', fontSize: 1, padding: 4 }}
                    >
                      No groups match that query
                    </Text>
                  )}
                </div>

                <ActionList
                  selectionVariant='single'
                  role='listbox'
                  aria-label='Groups'
                  sx={{ height: 208, overflow: 'auto' }}
                >
                  {isLoadingFilter ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pt: 2,
                      }}
                    >
                      <Spinner />
                    </Box>
                  ) : (
                    <>
                      {results.map(result => (
                        <ActionList.Item
                          key={result.key}
                          role='option'
                          selected={groupName === result.text}
                          onSelect={() => handleAction(result)}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: ['column', 'row'],
                              flexWrap: 'wrap',
                              gap: 2,
                              justifyContent: 'space-between',
                            }}
                          >
                            <Tooltip aria-label={result.text}>
                              {result.text.length > 20
                                ? `${result.text.slice(0, 20)}...`
                                : result.text}
                            </Tooltip>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                              }}
                            >
                              {result.devicesCount >= 0 && (
                                <Label variant='accent'>
                                  <Icons.Devices sx={{ mr: 1 }} />{' '}
                                  {result.devicesCount}
                                </Label>
                              )}
                              {result.schedule && (
                                <Tooltip
                                  aria-label={
                                    result.schedule.name.length > 50
                                      ? `${result.schedule.name.slice(0, 60)}...`
                                      : result.schedule.name
                                  }
                                >
                                  <Label variant='sponsors'>
                                    <Icons.Calendar sx={{ mr: 1 }} />{' '}
                                    {result.schedule.name.length > 10
                                      ? `${result.schedule.name.slice(0, 10)}...`
                                      : result.schedule.name}
                                  </Label>
                                </Tooltip>
                              )}
                              {result.playlist && (
                                <Tooltip
                                  aria-label={
                                    result.playlist.name.length > 50
                                      ? `${result.playlist.name.slice(0, 60)}...`
                                      : result.playlist.name
                                  }
                                >
                                  <Label variant='done'>
                                    <Icons.Play sx={{ mr: 1 }} />
                                    {result.playlist.name.length > 10
                                      ? `${result.playlist.name.slice(0, 10)}...`
                                      : result.playlist.name}
                                  </Label>
                                </Tooltip>
                              )}
                            </Box>
                          </Box>
                        </ActionList.Item>
                      ))}
                    </>
                  )}
                </ActionList>
              </ActionList.Group>
              <ActionList.Divider />
              <ActionList.Item onClick={handleOpenNewGroupDialog}>
                <Icons.Add /> Create new group
              </ActionList.Item>
              <ActionList.Item
                disabled={!groupName}
                onSelect={groupName ? () => handleAction({ key: 'clear' }) : null}
              >
                <Icons.Close /> Clear group filter
              </ActionList.Item>
              <NavList.Item as={NavLink} to='/dashboard/device-groups'>
                <Icons.Devices sx={{ mr: 2 }} />
                View all groups
              </NavList.Item>
              <ActionList.Item
                disabled={!groupName}
                onClick={groupName ? handleOpenRenameGroupDialog : null}
              >
                <Icons.Pencil sx={{ mr: 2 }} />
                Rename group
              </ActionList.Item>
              <ActionList.Item
                disabled={!groupName}
                variant='danger'
                onClick={groupName ? handleOpenDeleteGroupDialog : null}
              >
                <Icons.Trash /> Delete group
              </ActionList.Item>
            </ActionList>
          )}
          {isLoading && (
            <ActionList>
              <ActionList.Item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spinner size='small' />
              </ActionList.Item>
            </ActionList>
          )}
          {errorFetching && (
            <ActionList>
              <ActionList.Item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text color='fg.muted'>
                  <Icons.Alert /> Error fetching groups
                </Text>
              </ActionList.Item>
            </ActionList>
          )}
          {items.length === 0 && !isLoading && !errorFetching && (
            <ActionList>
              <ActionList.Group selectionVariant='single' title='Group by'>
                <ActionList.Item disabled>No groups found</ActionList.Item>
              </ActionList.Group>
              <ActionList.Divider />
              <ActionList.Item onClick={handleOpenNewGroupDialog}>
                <Icons.Add /> Create new group
              </ActionList.Item>
              <ActionList.Item
                disabled={!groupName}
                onSelect={groupName ? () => handleAction({ key: 'clear' }) : null}
              >
                <Icons.Close /> Clear group filter
              </ActionList.Item>
              <ActionList.Item
                disabled={!groupName}
                variant='danger'
                onClick={groupName ? handleOpenDeleteGroupDialog : null}
              >
                <Icons.Trash /> Delete group
              </ActionList.Item>
            </ActionList>
          )}
        </ActionMenu.Overlay>
      </ActionMenu>

      {isOpenRenameGroupDialog && (
        <Dialog
          isOpen={isOpenRenameGroupDialog}
          onDismiss={onDialogCloseRenameGroupDialog}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Rename device group</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' color={'fg.muted'}>
              Groups are used to organize devices and set schedules.
            </Text>
            <Box mt={3}>
              <FormControl required>
                <FormControl.Label>Name</FormControl.Label>
                <TextInput
                  block
                  placeholder={groupName}
                  value={value}
                  onChange={handleInputChange}
                />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'tooLong' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be longer than 255 characters
                  </FormControl.Validation>
                )}
                {validationResult === 'validName' && (
                  <FormControl.Validation variant='success'>
                    Valid name
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a name (1 to 255 characters).
                </FormControl.Caption>
              </FormControl>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogCloseRenameGroupDialog}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={handleSubmitRenameGroup}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px',
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Rename</Text>
                )}
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}

      {isOpenNewGroupDialog && (
        <Dialog
          isOpen={isOpenNewGroupDialog}
          onDismiss={onDialogCloseNewGroupDialog}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Create new device group</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' color={'fg.muted'} fontSize={1}>
              It is required to create groups to be able to set schedules.
            </Text>
            <Box mt={3}>
              <FormControl required>
                <FormControl.Label>Name</FormControl.Label>
                <TextInput block value={value} onChange={handleInputChange} />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'tooLong' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be longer than 255 characters
                  </FormControl.Validation>
                )}
                {validationResult === 'tooShort' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be less than 3 characters
                  </FormControl.Validation>
                )}
                {validationResult === 'validName' && (
                  <FormControl.Validation variant='success'>
                    Valid name
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a name (3 to 255 characters).
                </FormControl.Caption>
              </FormControl>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogCloseNewGroupDialog}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={handleSubmitNewGroup}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px',
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
      {isOpenDeleteGroupDialog && (
        <Dialog
          isOpen={isOpenDeleteGroupDialog}
          onDismiss={onDialogCloseDeleteGroupDialog}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Delete group</Dialog.Header>
          <Box p={3}>
            <Icons.Alert
              sx={{
                mr: 2,
                color: 'danger.fg',
              }}
            />
            <Text id='dialog-label' color='danger.fg' fontSize={1}>
              This action can't be undone. Are you sure you want to delete this
              group? This action will only delete the group and not the devices.
            </Text>
            <Box mt={3}></Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogCloseDeleteGroupDialog}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='danger'
                disabled={isLoading}
                onClick={handleSubmitDeleteGroup}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '120px',
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Delete group</Text>
                )}
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};
