import React, { useEffect, useState } from 'react'
import { useAuth } from '@/contexts/AuthContext'
import { SplitPageLayout, Link } from '@primer/react'
import { Outlet } from 'react-router-dom'
import { Sidebar } from '@/components/sidebar'
import { MainHeader } from '@/components/main-header'
import { Toaster } from 'react-hot-toast'
import { Footer } from '@/components/footer'
import FlashMessage from './ui/flash-message'
import { getFlashMessage } from '@/lib/calls'

export function DashboardLayout() {
  const { user } = useAuth()
  const [flashMessage, setFlashMessage] = useState(null)

  useEffect(() => {
    const fetchFlash = async () => {
      try {
        const response = await getFlashMessage()
        setFlashMessage(response)
      } catch (error) {
        console.error('Error fetching flash message', error)
      }
    }
    fetchFlash()
  }, [])

  // useDocumentTitle(router.routes);
  return (
    <SplitPageLayout
      sx={{
        backgroundColor: 'canvas.subtle',
        width: '100%',
      }}
    >
      <SplitPageLayout.Header
        padding='none'
        sx={{
          /* backgroundColor: 'red', */
          '& > *': {
            // Targets immediate children
            padding: '0 !important', // Override padding
          },
        }}
      >
        <MainHeader />
        {/* <pre>{JSON.stringify(flashMessage, null, 2)}</pre> */}
        {user &&
          new Date(user.data.created_at).getTime() >
            Date.now() - 30 * 24 * 60 * 60 * 1000 && (
            <FlashMessage
              message={
                <>
                  Our documentation is{' '}
                  <Link href='https://docs.signgrid.io'>here</Link> to help you
                  get started. If you have any questions, please{' '}
                  <Link href='mailto:contact@signgrid.io'>reach out to us</Link>
                  .
                </>
              }
              variant='default'
            />
          )}
        {flashMessage && flashMessage.display ? (
          <FlashMessage
            message={flashMessage.msg}
            variant={flashMessage.level}
          />
        ) : null}
      </SplitPageLayout.Header>
      <SplitPageLayout.Pane position='start' width='small'>
        <Sidebar />
      </SplitPageLayout.Pane>
      <SplitPageLayout.Content
        sx={{
          overflow: 'auto',
          height: '80vh',
          mr: 0,
          mb: 2,
        }}
      >
        <Outlet />
      </SplitPageLayout.Content>
      <SplitPageLayout.Footer>
        <Footer />
      </SplitPageLayout.Footer>
      <Toaster />
    </SplitPageLayout>
  )
}
