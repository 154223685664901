import { useState, useEffect } from 'react'
import {
  Header,
  ActionMenu,
  ActionList,
  Label,
  Avatar,
  Box,
  Text,
  Button,
  NavList,
} from '@primer/react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAuth } from '@/contexts/AuthContext'
import { OrgSwitcher } from '@/components/org-switcher'
import { Icons } from '@/components/icons'
import { sidebarSettingsNavItems } from '@/lib/nav-items'
import { acceptInvite, declineInvite } from '@/lib/calls'
import { toast } from 'react-hot-toast'
import { CustomToast } from '@/components/custom-toast'

export function MainHeader() {
  const navigate = useNavigate()
  const { user } = useAuth()
  const [notifications, setNotifications] = useState(
    user?.notifications || null
  )

  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false)

  const handleNotificationsClick = () => {
    setShowNotificationsMenu(!showNotificationsMenu)
  }

  const handleAcceptInvite = async (inviteId, orgId) => {
    // console.log(inviteId, orgId)
    // console.log('accepted invite')
    try {
      await acceptInvite(inviteId, orgId)
      toast.custom(t => (
        <CustomToast message='Invitation accepted' type='success' />
      ))
      // Handle success - e.g., show a success message
    } catch (error) {
      // Handle errors - e.g., show error message

      toast.custom(t => (
        <CustomToast message='Error accepting invitation' type='error' />
      ))

      // console.log(error)
      console.error('Error accepting invitation:', error)
    }
  }

  const handleDeclineInvite = async (inviteId, orgId) => {
    // console.log(inviteId, orgId)
    // console.log('accepted invite')
    try {
      await declineInvite(inviteId, orgId)
      toast.custom(t => (
        <CustomToast message='Invitation declined' type='success' />
      ))
      // Handle success - e.g., show a success message
    } catch (error) {
      // Handle errors - e.g., show error message

      toast.custom(t => (
        <CustomToast message='Error declining invitation' type='error' />
      ))

      // console.log(error)
      console.error('Error declining invitation:', error)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('polling for notifications')
      // console.log(user.notifications)
      if (user) {
        setNotifications(user.notifications || null)
      }
    }, 5000) // Polling every 5 seconds

    return () => clearInterval(interval)
  }, [user])

  if (!user) {
    return null
  }

  return (
    <Header
      sx={{
        backgroundColor: 'canvas.overlay',
      }}
    >
      <Header.Item full>
        <OrgSwitcher />
      </Header.Item>
      {/* <Header.Item full>Navigation Links</Header.Item> */}
      <Header.Item
        sx={{
          mr: 0,
          display: 'flex',
          gap: 3,
        }}
      >
        <ActionMenu>
          {/* <pre>{JSON.stringify(authProvider.user.notifications)}</pre> */}
          {/* <pre>{JSON.stringify(notifications)}</pre> */}
          <ActionMenu.Anchor>
            <Avatar size='32' src={user.data.picture} />
          </ActionMenu.Anchor>
          {notifications ? (
            <Box
              sx={{
                height: '12px',
                position: 'absolute',
                width: '12px',
                zIndex: 1,
                borderRadius: '100%',
                backgroundColor: 'accent.fg',
                border: '1px solid',
                borderColor: 'border.default',
                mr: -4,
                mt: 4,
              }}
            ></Box>
          ) : (
            ''
          )}

          <ActionMenu.Overlay width='medium'>
            {!showNotificationsMenu ? (
              <ActionList>
                <ActionList.Item onClick={handleNotificationsClick}>
                  <Icons.Bell sx={{ mr: 2 }} />
                  Notifications{' '}
                  {notifications ? <Label>{notifications.length}</Label> : ''}
                </ActionList.Item>
                <ActionList.Divider />
                {sidebarSettingsNavItems.map((item, idx) => (
                  <NavList.Item as={NavLink} key={idx} to={item.path}>
                    {item.icon && <item.icon sx={{ mr: 1 }} />}{' '}
                    {/* Add right margin to the icon */}
                    {item.name}
                  </NavList.Item>
                ))}
                <ActionList.Divider />
                <NavList.Item as={NavLink} to='mailto:help@signgrid.io'>
                  <Icons.Info sx={{ mr: 2 }} />
                  Help
                </NavList.Item>

                <NavList.Item
                  as={NavLink}
                  to='https://docs.signgrid.io/api-v1/api-documentation'
                >
                  <Icons.BookIcon sx={{ mr: 2 }} />
                  API documentation
                </NavList.Item>
                <ActionList.Divider />
                <ActionList.Item
                  onClick={() => navigate('/logout')}
                  variant='danger'
                >
                  <Icons.SignOut sx={{ mr: 2 }} />
                  Sign out
                </ActionList.Item>
                {/* ... other ActionList.Items ... */}
              </ActionList>
            ) : (
              <ActionList>
                {/* <pre>{JSON.stringify(authProvider.user.notifications)}</pre> */}
                {notifications &&
                  notifications.map((notification, idx) => (
                    <ActionList.Item key={idx}>
                      <Box>
                        <Text>
                          {notification.inviter_email} sent you an invitation to
                          join their team
                        </Text>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 2,
                            width: '100%',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            mt: 2,
                          }}
                        >
                          <Button
                            onClick={() =>
                              handleAcceptInvite(
                                notification.id,
                                notification.org_id
                              )
                            }
                            size='small'
                            variant='default'
                            sx={{ flexGrow: 1 }}
                          >
                            Accept
                          </Button>
                          <Button
                            onClick={() =>
                              handleDeclineInvite(
                                notification.id,
                                notification.org_id
                              )
                            }
                            size='small'
                            variant='danger'
                            sx={{ flexGrow: 1 }}
                          >
                            Decline
                          </Button>
                        </Box>
                      </Box>
                    </ActionList.Item>
                  ))}
                {!notifications && (
                  <ActionList.Item onClick={handleNotificationsClick}>
                    No notifications
                  </ActionList.Item>
                )}
                {/* {authProvider.user.notifications.length > 0 ? authProvider.user.notifications.map(notification =>
                     <ActionList.Item key={notification.id}>
                      <Box>
                        <Text>{notification.id} sent you an invitation to join their team</Text>
                        <Button variant="small">Accept</Button>
                        <Button variant="small">Decline</Button>
                      </Box>
                    </ActionList.Item>) : <ActionList.Item onClick={handleNotificationsClick}>No notifications</ActionList.Item>} */}
                <ActionList.Divider />
                <ActionList.Item onClick={handleNotificationsClick}>
                  <Icons.ArrowLeft sx={{ mr: 2 }} />
                  Back to main menu
                </ActionList.Item>
              </ActionList>
            )}
          </ActionMenu.Overlay>
        </ActionMenu>
      </Header.Item>
    </Header>
  )
}
