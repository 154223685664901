import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import {
  Text,
  Box,
  Label,
  FormControl,
  TextInput,
  PageHeader,
  Button,
  LinkButton,
  Spinner,
  Tooltip,
  ActionMenu,
  ActionList,
} from '@primer/react'
import { Hidden } from '@primer/react/drafts'
import { DataTable, Table } from '@primer/react/drafts'
import { getPlaylists } from '@/lib/calls'
import { Icons } from '@/components/icons'
import { SearchIcon } from '@primer/octicons-react'

export function PlaylistsListPage() {
  const [pageIndex, setPageIndex] = useState(0)
  const pageSize = 10
  const [allPlaylists, setAllPlaylists] = useState([])
  const [filteredPlaylists, setFilteredPlaylists] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [fetchError, setFetchError] = useState(false)
  const [restricted, setRestricted] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [sortCriteria, setSortCriteria] = useState('recently_created')

  const filterPlaylists = useCallback((query) => {
    const searchTerms = query.toLowerCase().split(/[\n,\s]+/).map(term => term.trim())
    return allPlaylists.filter(item =>
      searchTerms.some(term => item.playlist.name.toLowerCase().includes(term))
    )
  }, [allPlaylists])

  const handleFilter = useCallback((event) => {
    const query = event.target.value
    setSearchQuery(query)
    setFilteredPlaylists(filterPlaylists(query))
    setPageIndex(0)
  }, [filterPlaylists])

  const handleSort = useCallback((criteria) => {
    setSortCriteria(criteria)
    const sortedPlaylists = [...filteredPlaylists].sort((a, b) => {
      switch (criteria) {
        case 'recently_created':
          return new Date(b.playlist.created_at) - new Date(a.playlist.created_at)
        case 'least_recently_created':
          return new Date(a.playlist.created_at) - new Date(b.playlist.created_at)
        case 'recently_updated':
          return new Date(b.playlist.updated_at) - new Date(a.playlist.updated_at)
        case 'least_recently_updated':
          return new Date(a.playlist.updated_at) - new Date(b.playlist.updated_at)
        default:
          return 0
      }
    })
    setFilteredPlaylists(sortedPlaylists)
    setPageIndex(0)
  }, [filteredPlaylists])

  const getSortText = useCallback(() => {
    switch (sortCriteria) {
      case 'recently_created': return 'Recently created'
      case 'least_recently_created': return 'Least recently created'
      case 'recently_updated': return 'Recently updated'
      case 'least_recently_updated': return 'Least recently updated'
      default: return 'Recently created'
    }
  }, [sortCriteria])

  useEffect(() => {
    async function fetchPlaylists() {
      setIsLoading(true)
      try {
        const response = await getPlaylists()
        const sortedPlaylists = response.data.sort((a, b) =>
          new Date(b.playlist.created_at) - new Date(a.playlist.created_at)
        )
        setAllPlaylists(sortedPlaylists)
        setFilteredPlaylists(sortedPlaylists)
      } catch (error) {
        if (error.response?.status === 403) {
          setRestricted(true)
        }
        setFetchError(true)
      } finally {
        setIsLoading(false)
      }
    }
    fetchPlaylists()
  }, [])

  const renderContent = () => {
    if (restricted) {
      return <RestrictedAccessMessage />
    }

    if (isLoading) {
      return <LoadingSpinner />
    }

    if (fetchError) {
      return <ErrorMessage />
    }

    if (filteredPlaylists.length === 0) {
      return searchQuery ? <NoSearchResultsMessage /> : <NoPlaylistsMessage />
    }

    return (
      <>
        <SortButton handleSort={handleSort} getSortText={getSortText} sortCriteria={sortCriteria} />
        <PlaylistsTable playlists={filteredPlaylists} pageIndex={pageIndex} setPageIndex={setPageIndex} pageSize={pageSize} />
      </>
    )
  }

  return (
    <Box sx={{ padding: 3 }}>
      <PageHeader>
        <PageHeader.TitleArea>
          <PageHeader.Title as="h2">Playlists</PageHeader.Title>
        </PageHeader.TitleArea>
        <PageHeader.Description sx={{ fontSize: 1, color: 'fg.muted' }}>
          Playlists are a collection of media files that are played in a sequence.
        </PageHeader.Description>
        <PageHeader.Actions>
          <Hidden when={['narrow']}>
            <CreatePlaylistButton text="Create new playlist" />
          </Hidden>
          <Hidden when={['regular', 'wide']}>
            <CreatePlaylistButton text="New" />
          </Hidden>
        </PageHeader.Actions>
      </PageHeader>

      <Box sx={{ marginTop: 4 }}>
        {!restricted && !isLoading && !fetchError && allPlaylists.length > 0 && (
          <FormControl sx={{ width: '100%', padding: 0.5, marginBottom: 3 }}>
            <FormControl.Label visuallyHidden>Filter</FormControl.Label>
            <TextInput
              leadingVisual={SearchIcon}
              placeholder='Filter playlists'
              onChange={handleFilter}
              value={searchQuery}
              block
            />
          </FormControl>
        )}

        {renderContent()}

        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'start', mt: 4, gap: 2 }}>
          <LinkButton href='https://docs.signgrid.io/playlists/manage-playlists' variant='invisible'>
            Playlists guide
          </LinkButton>
        </Box>
      </Box>
    </Box>
  )
}

const CreatePlaylistButton = ({ text }) => (
  <Link to='/dashboard/playlists/editor'>
    <Button variant='primary' data-testid='trigger-button'>{text}</Button>
  </Link>
)

const RestrictedAccessMessage = () => (
  <MessageBox
    title="You don't have access to this content."
    description={<><Icons.Alert /> Insufficient permissions to manage playlists.</>}
  />
)

const LoadingSpinner = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '420px' }}>
    <Spinner />
  </Box>
)

const ErrorMessage = () => (
  <MessageBox
    title="An error occurred fetching content."
    description={<><Icons.Alert /> Please try again later</>}
  />
)

const NoPlaylistsMessage = () => (
  <MessageBox
    title="You don't have any playlists."
    description="You can create a playlist by clicking the button below."
    action={<CreatePlaylistButton text="Create new playlist" />}
  />
)

const NoSearchResultsMessage = () => (
  <MessageBox
    title="No playlists found."
    description="Try adjusting your search terms."
  />
)

const MessageBox = ({ title, description, action }) => (
  <Box sx={{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 3,
    mt: 4,
  }}>
    <Text sx={{ fontSize: 4, fontWeight: 'bold', textAlign: 'center' }}>{title}</Text>
    <Text sx={{ fontSize: 1, color: 'fg.muted' }}>{description}</Text>
    {action}
  </Box>
)

const SortButton = ({ handleSort, getSortText, sortCriteria }) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%', marginBottom: 2 }}>
    <ActionMenu>
      <ActionMenu.Button>
        <Text sx={{ color: 'fg.muted' }}>Sort:</Text>{' '}
        <Text>{getSortText()}</Text>
      </ActionMenu.Button>
      <ActionMenu.Overlay width='small'>
        <ActionList selectionVariant='single'>
          <ActionList.Item selected={sortCriteria === 'recently_created'} onSelect={() => handleSort('recently_created')}>
            Recently created
          </ActionList.Item>
          <ActionList.Item selected={sortCriteria === 'least_recently_created'} onSelect={() => handleSort('least_recently_created')}>
            Least recently created
          </ActionList.Item>
          <ActionList.Item selected={sortCriteria === 'recently_updated'} onSelect={() => handleSort('recently_updated')}>
            Recently updated
          </ActionList.Item>
          <ActionList.Item selected={sortCriteria === 'least_recently_updated'} onSelect={() => handleSort('least_recently_updated')}>
            Least recently updated
          </ActionList.Item>
        </ActionList>
      </ActionMenu.Overlay>
    </ActionMenu>
  </Box>
)

const PlaylistsTable = ({ playlists, pageIndex, setPageIndex, pageSize }) => (
  <Table.Container>
    <Table.Title as='h2' id='playlists'>Playlists</Table.Title>
    <Table.Subtitle as='p' id='playlists-subtitle' />
    <DataTable
      aria-labelledby='playlists'
      aria-describedby='playlists-subtitle'
      data={playlists.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)}
      columns={[
        {
          header: 'Name',
          field: 'playlist.name',
          renderCell: row => (
            <Tooltip aria-label={row.playlist.name}>
              <Link to={`/dashboard/playlists/editor?playlist_id=${row.playlist.id}`}>
                <Button variant='invisible'>
                  {row.playlist.name.length > 25 ? `${row.playlist.name.slice(0, 25)}...` : row.playlist.name}
                </Button>
              </Link>
            </Tooltip>
          ),
          rowHeader: true,
        },
        {
          header: 'Apps',
          field: 'playlist.apps',
          renderCell: row => (
            <Label sx={{ fontSize: 1, color: 'fg.muted' }}>
              <Icons.Apps sx={{ mr: 1 }} /> {row.playlist.apps ? row.playlist.apps.length : 0}
            </Label>
          ),
        },
        {
          header: 'Files',
          field: 'playlist.files',
          renderCell: row => (
            <Label sx={{ fontSize: 1, color: 'fg.muted' }}>
              <Icons.FileMedia sx={{ mr: 1 }} /> {row.playlist.files.length}
            </Label>
          ),
        },
      ]}
    />
    <Table.Pagination
      aria-label="Pagination for Playlists"
      pageSize={pageSize}
      totalCount={playlists.length}
      pageIndex={pageIndex}
      onChange={({ pageIndex }) => setPageIndex(pageIndex)}
    />
  </Table.Container>
)