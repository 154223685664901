import React from 'react'
import { Box, Text } from '@primer/react'

const Card = React.forwardRef(({ children, ...props }, ref) => (
  <Box
    ref={ref}
    bg=''
    border='1px solid'
    borderColor='border.default'
    borderRadius={2}
    boxShadow='shadow.small'
    {...props}
  >
    {children}
  </Box>
))

const CardHeader = React.forwardRef(({ children, ...props }, ref) => (
  <Box ref={ref} display='flex' flexDirection='column' p={3} {...props}>
    {children}
  </Box>
))

const CardTitle = React.forwardRef(({ children, ...props }, ref) => (
  <Text ref={ref} fontSize={3} fontWeight='bold' {...props}>
    {children}
  </Text>
))

const CardDescription = React.forwardRef(({ children, ...props }, ref) => (
  <Text ref={ref} fontSize={1} color='fg.muted' {...props}>
    {children}
  </Text>
))

const CardContent = React.forwardRef(({ children, ...props }, ref) => (
  <Box ref={ref} p={3} pt={0} {...props}>
    {children}
  </Box>
))

const CardFooter = React.forwardRef(({ children, ...props }, ref) => (
  <Box ref={ref} display='flex' alignItems='center' p={3} pt={0} {...props}>
    {children}
  </Box>
))

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent }
