import React, { createContext, useContext, useState } from "react";

const ReloadTableContext = createContext();

export const ReloadTableProvider = ({ children }) => {
  const [reloadTable, setReloadTable] = useState(false);

  return (
    <ReloadTableContext.Provider value={{ reloadTable, setReloadTable }}>
      {children}
    </ReloadTableContext.Provider>
  );
};

export const useReloadTable = () => useContext(ReloadTableContext);

// for playlists

const ReloadContentContext = createContext();

export const ReloadContentProvider = ({ children }) => {
  const [reloadContent, setReloadContent] = useState(false);

  return (
    <ReloadContentContext.Provider value={{ reloadContent, setReloadContent }}>
      {children}
    </ReloadContentContext.Provider>
  );
};

export const useReloadContent = () => useContext(ReloadContentContext);
