/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { stripe } from '@/lib/stripe'
import { Link } from 'react-router-dom'
import { useAuth } from '@/contexts/AuthContext'
import {
  Box,
  Text,
  ProgressBar,
  Tooltip,
  Spinner,
  PageHeader,
  AvatarStack,
  Avatar,
  Label,
} from '@primer/react'
import { BarChartExampleWithGroups } from '@/components/bar-chart-devices-activity-7d.tsx'
import { MetricsEnrollments } from '@/components/line-chart-enrollments-7d.tsx'
import { TableProofOfPlay } from '@/components/table-proof-of-play-7d'
import KPICard from '@/components/ui/kpi-card.jsx'
import {
  getDevices,
  getFiles,
  getPlaylists,
  getSchedules,
  getOrgSubscriptionPlan,
  getMetricsDevice,
  getMetricsEnrollments,
  getMetricsProofOfPlay,
} from '@/lib/calls'

export function DashboardPage() {
  const { user } = useAuth()
  const [subscriptionPlan, setSubscriptionPlan] = useState(null)
  const [isCanceled, setIsCanceled] = useState(false)
  const [devices, setDevices] = useState([])
  // const [files, setFiles] = useState([])
  const [deviceDistribution, setDeviceDistribution] = useState([])
  const [totalOnline, setTotalOnline] = useState(0)
  const [totalOffline, setTotalOffline] = useState(0)
  const [totalFileSizeGB, setTotalFileSizeGB] = useState(0)
  const [fetchError, setFetchError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [restricted, setRestricted] = useState(false)
  const [metricsDevice, setMetricsDevice] = useState([])
  const [metricsEnrollments, setMetricsEnrollments] = useState([])
  const [totalSizeBytes, setTotalSizeBytes] = useState(0)
  const [metricsProofOfPlay, setMetricsProofOfPlay] = useState([])
  // const [playlists, setPlaylists] = useState([])
  // const [schedules, setSchedules] = useState([])
  const [initialLoadComplete, setInitialLoadComplete] = useState(false)

  // converts bytes to gigabytes with 3 decimal places
  const formatSize = bytes => {
    if (bytes >= 1e9) {
      // Gigabytes
      return `${(bytes / 1e9).toFixed(2)}GB`
    } else if (bytes >= 1e6) {
      // Megabytes
      return `${(bytes / 1e6).toFixed(2)}MB`
    } else if (bytes >= 1e3) {
      // Kilobytes
      return `${(bytes / 1e3).toFixed(2)}KB`
    } else {
      // Bytes
      return `${bytes}bytes`
    }
  }

  const calculateTotalFileSize = files => {
    const totalSize = files.reduce((sum, file) => sum + file.meta.size, 0)
    setTotalSizeBytes(totalSize)
    return formatSize(totalSize)
  }

  function getColorCircle(color) {
    return (
      <Box
        sx={{
          backgroundColor: color,
          borderColor: color,
          width: 14,
          height: 14,
          borderRadius: 10,
          margin: 'auto',
          borderWidth: '1px',
          borderStyle: 'solid',
        }}
      />
    )
  }

  const matchData = (metrics, playlists, schedules, files) => {
    return metrics.map(metric => {
      const playlist = playlists.find(p => p.playlist.id === metric.playlist_id)
      const schedule = schedules.find(s => s.id === metric.schedule_id)
      const file = files.find(f => f.id === metric.file_id)

      return {
        ...metric,
        playlist_name: playlist ? playlist.playlist.name : 'Unknown',
        playlist_url: playlist
          ? `/dashboard/playlists/editor?playlist_id=${playlist.playlist.id}`
          : '#',
        schedule_name: schedule ? schedule.name : 'Unknown',
        schedule_url: schedule
          ? `/dashboard/schedules?schedule_id=${schedule.id}`
          : '#',
        file_name: file ? file.name : 'Unknown',
        file_url: file ? `/dashboard/content?file_id=${file.id}` : '#',
      }
    })
  }

  const fetchAndMatchProofOfPlayData = async (setLoading = true) => {
    if (setLoading) setIsLoading(true);
    try {
      const [metricsProofOfPlayData, playlistsData, schedulesData, filesData] = await Promise.all([
        getMetricsProofOfPlay(),
        getPlaylists(),
        getSchedules(),
        getFiles(),
      ]);

      // setPlaylists(playlistsData.data);
      // setSchedules(schedulesData.data);
      // setFiles(filesData.data);

      const matchedData = matchData(
        metricsProofOfPlayData.data,
        playlistsData.data,
        schedulesData.data,
        filesData.data
      );
      setMetricsProofOfPlay(matchedData);
    } catch (error) {
      handleFetchError(error);
    } finally {
      if (setLoading) setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          fetchEssentialData(),
          fetchAdditionalData(),
          fetchAndMatchProofOfPlayData(false),
        ]);
        setInitialLoadComplete(true);
      } catch (error) {
        console.error('Error during initial data fetch:', error);
        setFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  // Periodic data update
  useEffect(() => {
    if (!initialLoadComplete) return;

    const updateData = async () => {
      try {
        await Promise.all([
          fetchDevices(false),
          fetchMetricsDevice(false),
          fetchMetricsEnrollments(false),
          fetchAndMatchProofOfPlayData(false),
        ]);
      } catch (error) {
        console.error('Error during data update:', error);
      }
    };

    const intervalId = setInterval(updateData, 30000); // 30 seconds

    return () => clearInterval(intervalId);
  }, [initialLoadComplete]);

  const fetchEssentialData = async () => {
    try {
      const [devicesData, filesData] = await Promise.all([
        getDevices(),
        getFiles(),
      ]);

      setDevices(devicesData.data);
      // setFiles(filesData.data);
      calculateDistribution(devicesData.data);
      calculateOnlineOffline(devicesData.data);
      setTotalFileSizeGB(calculateTotalFileSize(filesData.data));
    } catch (error) {
      console.error('Error fetching essential data:', error);
      setFetchError(true);
    }
  };

  const fetchSubscriptionData = async () => {
    try {
      const subscriptionData = await fetchSubscription();
      setSubscriptionPlan(subscriptionData);
    } catch (error) {
      console.error('Error fetching subscription data:', error);
    }
  };

  const fetchAdditionalData = async () => {
    try {
      const [metricsDeviceData, metricsEnrollmentsData] = await Promise.all([
        getMetricsDevice(),
        getMetricsEnrollments(),
      ]);
      setMetricsDevice(metricsDeviceData.data);
      setMetricsEnrollments(metricsEnrollmentsData.data);
    } catch (error) {
      console.error('Error fetching additional data:', error);
    }
  };

  const fetchDevices = async (setLoading = true) => {
    if (setLoading) setIsLoading(true);
    try {
      const response = await getDevices();
      setDevices(response.data);
      calculateDistribution(response.data);
      calculateOnlineOffline(response.data);
    } catch (error) {
      handleFetchError(error);
    } finally {
      if (setLoading) setIsLoading(false);
    }
  };

  const fetchMetricsDevice = async (setLoading = true) => {
    if (setLoading) setIsLoading(true);
    try {
      const response = await getMetricsDevice();
      setMetricsDevice(response.data);
    } catch (error) {
      handleFetchError(error);
    } finally {
      if (setLoading) setIsLoading(false);
    }
  };

  const fetchMetricsEnrollments = async (setLoading = true) => {
    if (setLoading) setIsLoading(true);
    try {
      const response = await getMetricsEnrollments();
      setMetricsEnrollments(response.data);
    } catch (error) {
      handleFetchError(error);
    } finally {
      if (setLoading) setIsLoading(false);
    }
  };

  const fetchSubscription = async () => {
    try {
      const response = await getOrgSubscriptionPlan(user.data.org_id);
      if (response.isPro && response.stripeSubscriptionId) {
        try {
          const stripePlan = await stripe.subscriptions.retrieve(
            response.stripeSubscriptionId
          );
          setIsCanceled(stripePlan.cancel_at_period_end);
          console.log(isCanceled)
        } catch (error) {
          console.error('Stripe fetch error: ', error);
        }
      }
      return response;
    } catch (error) {
      console.error('Subscription fetch error:', error);
      throw error; // Rethrow to be caught in the main try-catch
    }
  };

  const handleFetchError = (error) => {
    setFetchError(true);
    if (error.response && error.response.status === 403) {
      setRestricted(true);
    }
    console.error('Error fetching data:', error);
  };

  const calculateDistribution = devices => {
    const total = devices.length
    const systemNameCounts = devices.reduce((acc, device) => {
      acc[device.device_system_name] = (acc[device.device_system_name] || 0) + 1
      return acc
    }, {})

    const sortedSystemNameCounts = Object.entries(systemNameCounts)
      .sort((a, b) => b[1] - a[1])
      .map(([name, count]) => ({ name, count }))

    let percentages = sortedSystemNameCounts
      .slice(0, 3)
      .map(({ name, count }) => ({
        name,
        percentage: (count / total) * 100,
        color: 'primary.emphasis', // Placeholder, you will need to map system names to colors
      }))

    const othersCount = sortedSystemNameCounts
      .slice(3)
      .reduce((sum, { count }) => sum + count, 0)
    const othersPercentage = (othersCount / total) * 100

    if (othersPercentage > 0) {
      percentages.push({
        name: 'Other',
        percentage: othersPercentage,
        color: 'primary.emphasis',
      })
    }

    // Assign colors dynamically, e.g., based on index or condition
    percentages = percentages.map((item, index) => ({
      ...item,
      color:
        index === 0
          ? 'accent.muted'
          : index === 1
            ? 'open.muted'
            : index === 2
              ? 'done.muted'
              : 'neutral.muted',
    }))

    setDeviceDistribution(percentages)
  }
  const calculateOnlineOffline = devices => {
    const now = new Date()
    const onlineCount = devices.filter(device => {
      if (device.last_seen) {
        const lastSeen = new Date(device.last_seen)
        const diff = now - lastSeen
        return diff <= 120000 // 2 minutes in milliseconds
      }
      return false
    }).length

    // const total = devices.length
    // const onlinePercentage = (onlineCount / total) * 100
    // const offlinePercentage = 100 - onlinePercentage

    setTotalOnline(onlineCount)
    setTotalOffline(devices.length - onlineCount)

    // setOnlineOffline([
    //   { percentage: onlinePercentage, color: 'success.fg' }, // Example color for online
    //   { percentage: offlinePercentage, color: 'danger.fg' }, // Example color for offline
    // ])
  }

  // return <pre>{JSON.stringify(authProvider.user, null, 2)}</pre>;
  return (
    <>
      <Box sx={{}}>


      <Box sx={{
        padding: 3
      }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Dashboard</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Description sx={{
              fontSize: 1,
              color: 'fg.muted',
            }}>
              Organization's overview.
            </PageHeader.Description>
          </PageHeader>
        </Box>

        {isLoading && !fetchError && !restricted && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '400px',
            }}
          >
            <Spinner />
          </Box>
        )}

        {!isLoading && (
          <>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
                gap: 3,
              }}
            >
              <Link to='/dashboard/settings/team'>
                <KPICard
                  title='Team seats'
                  message={`${user?.current_org.members.length}/${subscriptionPlan?.isPro ? '10' : '1'}`}
                  showProgressBar={true}
                  progressBarItem={user?.current_org.members.length}
                  progressBarTotal={subscriptionPlan?.isPro ? 10 : 1}
                  itemColor='accent.fg'
                  totalColor='accent.muted'
                />
              </Link>

              <KPICard
                title='Devices online'
                message={`${totalOnline}/${devices.length}`}
                showProgressBar={true}
                progressBarItem={totalOnline}
                progressBarTotal={devices.length}
                totalColor='danger.muted'
              // itemColor='success.fg'
              />

              <Link to='/dashboard/devices'>
                <KPICard
                  title='Devices'
                  message={`${devices?.length}${subscriptionPlan?.isPro ? '' : '/2'}`}
                  showProgressBar={subscriptionPlan?.isPro ? false : true}
                  progressBarItem={devices.length}
                  progressBarTotal={subscriptionPlan?.isPro ? 0 : 2}
                />
              </Link>

              <Link to='/dashboard/content'>
              <KPICard
    title='Content uploads'
    message={`${totalFileSizeGB.toString()}/${subscriptionPlan?.isPro ? '20GB' : '150MB'}`}
    showProgressBar={true}
    progressBarItem={totalSizeBytes}
    progressBarTotal={
      subscriptionPlan?.isPro ? 20 * 1024 ** 3 : 150 * 1024 * 1024
    } // 20GB for Pro, 150MB for free tier in bytes
  />
              </Link>

              {subscriptionPlan ? (
                <Link to='/dashboard/settings/billing'>
                  <KPICard
                    title='Subscription plan'
                    message={
                      subscriptionPlan.isPro ? (
                        <Label variant='done' size='large'>
                          PRO
                        </Label>
                      ) : (
                        <Label variant='default' size='large'>
                          Free
                        </Label>
                      )
                    }
                    showProgressBar={false}
                    progressBarItem={0}
                    progressBarTotal={0}
                  />
                </Link>
              ) : (
                <KPICard
                  title='Subscription plan'
                  message={<Spinner size='medium' />}
                  showProgressBar={false}
                  progressBarItem={0}
                  progressBarTotal={0}
                />
              )}
            </Box>
            {/* <Box */}
            {/*   sx={{ */}
            {/*     // height: '250px', */}
            {/*     mt: 4, */}
            {/*     border: '1px solid', */}
            {/*     borderColor: 'border.default', */}
            {/*     borderRadius: 2, */}
            {/*     padding: 3, */}
            {/*     display: 'flex', */}
            {/*     flexDirection: 'column', */}
            {/*   }} */}
            {/* > */}
            {/*   <Text */}
            {/*     sx={{ */}
            {/*       fontSize: 3, */}
            {/*       fontWeight: 'bold', */}
            {/*       color: 'fg.default', */}
            {/*     }} */}
            {/*   > */}
            {/*     Devices */}
            {/*   </Text> */}
            {/*   <Box> */}
            {/*     <Donut /> */}
            {/*   </Box> */}
            {/* </Box> */}

            <Box>
              <Box
                sx={{
                  // height: '250px',
                  mt: 4,
                  width: ['100%', '100%', '100%'], // Responsive width
                  border: '1px solid',
                  borderColor: 'border.default',
                  borderRadius: 2,
                  padding: 3,
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                <Text
                  sx={{
                    fontSize: 2,
                    fontWeight: 'bold',
                    color: 'fg.default',
                  }}
                >
                  Devices
                </Text>
                <Box>
                  <ProgressBar
                    aria-valuenow={100}
                    aria-label='device distribution'
                  >
                    {deviceDistribution.map((item, index) => (
                      <ProgressBar.Item
                        key={index}
                        progress={Math.round(item.percentage)}
                        sx={{ backgroundColor: item.color }}
                      />
                    ))}
                  </ProgressBar>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 3,
                      mt: 3,
                      flexWrap: 'wrap', // This allows the content to wrap
                    }}
                  >
                    {deviceDistribution.map((item, index) => (
                      <Box
                        key={index}
                        sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
                      >
                        <Label
                          variant='secondary'
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                          }}
                          size='large'
                        >
                          {getColorCircle(item.color)}{' '}
                          <Tooltip aria-label={item.name}>
                            <Text>
                              {item.name.length > 25
                                ? `${item.name.slice(0, 25)}...`
                                : item.name}
                            </Text>
                          </Tooltip>
                        </Label>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 3,
                width: "100%",
                // backgroundColor: "danger.emphasis",
                WebkitJustifyContent: "end",
              }}
            >
              <ButtonGroup>
                <Button>24h</Button>
                <Button>7d</Button>
                <Button>30d</Button>
              </ButtonGroup>
            </Box> */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  // height: '250px',
                  mt: 4,
                  width: ['100%', '100%', '100%'], // Responsive width
                  // backgroundColor: 'fg.subtle',
                  // color: 'fg.default',
                  border: '1px solid',
                  borderColor: 'border.default',
                  borderRadius: 2,
                  padding: 3,
                  display: 'flex',
                  height: ['430px', '350px', '350px'],
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Text
                  sx={{
                    fontSize: 2,
                    fontWeight: 'bold',
                    color: 'fg.default',
                  }}
                >
                  Device activity over the last 7 days
                </Text>

                {!metricsDevice && !isLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Text
                      sx={{
                        color: 'fg.muted',
                      }}
                    >
                      No records found
                    </Text>
                  </Box>
                )}

                {metricsDevice && metricsDevice.length === 0 && !isLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Text
                      sx={{
                        color: 'fg.muted',
                      }}
                    >
                      No records found
                    </Text>
                  </Box>
                )}
                {isLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Spinner />
                  </Box>
                )}
                {metricsDevice && metricsDevice.length > 0 && (
                  <BarChartExampleWithGroups
                    data={metricsDevice}
                    totalDevices={totalOnline + totalOffline}
                  />
                )}
              </Box>
              <Box
                sx={{
                  // height: '250px',
                  mt: 4,
                  width: ['100%', '100%', '100%'], // Responsive width
                  // backgroundColor: 'fg.subtle',
                  // color: 'fg.default',
                  border: '1px solid',
                  borderColor: 'border.default',
                  borderRadius: 2,
                  padding: 3,
                  display: 'flex',
                  // height: '350px',
                  height: ['430px', '350px', '350px'],
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                <Text
                  sx={{
                    fontSize: 2,
                    fontWeight: 'bold',
                    color: 'fg.default',
                  }}
                >
                  Device enrollments over the last 7 days
                </Text>

                {!metricsEnrollments && !isLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Text
                      sx={{
                        color: 'fg.muted',
                      }}
                    >
                      No records found
                    </Text>
                  </Box>
                )}

                {metricsEnrollments &&
                  metricsEnrollments.length === 0 &&
                  !isLoading && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Text
                        sx={{
                          color: 'fg.muted',
                        }}
                      >
                        No records found
                      </Text>
                    </Box>
                  )}
                {isLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Spinner />
                  </Box>
                )}
                {metricsEnrollments && metricsEnrollments.length > 0 && (
                  <MetricsEnrollments data={metricsEnrollments} />
                )}
              </Box>

              <Box
                sx={{
                  // height: '250px',
                  mt: 4,
                  width: ['100%', '100%', '100%'], // Responsive width
                  // backgroundColor: 'fg.subtle',
                  // color: 'fg.default',
                  border: '1px solid',
                  borderColor: 'border.default',
                  borderRadius: 2,
                  padding: 3,
                  display: 'flex',
                  // height: '350px',
                  height: ['430px', '350px', '350px'],
                  flexDirection: 'column',
                  gap: 4,
                  overflow: 'auto',
                }}
              >
                <Text
                  sx={{
                    fontSize: 2,
                    fontWeight: 'bold',
                    color: 'fg.default',
                  }}
                >
                  Proof of play data for the last 7 days
                </Text>

                {!metricsProofOfPlay && !isLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Text
                      sx={{
                        color: 'fg.muted',
                      }}
                    >
                      No records found
                    </Text>
                  </Box>
                )}

                {metricsProofOfPlay &&
                  metricsProofOfPlay.length === 0 &&
                  !isLoading && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Text
                        sx={{
                          color: 'fg.muted',
                        }}
                      >
                        No records found
                      </Text>
                    </Box>
                  )}
                {isLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Spinner />
                  </Box>
                )}
                {metricsProofOfPlay && metricsProofOfPlay.length > 0 && (
                  <TableProofOfPlay data={metricsProofOfPlay} />
                )}
              </Box>

              <Box
                sx={{
                  mt: 4,
                  width: ['100%', '100%', '100%'], // Responsive borderWidth
                  border: '1px solid',
                  borderColor: 'border.default',
                  borderRadius: 2,
                  padding: 3,
                  display: 'flex',
                  height: '150px',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                <Text
                  sx={{
                    fontSize: 2,
                    fontWeight: 'bold',
                    color: 'fg.default',
                  }}
                >
                  Your team
                </Text>
                {!user?.current_org.members && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Spinner />
                  </Box>
                )}

                {/* <pre> */}
                {/*   {JSON.stringify( */}
                {/*     authProvider.user.current_org.members, */}
                {/*     null, */}
                {/*     2 */}
                {/*   )} */}
                {/* </pre> */}

                {user?.current_org.members && (
                  <AvatarStack size={{ narrow: 32, regular: 42, wide: 42 }}>
                    {user?.current_org.members.map(member => (
                      <Avatar
                        key={member.user.id}
                        alt={member.user.email}
                        src={member.user.picture}
                      />
                    ))}
                  </AvatarStack>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  )
}
