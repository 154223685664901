/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback, useRef } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import {
  Box,
  TreeView,
  Text,
  Button,
  Tooltip,
  Spinner,
  LinkButton,
  PageHeader,
  Dialog,
  FormControl,
  TextInput,
  Select,
  Truncate,
  Label,
  AnchoredOverlay,
  Autocomplete,
} from '@primer/react'
import {
  getFoldersAndFiles,
  createFolder,
  uploadFile,
  deleteFolder,
  deleteFile,
  patchFolderName,
  getPlaylists,
  getSchedules,
} from '@/lib/calls'
import {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
} from '@/components/ui/card-primer'
import { Icons } from '@/components/icons'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export function ContentPage() {
  // const [renameValue, setRenameValue] = useState("");
  const [restricted, setRestricted] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [reloadContent, setReloadContent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingTree, setIsLoadingTree] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const navigate = useNavigate()

  const formatSize = bytes => {
    if (bytes >= 1e9) {
      // Gigabytes
      return `${(bytes / 1e9).toFixed(2)} GB`
    } else if (bytes >= 1e6) {
      // Megabytes
      return `${(bytes / 1e6).toFixed(2)} MB`
    } else if (bytes >= 1e3) {
      // Kilobytes
      return `${(bytes / 1e3).toFixed(2)} KB`
    } else {
      // Bytes
      return `${bytes} bytes`
    }
  }

  const [content, setContent] = useState(null)
  const query = useQuery()
  // const currentFolderId = query.get('folder');
  const folderId = query.get('folder')
  const fileId = query.get('file_id')

  // dialogs
  const buttonRef = useRef()
  const [isOpenNewFolderDialog, setIsOpenNewFolderDialog] = useState(false)
  const onDialogCloseNewFolder = useCallback(() => {
    if (!isLoading) {
      setIsOpenNewFolderDialog(false)
    }
  }, [isLoading])
  // const onDialogCloseNewFolder = useCallback(() => setIsOpenNewFolderDialog(false), []);

  const handleOpenNewFolderDialog = () => {
    setShowVideo(false)
    setValue('')
    setIsOpenNewFolderDialog(true)
    setSelectedParent('choose_parent_id')
    setSelectedParentId('')
  }

  const handleSubmitNewFolder = async () => {
    if (
      selectValidationResult === 'validSelection' &&
      validationResult === 'validName'
    ) {
      // alert(selectedParentId);
      try {
        if (value === '') {
          return toast.custom(t => (
            <CustomToast message='Name is invalid' type='error' />
          ))
        }

        setIsLoading(true)

        await createFolder(value, selectedParentId)
        setReloadContent(true)
        toast.custom(t => (
          <CustomToast message='Folder created' type='success' />
        ))

        setIsLoading(false)
        setIsOpenNewFolderDialog(false)

        // setUserRoleChanged(true);
        // Handle success - e.g., show a success message
      } catch (error) {
        setIsLoading(false)
        // Handle errors - e.g., show error message

        toast.custom(t => (
          <CustomToast message='Error could not create folder' type='error' />
        ))

        // console.log(error)
        console.error('Error creating role:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const [isOpenUploadFileDialog, setIsOpenUploadFileDialog] = useState(false)
  const onDialogCloseUploadFileDialog = useCallback(() => {
    if (!isLoading) {
      setIsOpenUploadFileDialog(false)
    }
  }, [isLoading])

  const handleOpenUploadFileDialog = () => {
    setShowVideo(false)
    setIsOpenUploadFileDialog(true)
    setSelectedParent('choose_parent_id')
    setSelectedParentId('')
    setFile(null)
  }

  const handleSubmitUploadFile = async () => {
    if (
      selectValidationResult === 'validSelection' &&
      validationResultFile === 'validFile'
    ) {
      // alert(selectedParentId);
      // alert(file);
      try {
        setIsLoading(true)

        await uploadFile(file, selectedParentId)

        setReloadContent(true)

        toast.custom(t => (
          <CustomToast message='File uploaded' type='success' />
        ))

        setIsLoading(false)

        setIsOpenUploadFileDialog(false)

        // setUserRoleChanged(true);
        // Handle success - e.g., show a success message
      } catch (error) {
        // Handle errors - e.g., show error message
        setIsLoading(false)

        toast.custom(t => (
          <CustomToast message='Error could not upload file' type='error' />
        ))

        // console.log(error)
        console.error('Error uploading file:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const [isOpenRenameFolderDialog, setIsOpenRenameFolderDialog] =
    useState(false)
  const onDialogCloseRenameFolderDialog = useCallback(() => {
    if (!isLoading) {
      setIsOpenRenameFolderDialog(false)
    }
  }, [isLoading])

  const [isOpenDeleteFolderDialog, setIsOpenDeleteFolderDialog] =
    useState(false)
  const onDialogCloseDeleteFolderDialog = useCallback(() => {
    if (!isLoading) {
      setIsOpenDeleteFolderDialog(false)
    }
  }, [isLoading])

  // const handleOpenDeleteFolderDialog = () => {
  //   setIsOpenDeleteFolderDialog(true);
  // }

  const handleSubmitDeleteFolder = async () => {
    try {
      setIsLoading(true)

      await deleteFolder(folderId)

      setIsLoading(false)
      navigate(`?folder=null&file_id=null`)
      setReloadContent(true)

      toast.custom(t => (
        <CustomToast message='Folder and content deleted' type='success' />
      ))

      setIsOpenDeleteFolderDialog(false)

      // Handle success - e.g., show a success message
    } catch (error) {
      // Handle errors - e.g., show error message
      setIsLoading(false)

      toast.custom(t => (
        <CustomToast
          message='Error could not delete folder and content'
          type='error'
        />
      ))

      // console.log(error)
      console.error('Error deleting folder:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const [isOpenDeleteFileDialog, setIsOpenDeleteFileDialog] = useState(false)
  const onDialogCloseDeleteFileDialog = useCallback(() => {
    if (!isLoading) {
      setIsOpenDeleteFileDialog(false)
    }
  }, [isLoading])

  const handleSubmitDeleteFile = async () => {
    try {
      setIsLoading(true)

      await deleteFile(fileId)

      navigate(`?folder=null&file_id=null`)
      setReloadContent(true)

      toast.custom(t => <CustomToast message='File deleted' type='success' />)

      setIsLoading(false)
      setIsOpenDeleteFileDialog(false)

      // Handle success - e.g., show a success message
    } catch (error) {
      // Handle errors - e.g., show error message
      setIsLoading(false)

      toast.custom(t => (
        <CustomToast message='Error could not delete file' type='error' />
      ))

      // console.log(error)
      console.error('Error deleting file:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // validations and values
  const [value, setValue] = useState('')
  const [currentFolder, setCurrentFolder] = useState('')
  const [selectedParent, setSelectedParent] = useState('choose_parent_id')
  const [selectedParentId, setSelectedParentId] = useState('choose_parent_id')
  const [file, setFile] = useState(null)

  const [validationResult, setValidationResult] = useState('')
  const [selectValidationResult, setSelectValidationResult] = useState('')
  const [validationResultFile, setValidationResultFile] = useState('emptyFile')

  const handleInputChange = e => {
    setValue(e.currentTarget.value)
  }

  const handleOpenRenameFolderDialog = (folderName, folderId) => {
    setShowVideo(false)
    setValue(folderName)
    setValidationResult('validName')
    setCurrentFolder(folderId)
    setIsOpenRenameFolderDialog(true)
  }

  const handleSubmitRenameFolder = async () => {
    if (validationResult === 'validName') {
      setIsLoading(true)
      try {
        await patchFolderName(currentFolder, value)

        setReloadContent(true)
        toast.custom(t => (
          <CustomToast message='Folder renamed' type='success' />
        ))
        setIsLoading(false)
        setIsOpenRenameFolderDialog(false)
      } catch (error) {
        setIsLoading(false)
        toast.custom(t => (
          <CustomToast message='Error could not rename folder' type='error' />
        ))
        // console.log(error)
        console.error('Error renaming folder:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleFileChange = e => {
    // console.log('FILE', e.currentTarget.files[0])
    setValidationResultFile('validFile')
    setFile(e.currentTarget.files[0])
  }

  const handleParentSelected = event => {
    setSelectedParent(event.currentTarget.value)
    setSelectedParentId(event.currentTarget.value)
    setValidationResult('validSelection')
  }

  const generateFolderOptions = (folder, prefix = '') => {
    let options = []

    const namePrefix = prefix ? `${prefix} / ` : ''
    options.push(
      <Select.Option key={folder.id} value={folder.id}>
        {namePrefix + folder.name}
      </Select.Option>
    )

    if (folder.children) {
      folder.children.forEach(childFolder => {
        options = options.concat(
          generateFolderOptions(childFolder, namePrefix + folder.name)
        )
      })
    }

    return options
  }

  const folderOptions = content ? generateFolderOptions(content.data) : []

  useEffect(() => {
    // console.log('FILE', file)
    if (!file) {
      setValidationResultFile('emptyFile')
    } else {
      setValidationResultFile('validFile')
    }
    // console.log(selectedRoleId)
    if (selectedParentId === '') {
      setSelectValidationResult('noSelection')
    } else {
      setSelectValidationResult('validSelection')
    }
    if (value.length === 0) {
      setValidationResult('empty')
    } else if (value.length > 255) {
      setValidationResult('tooLong')
    } else if (value.length < 3) {
      setValidationResult('tooShort')
    } else {
      setValidationResult('validName')
    }
  }, [value, selectedParentId, file])

  const findFileById = (folders, fileId) => {
    for (const folder of folders) {
      // Search in the current folder's files
      const foundFile = folder.files?.find(file => file.id === fileId)
      if (foundFile) {
        return foundFile
      }

      // Recursively search in children folders
      if (folder.children) {
        const fileInChild = findFileById(folder.children, fileId)
        if (fileInChild) {
          return fileInChild
        }
      }
    }

    return null
  }

  const [playlists, setPlaylists] = useState([])
  const [schedules, setSchedules] = useState([])
  const [playlistError, setPlaylistError] = useState(false)
  const [scheduleError, setScheduleError] = useState(false)
  const [extraInfo, setExtraInfo] = useState({})

  function findPlaylistsAndSchedules(fileId, playlists, schedules) {
    // Find playlists that contain the specified file ID
    const matchedPlaylists = playlists.filter(playlist =>
      playlist.playlist.files.some(file => file.id === fileId)
    )

    // For each matched playlist, find corresponding schedules
    const result = matchedPlaylists.map(playlist => {
      const matchedSchedules = schedules.filter(
        schedule => schedule.playlist_id === playlist.playlist.id
      )
      return {
        playlist: playlist.playlist,
        schedules: matchedSchedules,
      }
    })

    return result
  }

  const fetchPlaylists = async () => {
    try {
      setIsLoading(true)
      const response = await getPlaylists() // Your API call to fetch items
      if (!response.error) {
        setIsLoading(false)
        setPlaylists(response.data)
      } else {
        setIsLoading(false)
        console.error('API returned an error:', response.msg)
        // Handle API error state here, e.g., set an error message state and display it
      }
    } catch (error) {
      setIsLoading(false)
      setPlaylistError(true)
      console.error('Failed to fetch playlists:', error)
      // Handle fetching error state here
    } finally {
      setIsLoading(false)
    }
  }

  const fetchSchedules = async () => {
    try {
      setIsLoading(true)
      const response = await getSchedules() // Your API call to fetch items
      if (!response.error) {
        setIsLoading(false)
        setSchedules(response.data)
      } else {
        setIsLoading(false)
        console.error('API returned an error:', response.msg)
        // Handle API error state here, e.g., set an error message state and display it
      }
    } catch (error) {
      setIsLoading(false)
      setScheduleError(true)
      console.error('Failed to fetch schedules:', error)
      // Handle fetching error state here
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchFoldersAndFiles = async () => {
      try {
        setIsLoadingTree(true)
        const response = await getFoldersAndFiles()

        // console.log('RESPONSE', response)

        setContent(response)

        if (fileId) {
          const foundFile = findFileById([response.data], fileId)
          setSelectedFile(foundFile)
        } else {
          setSelectedFile(null)
        }

        const foundFolder = findFolderById([response.data], folderId)
        setSelectedFolder(foundFolder)
        setIsLoadingTree(false)
        setReloadContent(false)
      } catch (error) {
        // console.log(error);

        setIsLoadingTree(false)
        setRestricted(true)

        // console.error('Error fetching folders and files:', error);
      } finally {
        setIsLoadingTree(false)
      }
    }
    fetchFoldersAndFiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadContent])

  useEffect(() => {
    const fetchFoldersAndFiles = async () => {
      try {
        setIsLoadingTree(true)
        const response = await getFoldersAndFiles()

        // console.log('RESPONSE', response)

        setContent(response)

        if (fileId) {
          const foundFile = findFileById([response.data], fileId)
          setSelectedFile(foundFile)
        } else {
          setSelectedFile(null)
        }

        const foundFolder = findFolderById([response.data], folderId)
        setSelectedFolder(foundFolder)
        setIsLoadingTree(false)
      } catch (error) {
        // console.log(error);

        setIsLoadingTree(false)
        setRestricted(true)

        // console.error('Error fetching folders and files:', error);
      } finally {
        setIsLoadingTree(false)
      }
    }
    fetchFoldersAndFiles()
    fetchPlaylists()
    fetchSchedules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!content) {
      return
    }

    if (fileId) {
      const foundFile = findFileById([content.data], fileId)
      setSelectedFile(foundFile)
      if (!playlistError && !scheduleError) {
        const result = findPlaylistsAndSchedules(fileId, playlists, schedules)
        // console.log('RESULT', result)
        setExtraInfo(result)
      }
    } else {
      setSelectedFile(null)
    }

    const foundFolder = findFolderById([content.data], folderId)
    setSelectedFolder(foundFolder)
    setIsLoadingTree(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId, folderId, content])

  const findFolderById = (folders, folderId) => {
    let queue = [...folders]

    while (queue.length) {
      let current = queue.shift()

      if (current.id === folderId) {
        return current
      }

      if (current.children) {
        queue.push(...current.children)
      }
    }

    return null
  }

  const FileTreeView = ({ data, currentFolderId, currentFileId }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [items, setItems] = useState(['test'])
    const [textInputArgs] = useState({})
    const [menuArgs] = useState({})
    const inputRef = useRef()
    const scrollContainerRef = useRef()

    useEffect(() => {
      const fileNames = extractFileNames(data)
      setItems(fileNames)
    }, [data])

    const handleOpen = () => {
      setIsOpen(true)
    }

    const extractFileNames = (node, fileNames = []) => {
      if (node.is_folder) {
        // Traverse files in the current folder
        ; (node.files || []).forEach(file => {
          fileNames.push({ id: file.id, name: file.name })
        })

          // Recursively traverse child folders
          ; (node.children || []).forEach(child => {
            extractFileNames(child, fileNames)
          })
      }
      return fileNames
    }

    const handleAutocompleteSelect = selectedItems => {
      if (selectedItems.length > 0) {
        const selectedItem = selectedItems[0]
        handleItemClick(false, null, selectedItem.id)
      }
    }

    const navigate = useNavigate()

    const handleItemClick = (isFolder, folderId, fileId = null) => {
      navigate(`?folder=${folderId}&file_id=${fileId}`)
      setShowVideo(false)
    }

    const isCurrent = (node, isFolder) => {
      return isFolder ? node.id === currentFolderId : node.id === currentFileId
    }

    const renderTreeItems = (node, parentFolderId = null) => {
      const isFolder = node.is_folder
      if (isFolder) {
        return (
          <TreeView.Item
            id={node.id}
            key={node.id}
            defaultExpanded
            current={isCurrent(node, true)}
            onSelect={event => {
              event.preventDefault()
              handleItemClick(true, node.id)
            }}
          >
            <TreeView.LeadingVisual>
              <TreeView.DirectoryIcon />
            </TreeView.LeadingVisual>
            {node.name}
            <TreeView.SubTree>
              {(node.files || []).map(file => renderTreeItems(file, node.id))}
              {(node.children || []).map(child =>
                renderTreeItems(child, node.id)
              )}
            </TreeView.SubTree>
          </TreeView.Item>
        )
      } else {
        return (
          <TreeView.Item
            id={node.id}
            key={node.id}
            current={isCurrent(node, false)}
            onSelect={event => {
              event.preventDefault()
              handleItemClick(false, parentFolderId, node.id)
            }}
          >
            <TreeView.LeadingVisual>
              {/* Insert appropriate file icon based on the file type */}
              {/* {node.meta.type.toString()} */}
              {node.meta && node.meta.type.startsWith('image') && (
                <Icons.FileMedia />
              )}
              {node.meta && node.meta.type.startsWith('video') && (
                <Icons.Video />
              )}
            </TreeView.LeadingVisual>
            {node.name}
          </TreeView.Item>
        )
      }
    }

    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 14,
          height: '100%',
        }}
      >
        <AnchoredOverlay
          open={isOpen}
          onOpen={handleOpen}
          onClose={() => setIsOpen(false)}
          width='large'
          height='xsmall'
          focusTrapSettings={{
            initialFocusRef: inputRef,
          }}
          side='inside-top'
          renderAnchor={props => (
            <Button {...props} trailingVisual={Icons.SearchIcon}>
              Find files
            </Button>
          )}
        >
          <Autocomplete>
            <Box display='flex' flexDirection='column' height='100%'>
              <Box
                borderWidth={0}
                borderBottomWidth={1}
                borderColor='border.default'
                borderStyle='solid'
              >
                <Autocomplete.Input
                  ref={inputRef}
                  sx={{
                    display: 'flex',
                    border: '0',
                    paddingX: 3,
                    paddingY: 1,
                    boxShadow: 'none',
                    ':focus-within': {
                      border: '0',
                      boxShadow: 'none',
                    },
                  }}
                  {...textInputArgs}
                  size={textInputArgs.inputSize}
                  block
                />
              </Box>
              <Box overflow='auto' flexGrow={1}>
                <Autocomplete.Menu
                  items={items.map(item => ({
                    text: item.name,
                    id: item.id,
                  }))}
                  selectedItemIds={[]}
                  customScrollContainerRef={scrollContainerRef}
                  aria-labelledby='autocompleteLabel'
                  {...menuArgs}
                  onSelectedChange={handleAutocompleteSelect}
                />
              </Box>
            </Box>
          </Autocomplete>
        </AnchoredOverlay>
        <TreeView aria-label='Files'>{renderTreeItems(data)}</TreeView>
      </Box>
    )
  }

  const Preview = ({ file, folder }) => {
    if (!file && !folder) {
      return (
        <Card>
          <CardHeader></CardHeader>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '480px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                textAlign: 'center',
                color: 'fg.muted',
              }}
            >
              <Text>No file or folder selected or it doesn't exist</Text>
            </Box>
          </CardContent>
        </Card>
      )
    }

    return (
      <Card>
        <CardHeader>
          <CardTitle>
            <Tooltip
              aria-label={file ? file.name : folder.name}
              sx={{
                maxWidth: '100%',
              }}
            >
              <Truncate maxWidth={['100%']}>
                {file ? file.name : folder.name}
              </Truncate>
            </Tooltip>
          </CardTitle>
        </CardHeader>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          {file ? (
            <>
              {/* File details */}
              <Box>
                {file.meta &&
                  (file.meta.type.startsWith('video') ? (
                    <>
                      {!showVideo && (
                        <Tooltip
                          aria-label='Click to play video'
                          sx={{
                            maxWidth: '100%',
                          }}
                        >
                          <img
                            src={
                              'https://cdn.signgrid.io/file/' +
                              file.id +
                              '_thumbnail.jpg'
                            }
                            alt={file.name}
                            onClick={() => setShowVideo(!showVideo)}
                            style={{
                              maxWidth: '100%',
                              height: '200px',
                              cursor: 'pointer',
                              maxHeight: '200px',
                            }}
                          />
                        </Tooltip>
                      )}

                      {showVideo && (
                        <video
                          controls
                          style={{
                            maxWidth: '100%',
                            height: '200px',
                            cursor: 'pointer',
                            maxHeight: '200px',
                          }}
                          src={`https://cdn.signgrid.io/file/${file.id}-${file.name}`}
                        ></video>
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        'https://cdn.signgrid.io/file/' +
                        file.id +
                        '_thumbnail.jpg'
                      }
                      alt={file.name}
                      style={{
                        maxWidth: '100%',
                        height: '200px',
                        maxHeight: '200px',
                      }}
                    />
                  ))}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'fg.muted',
                    fontSize: 1,
                    gap: 3,
                    mt: 4,
                  }}
                >
                  <Text>
                    <Label>
                      Size:{' '}
                      {file.meta ? formatSize(file.meta.size) : 'Unknown size'}
                    </Label>
                  </Text>
                  <Text>
                    <Label>
                      Resolution:{' '}
                      {file.meta ? file.meta.width : 'Unknown width'}x
                      {file.meta ? file.meta.height : 'Unknown height'}px
                    </Label>
                  </Text>
                  <Text>
                    <Label>
                      Type: {file.meta ? file.meta.type : 'Unknown file'}
                    </Label>{' '}
                    {file.meta.type.startsWith('video') ? (
                      <Text
                        sx={{
                          ml: 1,
                        }}
                      >
                        <Label>Seconds: {file.meta.length}</Label>
                      </Text>
                    ) : (
                      ''
                    )}{' '}
                  </Text>
                  <Text
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 3,
                    }}
                  >
                    {extraInfo.length > 0 &&
                      extraInfo.map(info => (
                        <>
                          {/* <pre>{JSON.stringify(info.playlist)}</pre> */}
                          <Link
                            to={`/dashboard/playlists/editor?playlist_id=${info.playlist.id}`}
                          >
                            <Tooltip aria-label='Click to view playlist'>
                              <Label variant='done'>
                                <Icons.Play sx={{ mr: 1 }} />{' '}
                                {info.playlist.name.length > 25
                                  ? `${info.playlist.name.slice(0, 25)}...`
                                  : info.playlist.name}
                              </Label>
                            </Tooltip>
                          </Link>
                          {info.schedules.length > 0 &&
                            info.schedules.map(schedule => (
                              // <pre>{JSON.stringify(schedule)}</pre>
                              <Link
                                to={`/dashboard/schedules?schedule_id=${schedule.id}`}
                              >
                                <Tooltip aria-label='Click to view schedule'>
                                  <Label variant='sponsors'>
                                    <Icons.Calendar sx={{ mr: 1 }} />{' '}
                                    {schedule.name.length > 25
                                      ? `${schedule.name.slice(0, 25)}...`
                                      : schedule.name}
                                  </Label>
                                </Tooltip>
                              </Link>
                            ))}
                        </>
                      ))}
                  </Text>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  // backgroundColor: 'red',
                  mt: 1,
                  color: 'fg.muted',
                  justifyItems: 'space-between',
                  justifyContent: 'space-between',
                  alignContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    mt: 2,
                    color: 'fg.muted',
                  }}
                >
                  <LinkButton variant='default' as='a' href={file.path}>
                    Download
                  </LinkButton>
                  <Button
                    variant='danger'
                    onClick={() => {
                      setShowVideo(false)
                      setIsOpenDeleteFileDialog(true)
                    }}
                  >
                    Delete
                  </Button>
                </Box>
                {/* Add more file details here */}
              </Box>
            </>
          ) : (
            <>
              {/* Optionally list folder contents here */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'space-between',
                  justifyItems: 'space-between',
                  color: 'fg.muted',
                }}
              >
                {/* <Text>{folder.name}</Text> */}
                <Box></Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    mt: 2,
                    color: 'fg.muted',
                  }}
                >
                  <Button
                    variant='default'
                    onClick={() =>
                      handleOpenRenameFolderDialog(folder.name, folder.id)
                    }
                  >
                    Rename
                  </Button>

                  <Button
                    variant='danger'
                    onClick={() => {
                      setShowVideo(false)
                      setIsOpenDeleteFolderDialog(true)
                    }}
                  >
                    Delete folder
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    )
  }

  return (
    <Box sx={{}}>




<Box sx={{
        padding: 3
      }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Content</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Description sx={{
              fontSize: 1,
              color: 'fg.muted',
            }}>
      Simple content manager to organize your campaign media files.
</PageHeader.Description>
            <PageHeader.Actions>
            <Button
            disabled={restricted}
            variant='default'
            onClick={() => handleOpenNewFolderDialog()}
          >
            New folder
          </Button>
          <Button
            disabled={restricted}
            variant='primary'
            onClick={() => handleOpenUploadFileDialog()}
          >
            Upload file
          </Button>
            </PageHeader.Actions>
          </PageHeader>
        </Box>

      {restricted ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            justifyItems: 'center',
            position: 'relative',
            // backgroundColor: 'canvas.default',
            alignContent: 'center',
            alignItems: 'center',
            gap: 3,
            mt: 4,
          }}
        >
          <Text
            sx={{
              fontSize: 4,
              fontWeight: 'bold',
              // color: 'danger.',
              textAlign: 'center',
            }}
          >
            You don't have access to this content.
          </Text>
          <Text
            sx={{
              fontSize: 1,
              color: 'fg.muted',
            }}
          >
            <Icons.Alert /> Insufficient permissions to manage content.
          </Text>
          {/* <LinkButton variant="primary" as="a" href="/dashboard/devices">
                Retry
              </LinkButton> */}
          {/* <LinkButton variant="invisible" href="#">
                Installation instructions
              </LinkButton> */}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            width: '100%',
            height: '100%',
            marginTop: 4,
            gap: 3,
            // backgroundColor: 'canvas.default',
          }}
        >
          <Box
            sx={{
              width: ['100%', '50%'],
              height: ['50%', '100%'],
              overflow: 'auto',
            }}
          >
            {!content && isLoadingTree && !restricted && (
              <Card>
                <CardHeader>
                  <CardTitle></CardTitle>
                  <CardDescription></CardDescription>
                </CardHeader>
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // height: '382px',
                      height: '450px',
                    }}
                  >
                    <Spinner />
                  </Box>
                </CardContent>
                <CardFooter></CardFooter>
              </Card>
            )}
            {content && !isLoadingTree && !restricted && (
              <Box
                sx={{
                  // height: '420px',
                  height: '510px',
                  // backgroundColor: 'canvas.default',
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'border.default',
                  padding: 3,
                  overflow: 'auto',
                }}
              >
                <FileTreeView
                  data={content?.data}
                  currentFolderId={folderId}
                  currentFileId={fileId}
                />
              </Box>
            )}
          </Box>

          <Box sx={{ width: ['100%', '50%'], height: ['50%', '485px'] }}>
            <Preview file={selectedFile} folder={selectedFolder} />
          </Box>
        </Box>
      )}
      {isOpenNewFolderDialog && (
        <Dialog
          isOpen={isOpenNewFolderDialog}
          onDismiss={onDialogCloseNewFolder}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Create new folder</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' color='fg.muted' fontSize={1}>
              Folders let you better organize your media files.
            </Text>
            <Box
              mt={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
              }}
            >
              <FormControl required>
                <FormControl.Label htmlFor='name'>Name</FormControl.Label>
                <TextInput
                  value={value}
                  onChange={handleInputChange}
                  id='name'
                  name='name'
                  placeholder='Name'
                  sx={{
                    width: '100%',
                  }}
                />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'tooLong' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be longer than 255 characters
                  </FormControl.Validation>
                )}
                {validationResult === 'tooShort' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be less than 3 characters
                  </FormControl.Validation>
                )}
                {validationResult === 'validName' && (
                  <FormControl.Validation variant='success'>
                    Valid name
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a name (3 to 255 characters).
                </FormControl.Caption>
              </FormControl>

              <FormControl required>
                <FormControl.Label htmlFor='parent_folder'>
                  Parent folder
                </FormControl.Label>
                <Select
                  value={selectedParent}
                  onChange={handleParentSelected}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Select.Option value='choose_parent_id' disabled>
                    Choose parent folder
                  </Select.Option>
                  {folderOptions}
                </Select>
                {selectValidationResult === 'noSelection' && (
                  <FormControl.Validation variant='error'>
                    Please select a parent folder
                  </FormControl.Validation>
                )}
                {selectValidationResult === 'validSelection' && (
                  <FormControl.Validation variant='success'>
                    Parent ID selected
                  </FormControl.Validation>
                )}
              </FormControl>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogCloseNewFolder}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleSubmitNewFolder()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
              {/* <Button onClick={() => {handleSubmitNewFolder()}} variant="primary">Submit</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}

      {isOpenUploadFileDialog && (
        <Dialog
          isOpen={isOpenUploadFileDialog}
          onDismiss={onDialogCloseUploadFileDialog}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Upload file</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' color='fg.muted' fontSize={1}>
              Maximum file size supported is 100 MB.
            </Text>
            <Box
              mt={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
              }}
            >
              <FormControl required>
                <FormControl.Label htmlFor='file'>File</FormControl.Label>
                {/* <input type="file" onChange={handleFileChange} />  */}
                <TextInput
                  width='100%'
                  size='medium'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  type='file'
                  onChange={handleFileChange}
                />
                {validationResultFile === 'emptyFile' && (
                  <FormControl.Validation variant='error'>
                    Please upload a file
                  </FormControl.Validation>
                )}
                {validationResultFile === 'validFile' && (
                  <FormControl.Validation variant='success'>
                    Valid file
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Allowed formats: JPG, JPEG, PNG, BMP, GIF, MP4, MOV, AVI, FLV,
                  MKV
                </FormControl.Caption>
              </FormControl>
              <FormControl required>
                <FormControl.Label htmlFor='parent_folder'>
                  Parent folder
                </FormControl.Label>
                <Select
                  value={selectedParent}
                  onChange={handleParentSelected}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Select.Option value='choose_parent_id' disabled>
                    Choose parent folder
                  </Select.Option>
                  {folderOptions}
                </Select>
                {selectValidationResult === 'noSelection' && (
                  <FormControl.Validation variant='error'>
                    Please select a parent folder
                  </FormControl.Validation>
                )}
                {selectValidationResult === 'validSelection' && (
                  <FormControl.Validation variant='success'>
                    Parent ID selected
                  </FormControl.Validation>
                )}
              </FormControl>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogCloseUploadFileDialog}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleSubmitUploadFile()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}

      {isOpenRenameFolderDialog && (
        <Dialog
          isOpen={isOpenRenameFolderDialog}
          onDismiss={onDialogCloseRenameFolderDialog}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Rename folder</Dialog.Header>
          <Box p={3}>
            <Text
              id='dialog-label'
              sx={{
                color: 'fg.muted',
                fontSize: 1,
              }}
            >
              Change folder name
            </Text>
            <Box mt={3}>
              <FormControl required>
                <FormControl.Label htmlFor='name'>Name</FormControl.Label>
                <TextInput
                  value={value}
                  onChange={handleInputChange}
                  id='name'
                  name='name'
                  placeholder='Name'
                  sx={{
                    width: '100%',
                  }}
                />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'tooLong' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be longer than 255 characters
                  </FormControl.Validation>
                )}
                {validationResult === 'tooShort' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be less than 3 characters
                  </FormControl.Validation>
                )}
                {validationResult === 'validName' && (
                  <FormControl.Validation variant='success'>
                    Valid name
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a name (3 to 255 characters).
                </FormControl.Caption>
              </FormControl>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogCloseRenameFolderDialog}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleSubmitRenameFolder()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}

      {isOpenDeleteFolderDialog && (
        <Dialog
          isOpen={isOpenDeleteFolderDialog}
          onDismiss={onDialogCloseDeleteFolderDialog}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Delete folder</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' color='fg.muted' fontSize={1}>
              This action cannot be undone. Are you sure you want to delete this
              folder and it's content?
            </Text>
            <Box mt={3}></Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogCloseDeleteFolderDialog}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='danger'
                disabled={isLoading}
                onClick={() => {
                  handleSubmitDeleteFolder()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '120px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Delete folder</Text>
                )}
              </Button>
              {/* <Button onClick={() => {handleSubmitDeleteFolder()}} variant="danger">Delete folder</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}

      {isOpenDeleteFileDialog && (
        <Dialog
          isOpen={isOpenDeleteFileDialog}
          onDismiss={onDialogCloseDeleteFileDialog}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Delete file</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' color='fg.muted'>
              This action can't be undone. Are you sure you want to delete this
              file?
            </Text>
            <Box mt={3}></Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogCloseDeleteFileDialog}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='danger'
                disabled={isLoading}
                onClick={() => {
                  handleSubmitDeleteFile()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '120px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Delete file</Text>
                )}
              </Button>
              {/* <Button onClick={() => {handleSubmitDeleteFile()}} variant="danger">Delete file</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}
    </Box>
  )
}
