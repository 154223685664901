import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import {
  Text,
  Box,
  Spinner,
  Truncate,
  Tooltip,
  PageHeader,
  Button,
  Label,
  Dialog,
  TextInput,
  FormControl,
  ActionMenu,
  ActionList,
} from '@primer/react'
import { Hidden } from '@primer/react/drafts'
import { DataTable, Table } from '@primer/react/drafts'
import { Icons } from '@/components/icons'
import { getDeviceGroups, createDeviceGroup, getSchedules, getPlaylists } from '@/lib/calls'
import { SearchIcon } from '@primer/octicons-react'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

export function DeviceGroupsPage() {
  const [pageIndex, setPageIndex] = useState(0)
  const pageSize = 10
  const [restricted, setRestricted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [deviceGroups, setDeviceGroups] = useState([])
  const [schedules, setSchedules] = useState([])
  const [playlists, setPlaylists] = useState([])
  const [filteredGroups, setFilteredGroups] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [isOpenNewGroupDialog, setIsOpenNewGroupDialog] = useState(false)
  const [newGroupName, setNewGroupName] = useState('')
  const [sortCriteria, setSortCriteria] = useState('recently_created')

  const buttonRef = useRef()

  const handleOpenNewGroupDialog = () => {
    setNewGroupName('')
    setIsOpenNewGroupDialog(true)
  }

  const onDialogCloseNewGroupDialog = useCallback(() => {
    if (!isLoading) {
      setIsOpenNewGroupDialog(false)
    }
  }, [isLoading])

  const handleInputChange = e => {
    setNewGroupName(e.currentTarget.value)
  }

  const validateGroupName = useCallback(() => {
    if (newGroupName.length === 0) return 'empty'
    if (newGroupName.length > 255) return 'tooLong'
    if (newGroupName.length < 3) return 'tooShort'
    return 'validName'
  }, [newGroupName])

  const handleSubmitNewGroup = async () => {
    if (validateGroupName() === 'validName') {
      try {
        setIsLoading(true)
        await createDeviceGroup(newGroupName)
        toast.custom(t => <CustomToast message='Group created' type='success' />)
        onDialogCloseNewGroupDialog()
        fetchDeviceGroups()
      } catch (error) {
        toast.custom(t => <CustomToast message='Error creating group' type='error' />)
        console.error('Error creating group:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleSearch = useCallback((event) => {
    const query = event.target.value.toLowerCase()
    setSearchQuery(query)
    const filtered = deviceGroups.filter(group => 
      group.name.toLowerCase().includes(query)
    )
    setFilteredGroups(filtered)
    setPageIndex(0)
  }, [deviceGroups])

  const handleSort = useCallback((criteria) => {
    setSortCriteria(criteria)
    const sorted = [...filteredGroups].sort((a, b) => {
      switch (criteria) {
        case 'recently_created':
          return new Date(b.created_at) - new Date(a.created_at)
        case 'least_recently_created':
          return new Date(a.created_at) - new Date(b.created_at)
        case 'recently_updated':
          return new Date(b.updated_at) - new Date(a.updated_at)
        case 'least_recently_updated':
          return new Date(a.updated_at) - new Date(b.updated_at)
        default:
          return 0
      }
    })
    setFilteredGroups(sorted)
    setPageIndex(0)
  }, [filteredGroups])

  const getSortText = useCallback(() => {
    switch (sortCriteria) {
      case 'recently_created': return 'Recently created'
      case 'least_recently_created': return 'Least recently created'
      case 'recently_updated': return 'Recently updated'
      case 'least_recently_updated': return 'Least recently updated'
      default: return 'Recently created'
    }
  }, [sortCriteria])

  const fetchDeviceGroups = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await getDeviceGroups()
      if (!response.error) {
        const sortedDeviceGroups = response.data.sort((a, b) => 
          new Date(b.created_at) - new Date(a.created_at)
        )
        setDeviceGroups(sortedDeviceGroups)
        setFilteredGroups(sortedDeviceGroups)
        setFetchError(false)
      } else {
        console.error('API returned an error:', response.msg)
        setFetchError(true)
      }
    } catch (error) {
      console.error(error)
      setFetchError(true)
      if (error.response?.status === 403) {
        setRestricted(true)
      }
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchDeviceGroups()
    getSchedules().then(response => setSchedules(response.data)).catch(console.error)
    getPlaylists().then(response => setPlaylists(response.data)).catch(console.error)
  }, [fetchDeviceGroups])

  const findScheduleAndPlaylist = useCallback((deviceGroupId) => {
    const schedule = schedules.find(s => s.group_id === deviceGroupId)
    if (!schedule) return null
    const playlistEntry = playlists.find(p => p.playlist.id === schedule.playlist_id)
    return {
      schedule,
      playlist: playlistEntry ? playlistEntry.playlist : null,
    }
  }, [schedules, playlists])

  const renderContent = () => {
    if (isLoading) return <LoadingSpinner />
    if (fetchError && restricted) return <RestrictedAccessMessage />
    if (fetchError && !restricted) return <FetchErrorMessage />
  
    return (
      <>
        <SearchAndSortSection 
          handleSearch={handleSearch} 
          handleSort={handleSort} 
          getSortText={getSortText}
          searchQuery={searchQuery}
        />
        {deviceGroups.length === 0 ? (
          <NoDeviceGroupsMessage />
        ) : filteredGroups.length === 0 ? (
          <NoSearchResultsMessage />
        ) : (
          <DeviceGroupsTable 
            groups={filteredGroups} 
            pageIndex={pageIndex} 
            setPageIndex={setPageIndex} 
            pageSize={pageSize} 
            findScheduleAndPlaylist={findScheduleAndPlaylist}
          />
        )}
      </>
    )
  }

  return (
    <Box sx={{ padding: 3 }}>
      <PageHeader>
        <PageHeader.TitleArea>
          <PageHeader.Title as="h2">Device groups</PageHeader.Title>
        </PageHeader.TitleArea>
        <PageHeader.Description sx={{ fontSize: 1, color: 'fg.muted' }}>
          Cluster devices through groups to trigger updates more efficiently.
        </PageHeader.Description>
        <PageHeader.Actions>
          <Hidden when={['narrow']}>
            <Button variant='primary' onClick={handleOpenNewGroupDialog}>
              New device group
            </Button>
          </Hidden>
          <Hidden when={['regular', 'wide']}>
            <Button variant='primary' onClick={handleOpenNewGroupDialog}>
              New
            </Button>
          </Hidden>
        </PageHeader.Actions>
      </PageHeader>

      <Box sx={{ marginTop: 4 }}>
        {renderContent()}
      </Box>

      <NewGroupDialog
        isOpen={isOpenNewGroupDialog}
        onClose={onDialogCloseNewGroupDialog}
        buttonRef={buttonRef}
        newGroupName={newGroupName}
        handleInputChange={handleInputChange}
        validateGroupName={validateGroupName}
        handleSubmitNewGroup={handleSubmitNewGroup}
        isLoading={isLoading}
      />
    </Box>
  )
}

const LoadingSpinner = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '420px' }}>
    <Spinner />
  </Box>
)

const RestrictedAccessMessage = () => (
  <MessageBox
    title="You don't have access to this content."
    description={<><Icons.Alert /> Insufficient permissions.</>}
  />
)

const FetchErrorMessage = () => (
  <MessageBox
    title="Failed to gather information."
    description="Please try again later."
  />
)

const NoDeviceGroupsMessage = () => (
  <MessageBox
    title="No device group found."
    description="You need to create one first from the devices page."
  />
)

const NoSearchResultsMessage = () => (
  <MessageBox
    title="No device group found."
    description="Please try a different query."
  />
)

const MessageBox = ({ title, description }) => (
  <Box sx={{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 3,
  }}>
    <Text sx={{ fontSize: 4, fontWeight: 'bold', textAlign: 'center' }}>{title}</Text>
    <Text sx={{ fontSize: 1, color: 'fg.muted' }}>{description}</Text>
  </Box>
)

const SearchAndSortSection = ({ handleSearch, handleSort, getSortText, searchQuery }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginBottom: 3 }}>
    <FormControl>
      <FormControl.Label visuallyHidden>Filter</FormControl.Label>
      <TextInput
        leadingVisual={SearchIcon}
        trailingVisual={searchQuery ? 
          <Button variant="invisible" onClick={() => handleSearch({ target: { value: '' } })}>
            Clear
          </Button> : 
          null
        }
        placeholder='Filter groups'
        onChange={handleSearch}
        value={searchQuery}
        block
      />
    </FormControl>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ActionMenu>
        <ActionMenu.Button>Sort: {getSortText()}</ActionMenu.Button>
        <ActionMenu.Overlay>
          <ActionList selectionVariant='single'>
            <ActionList.Item onSelect={() => handleSort('recently_created')}>Recently created</ActionList.Item>
            <ActionList.Item onSelect={() => handleSort('least_recently_created')}>Least recently created</ActionList.Item>
            <ActionList.Item onSelect={() => handleSort('recently_updated')}>Recently updated</ActionList.Item>
            <ActionList.Item onSelect={() => handleSort('least_recently_updated')}>Least recently updated</ActionList.Item>
          </ActionList>
        </ActionMenu.Overlay>
      </ActionMenu>
    </Box>
  </Box>
)

const DeviceGroupsTable = ({ groups, pageIndex, setPageIndex, pageSize, findScheduleAndPlaylist }) => (
  <Table.Container>
    <Table.Title as='h2' id='deviceGroups'>Device groups</Table.Title>
    <Table.Subtitle as='p' id='deviceGroups-subtitle' />
    <DataTable
      aria-labelledby='deviceGroups'
      aria-describedby='deviceGroups-subtitle'
      data={groups.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)}
      columns={[
        {
          header: 'Name',
          field: 'name',
          renderCell: row => (
            <Tooltip aria-label={row.name}>
              <Link to={`/dashboard/devices?device_group_id=${row.id}`}>
                <Button variant='invisible'>
                  {row.name.length > 25 ? `${row.name.slice(0, 25)}...` : row.name}
                </Button>
              </Link>
            </Tooltip>
          ),
        },
        {
          header: 'Devices',
          field: 'devices',
          renderCell: row => (
            <Label variant='accent' sx={{ maxWidth: '77px' }}>
              <Icons.Devices sx={{ mr: 1 }} />
              <Truncate>{row.devices ? row.devices.length : 0}</Truncate>
            </Label>
          ),
        },
        {
          header: 'Schedule',
          field: 'schedule',
          renderCell: row => {
            const scheduleAndPlaylist = findScheduleAndPlaylist(row.id)
            return scheduleAndPlaylist?.schedule ? (
              <Link to={`/dashboard/schedules?schedule_id=${scheduleAndPlaylist.schedule.id}`}>
                <Tooltip aria-label='Click to view schedule'>
                  <Label variant='sponsors'>
                    <Icons.Calendar sx={{ mr: 1 }} />
                    {scheduleAndPlaylist.schedule.name.length > 25
                      ? `${scheduleAndPlaylist.schedule.name.slice(0, 25)}...`
                      : scheduleAndPlaylist.schedule.name}
                  </Label>
                </Tooltip>
              </Link>
            ) : null
          },
        },
        {
          header: 'Playlist',
          field: 'playlist',
          renderCell: row => {
            const scheduleAndPlaylist = findScheduleAndPlaylist(row.id)
            return scheduleAndPlaylist?.playlist ? (
              <Link to={`/dashboard/playlists/editor?playlist_id=${scheduleAndPlaylist.playlist.id}`}>
                <Tooltip aria-label='Click to view playlist'>
                  <Label variant='done'>
                    <Icons.Play sx={{ mr: 1 }} />
                    {scheduleAndPlaylist.playlist.name.length > 25
                      ? `${scheduleAndPlaylist.playlist.name.slice(0, 25)}...`
                      : scheduleAndPlaylist.playlist.name}
                  </Label>
                </Tooltip>
              </Link>
            ) : null
          },
        },
      ]}
    />
    <Table.Pagination
      aria-label="Pagination for Devices"
      pageSize={pageSize}
      totalCount={groups.length}
      pageIndex={pageIndex}
      onChange={({ pageIndex }) => setPageIndex(pageIndex)}
    />
  </Table.Container>
)

const NewGroupDialog = ({ isOpen, onClose, buttonRef, newGroupName, handleInputChange, validateGroupName, handleSubmitNewGroup, isLoading }) => (
  <Dialog isOpen={isOpen} onDismiss={onClose} returnFocusRef={buttonRef} aria-labelledby='dialog-label'>
    <Dialog.Header>Create new device group</Dialog.Header>
    <Box p={3}>
      <Text id='dialog-label' color={'fg.muted'} fontSize={1}>
        It is required to create groups to be able to set schedules.
      </Text>
      <Box mt={3}>
        <FormControl required>
          <FormControl.Label>Name</FormControl.Label>
          <TextInput block value={newGroupName} onChange={handleInputChange} />
          {validateGroupName() === 'empty' && (
            <FormControl.Validation variant='error'>Name cannot be empty</FormControl.Validation>
          )}
          {validateGroupName() === 'tooLong' && (
            <FormControl.Validation variant='error'>Name cannot be longer than 255 characters</FormControl.Validation>
          )}
          {validateGroupName() === 'tooShort' && (
            <FormControl.Validation variant='error'>Name cannot be less than 3 characters</FormControl.Validation>
          )}
          {validateGroupName() === 'validName' && (
            <FormControl.Validation variant='success'>Valid name</FormControl.Validation>
          )}
          <FormControl.Caption>
            Please enter a name (3 to 255 characters).
          </FormControl.Caption>
        </FormControl>
      </Box>
      <Box display='flex' mt={3} justifyContent='flex-end'>
        <Button
          onClick={onClose}
          sx={{ mr: 1 }}
          variant='invisible'
        >
          Cancel
        </Button>
        <Button
          variant='primary'
          disabled={isLoading || validateGroupName() !== 'validName'}
          onClick={handleSubmitNewGroup}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '65px',
          }}
        >
          {isLoading ? (
            <Spinner size='small' />
          ) : (
            <Text>Submit</Text>
          )}
        </Button>
      </Box>
    </Box>
  </Dialog>
)
