import { Box } from "@primer/react";

export default function Logo({ size, variant }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
        overflow: "hidden", // Ensures the image does not exceed the box
      }}
    >
      {variant === "white" && (
        <img
          src="/SignGrid-whiteV.png"
          alt="logo"
          style={{
            maxWidth: "100%", // Limits the width to the box size
            maxHeight: "100%", // Limits the height to the box size
            objectFit: "contain", // Adjusts the image to fit within the dimensions without stretching
          }}
        />
      )}
      {variant === "color" && (
        <img
          src="/SignGrid-colorV.png"
          alt="logo"
          style={{
            maxWidth: "100%", // Limits the width to the box size
            maxHeight: "100%", // Limits the height to the box size
            objectFit: "contain", // Adjusts the image to fit within the dimensions without stretching
          }}
        />
      )}
    </Box>
  );
}
