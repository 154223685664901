let API_ROUTE
let COOKIE_DOMAIN

if (process.env.NODE_ENV === 'development') {
  API_ROUTE = 'http://localhost:42069/api/v1'
  COOKIE_DOMAIN = 'localhost'
} else if (process.env.NODE_ENV === 'staging') {
  API_ROUTE = 'https://staging-api.signgrid.io/api/v1'
  COOKIE_DOMAIN = '.signgrid.io'
} else {
  API_ROUTE = 'https://api.signgrid.io/api/v1'
  COOKIE_DOMAIN = '.signgrid.io'
}

export { API_ROUTE, COOKIE_DOMAIN }
