import { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  TextInput,
  Spinner,
  Box,
  Text,
} from '@primer/react'
import Logo from '@/components/ui/logo'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'
import { Toaster } from 'react-hot-toast'
import { resetPassword } from '@/lib/calls'

export function ResetPasswordPage() {
  const [isLoading, setIsLoading] = useState(false)
  // const navigate = useNavigate()
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const tokenParam = query.get('token')

  // console.log(query)
  // console.log(tokenParam)

  // alert(tokenParam)

  const [valuePassword, setValuePassword] = useState('')
  const [validationResultPassword, setValidationResultPassword] = useState('')
  const [valueConfirmPassword, setValueConfirmPassword] = useState('')
  const [validationResultConfirmPassword, setValidationResultConfirmPassword] =
    useState('')
  const [startedTypingPassword, setStartedTypingPassword] = useState(false)
  const [startedTypingConfirmPassword, setStartedTypingConfirmPassword] =
    useState(false)

  const handleInputChangeConfirmPassword = e => {
    setStartedTypingConfirmPassword(true)
    setValueConfirmPassword(e.currentTarget.value)
  }

  const handleInputChangePassword = e => {
    setStartedTypingPassword(true)
    setValuePassword(e.currentTarget.value)
  }

  useEffect(() => {
    if (startedTypingPassword) {
      if (startedTypingPassword && valuePassword.length === 0) {
        setValidationResultPassword('empty')
      } else if (startedTypingPassword && valuePassword.length < 16) {
        setValidationResultPassword('tooShort')
      } else if (startedTypingPassword && valuePassword.length > 60) {
        setValidationResultPassword('tooLong')
      } else {
        setValidationResultPassword('validPassword')
      }
    }
  }, [valuePassword, startedTypingPassword])

  useEffect(() => {
    if (startedTypingConfirmPassword) {
      if (startedTypingConfirmPassword && valueConfirmPassword.length === 0) {
        setValidationResultConfirmPassword('empty')
      } else if (
        startedTypingConfirmPassword &&
        valueConfirmPassword.length < 16
      ) {
        setValidationResultConfirmPassword('tooShort')
      } else if (
        startedTypingConfirmPassword &&
        valueConfirmPassword.length > 60
      ) {
        setValidationResultConfirmPassword('tooLong')
      } else if (valueConfirmPassword !== valuePassword) {
        setValidationResultConfirmPassword('passwordMismatch')
      } else {
        setValidationResultConfirmPassword('passwordMatch')
      }
    }
  }, [valueConfirmPassword, startedTypingConfirmPassword, valuePassword])

  if (tokenParam) {
    if (tokenParam !== '') {
      console.log('token is not empty')
    }
  } else {
    window.location.href = '/login'
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      // console.log('event target', event.target)
      handleSubmitResetPassword()
    }
  }

  const handleSubmitResetPassword = async () => {
    try {
      if (valuePassword === '') {
        setValidationResultPassword('empty')
      }
      if (valueConfirmPassword === '') {
        setValidationResultConfirmPassword('empty')
      }
      if (
        validationResultPassword === 'validPassword' &&
        validationResultConfirmPassword === 'passwordMatch'
      ) {
        setIsLoading(true)
        await resetPassword(valuePassword, tokenParam)

        toast.custom(t => (
          <CustomToast message='Password changed' type='success' />
        ))
      }
    } catch (error) {
      console.error(error)
      toast.custom(t => (
        <CustomToast message='Error changing password' type='error' />
      ))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          // backgroundColor: 'canvas.default',
        }}
      >
        <Logo variant='color' size={32} />
        <Text fontSize={4}>Reset password</Text>
        <Text fontSize={2} sx={{ color: 'fg.muted' }}>
          Enter your new password
        </Text>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            mt: 4,
          }}
        >
          <FormControl>
            <FormControl.Label htmlFor='password'>Password *</FormControl.Label>
            <TextInput
              id='password'
              type='password'
              placeholder='Password'
              value={valuePassword}
              onChange={handleInputChangePassword}
              onKeyPress={handleKeyPress}
              sx={{
                width: '300px',
              }}
            />
            <FormControl.Caption>
              Password must be at least 16 characters long
            </FormControl.Caption>

            {validationResultPassword === 'empty' && (
              <FormControl.Validation variant='error'>
                Password cannot be empty
              </FormControl.Validation>
            )}
            {validationResultPassword === 'tooShort' && (
              <FormControl.Validation variant='error'>
                Password too short
              </FormControl.Validation>
            )}
            {validationResultPassword === 'tooLong' && (
              <FormControl.Validation variant='error'>
                Password too long
              </FormControl.Validation>
            )}
            {validationResultPassword === 'validPassword' && (
              <FormControl.Validation variant='success'>
                Valid password
              </FormControl.Validation>
            )}
          </FormControl>
          <FormControl>
            <FormControl.Label htmlFor='confirm_password'>
              Confirm password *
            </FormControl.Label>
            <TextInput
              type='password'
              placeholder='Password'
              value={valueConfirmPassword}
              onChange={handleInputChangeConfirmPassword}
              onKeyPress={handleKeyPress}
              sx={{
                width: '300px',
              }}
            />
            {validationResultConfirmPassword === 'empty' && (
              <FormControl.Validation variant='error'>
                Password cannot be empty
              </FormControl.Validation>
            )}
            {validationResultConfirmPassword === 'tooShort' && (
              <FormControl.Validation variant='error'>
                Password too short
              </FormControl.Validation>
            )}
            {validationResultConfirmPassword === 'tooLong' && (
              <FormControl.Validation variant='error'>
                Password too long
              </FormControl.Validation>
            )}
            {validationResultConfirmPassword === 'passwordMismatch' && (
              <FormControl.Validation variant='error'>
                Password mismatch
              </FormControl.Validation>
            )}
            {validationResultConfirmPassword === 'passwordMatch' && (
              <FormControl.Validation variant='success'>
                Passwords match
              </FormControl.Validation>
            )}
          </FormControl>
          <Button
            variant='primary'
            size='medium'
            sx={{ width: '300px' }}
            onClick={() => handleSubmitResetPassword()}
          >
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  // width: '47px',
                  // width: '300px',
                }}
              >
                <Spinner size='small' />
              </Box>
            ) : (
              <Text>Reset password</Text>
            )}
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
          }}
        >
          <Text
            sx={{
              color: 'accent.fg',
              cursor: 'pointer',
              mt: 3,
            }}
            fontSize='small'
            onClick={() => window.location.replace('/login')}
          >
            Go back to login
          </Text>
        </Box>
      </Box>
      <Toaster />
    </>
  )
}
