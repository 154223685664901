import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import {
  Text,
  Label,
  Spinner,
  Box,
  useConfirm,
  PageHeader,
  Button,
  FormControl,
  ActionList,
  TextInput,
  Select,
  TextInputWithTokens,
} from '@primer/react';
import { SelectPanel } from '@primer/react/drafts';
import { debounce } from 'lodash';
import { FixedSizeList as List } from 'react-window';
import { Icons } from '@/components/icons';
import {
  getDevices,
  deleteAlert,
  getAlerts,
  createAlert,
  getAlert,
} from '@/lib/calls';
import { toast } from 'react-hot-toast';
import { CustomToast } from './custom-toast';

export function AlertsPage() {
  const handleSubmitDeleteAlert = async alertId => {
    try {
      await deleteAlert(alertId);
      toast.custom(t => <CustomToast message='Alert deleted' type='success' />);
    } catch (error) {
      toast.custom(t => (
        <CustomToast message='Error could not delete alert' type='error' />
      ));
      console.error('Error deleting alert:', error);
    } finally {
      setReloadPage(prev => !prev);
      setShowAlertDetails(false);
      setSelectedAlert(null);
    }
  };

  const [devices, setDevices] = useState([]);
  const [reloadPage, setReloadPage] = useState(false);

  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
  const buttonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const title = <Text sx={{ color: 'fg.default' }}>Select devices</Text>;
  const notFound = <Text sx={{ color: 'fg.default' }}>No devices found</Text>;

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onClearSelection = () => {
    setSelectedDeviceIds([]);
  };

  const onDeviceSelect = deviceId => {
    if (!selectedDeviceIds.includes(deviceId))
      setSelectedDeviceIds([...selectedDeviceIds, deviceId]);
    else setSelectedDeviceIds(selectedDeviceIds.filter(id => id !== deviceId));
  };

  const [filteredDevices, setFilteredDevices] = useState([]);

  const [query, setQuery] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(query => {
      if (query === '') {
        setFilteredDevices(devices);
      } else {
        setFilteredDevices(
          devices
            .map(device => {
              if (
                device.device_name.toLowerCase().startsWith(query) ||
                device.name.toLowerCase().startsWith(query)
              )
                return { priority: 1, device };
              else if (
                device.device_name.toLowerCase().includes(query) ||
                device.name.toLowerCase().includes(query)
              )
                return { priority: 2, device };
              else return { priority: -1, device };
            })
            .filter(result => result.priority > 0)
            .sort((a, b) => a.priority - b.priority)
            .map(result => result.device)
        );
      }
    }, 300),
    [devices]
  );

  const onSearchInputChange = event => {
    const query = event.currentTarget.value.toLowerCase();
    setQuery(query);
    debouncedSearch(query);
  };

  const sortingFn = (itemA, itemB) => {
    const initialSelectedIds = devices.map(item => item.id);
    const includesA = initialSelectedIds.includes(itemA.id);
    const includesB = initialSelectedIds.includes(itemB.id);
    if (includesA && includesB) return 0;
    else if (includesA) return -1;
    else if (includesB) return 1;
    else return 0;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const itemsToShow = useMemo(() => (query ? filteredDevices : [...devices].sort(sortingFn)), [query, filteredDevices, devices]);

  const [tokens, setTokens] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [restricted, setRestricted] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [isLoadingAlerts, setIsLoadingAlerts] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [nameValue, setNameValue] = useState('');
  const [validationResult, setValidationResult] = useState('empty');
  const [validationEmailsResult, setValidationEmailsResult] = useState('empty');
  const [validationDeviceIdsResult, setValidationDeviceIdsResult] = useState('empty');

  const isValidEmail = email => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const [alertTypeValue, setAlertTypeValue] = useState('choose_alert_type');
  const [validationAlertTypeResult, setValidationAlertTypeResult] = useState('empty');

  const [notifyAfterValue, setNotifyAfterValue] = useState('choose_notify_after');
  const [validationNotifyAfterResult, setValidationNotifyAfterResult] = useState('empty');

  const [isLoadingAlert, setIsLoadingAlert] = useState(false);
  const [selectedAlertId, setSelectedAlertId] = useState('');
  const [showAlertDetails, setShowAlertDetails] = useState(false);

  const handleFetchAlert = async alertId => {
    try {
      setIsLoadingAlert(true);

      const response = await getAlert(alertId);
      if (response.data && response.data.logs) {
        response.data.logs.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      }

      setSelectedAlert(response.data);
      setIsLoadingAlert(false);
    } catch (error) {
      setIsLoadingAlert(false);
      toast.custom(t => (
        <CustomToast message='Failed to fetch alert data' type='error' />
      ));
      console.error('Error fetching alert rule:', error);
    }
  };

  const handleSubmitCreateAlert = async () => {
    if (
      validationResult !== 'validName' ||
      validationEmailsResult !== 'valid' ||
      validationDeviceIdsResult !== 'valid' ||
      validationAlertTypeResult !== 'valid' ||
      validationNotifyAfterResult !== 'valid'
    ) {
      return;
    } else {
      try {
        setIsLoading(true);

        await createAlert(
          nameValue,
          alertTypeValue,
          parseInt(notifyAfterValue),
          selectedDeviceIds,
          convertTokensToArray(tokens)
        );

        setIsLoading(false);
        toast.custom(t => (
          <CustomToast message='Alert created' type='success' />
        ));

        setNameValue('');
        setValidationResult('empty');
        setSelectedDeviceIds([]);
        setAlertTypeValue('choose_alert_type');
        setValidationAlertTypeResult('empty');
        setNotifyAfterValue('choose_notify_after');
        setValidationNotifyAfterResult('empty');
        setTokens([]);
        setInputValue('');
        setValidationEmailsResult('empty');
        setValidationDeviceIdsResult('empty');
      } catch (error) {
        setIsLoading(false);
        toast.custom(t => (
          <CustomToast message='Error could not create alert' type='error' />
        ));
        console.error('Error creating alert rule:', error);
      } finally {
        setIsLoading(false);
        setReloadPage(prev => !prev);
      }
    }
  };

  useEffect(() => {
    const fetchDevices = async () => {
      setIsLoadingDevices(true);
      try {
        const response = await getDevices();
        setDevices(response.data);
        setFilteredDevices(response.data);
        setIsLoadingDevices(false);
      } catch (error) {
        console.error('Error fetching devices', error);
        if (error?.response?.status === 403) {
          setRestricted(true);
        }
        setFetchError(error);
      } finally {
        setIsLoadingDevices(false);
      }
    };

    const fetchAlerts = async () => {
      setIsLoadingAlerts(true);
      try {
        const response = await getAlerts();
        const sortedAlerts = response.data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setAlerts(sortedAlerts);
        setIsLoadingAlerts(false);
      } catch (error) {
        console.error('Error fetching alerts', error);
        if (error?.response?.status === 403) {
          setRestricted(true);
        }
        setFetchError(error);
      } finally {
        setIsLoadingAlerts(false);
      }
    };

    fetchDevices();
    fetchAlerts();
  }, [reloadPage]);

  const handleInputChange = e => {
    setNameValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (selectedDeviceIds.length === 0) {
      setValidationDeviceIdsResult('empty');
    } else {
      setValidationDeviceIdsResult('valid');
    }
  }, [selectedDeviceIds]);

  useEffect(() => {
    if (nameValue.length === 0) {
      setValidationResult('empty');
    } else if (nameValue.length > 255) {
      setValidationResult('tooLong');
    } else if (nameValue.length < 3) {
      setValidationResult('tooShort');
    } else {
      setValidationResult('validName');
    }
  }, [nameValue]);

  const handleAlertTypeChange = event => {
    setAlertTypeValue(event.target.value);
    if (event.target.value === '') {
      setValidationAlertTypeResult('empty');
    } else if (event.target.value === 'device_goes_offline') {
      setNotifyAfterValue('choose_notify_after');
      setValidationNotifyAfterResult('empty');
      setValidationAlertTypeResult('valid');
    } else if (event.target.value === 'device_comes_online') {
      setNotifyAfterValue('-1');
      setValidationNotifyAfterResult('valid');
      setValidationAlertTypeResult('valid');
    } else {
      setValidationAlertTypeResult('valid');
    }
  };

  const handleNotifyAfterChange = event => {
    setNotifyAfterValue(event.target.value);
    if (event.target.value === '') {
      setValidationNotifyAfterResult('empty');
    } else {
      setValidationNotifyAfterResult('valid');
    }
  };

  function convertTokensToArray(tokens) {
    return tokens.map(token => token.text);
  }

  useEffect(() => {
    if (tokens.length === 0 && !inputValue) {
      setValidationEmailsResult('empty');
    } else if (
      isValidEmail(inputValue) ||
      (tokens.length > 0 && tokens.every(token => isValidEmail(token.text)))
    ) {
      setValidationEmailsResult('valid');
    } else {
      setValidationEmailsResult('invalid');
    }
  }, [tokens, inputValue]);

  const onTokenRemove = tokenId => {
    setTokens(tokens.filter(token => token.id !== tokenId));
    if (tokens.length === 1) {
      setValidationEmailsResult('empty');
    }
  };

  const onInputChange = e => {
    setInputValue(e.target.value);
  };

  const onInputKeyPress = e => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      if (!isValidEmail(inputValue.trim())) {
        setValidationEmailsResult('invalid');
        return;
      } else {
        const newToken = { id: tokens.length + 1, text: inputValue.trim() };
        setTokens([...tokens, newToken]);
        setInputValue(''); // Clear the input after adding the token
        setValidationEmailsResult('valid');
      }
    }
  };

  const confirm = useConfirm();

  const onButtonClick = useCallback(async () => {
    if (
      await confirm({
        title: 'Are you sure?',
        content: `Deleting alert ${selectedAlertId} will remove it from the system. Any pending notifications will still be sent. Are you sure you want to delete this alert?`,
        confirmButtonContent: 'Delete it!',
        confirmButtonType: 'danger',
      })
    ) {
      handleSubmitDeleteAlert(selectedAlertId);
    }
  }, [confirm, selectedAlertId]);

  // const renderDeviceRow = ({ index, style }) => {
  //   const device = itemsToShow[index];
  //   return (
  //     <ActionList.Item
  //       key={device.id}
  //       onSelect={() => onDeviceSelect(device.id)}
  //       selected={selectedDeviceIds.includes(device.id)}
  //       style={style}
  //     >
  //       <ActionList.LeadingVisual>
  //         <Box
  //           sx={{
  //             width: 14,
  //             height: 14,
  //             borderRadius: '100%',
  //           }}
  //           style={{
  //             backgroundColor: `#${device.color}`,
  //           }}
  //         />
  //       </ActionList.LeadingVisual>
  //       {device.name.length > 25 ? `${device.name.slice(0, 25)}...` : device.name}
  //     </ActionList.Item>
  //   );
  // };

  return (
    <>
      <Box sx={{}}>
        <Box sx={{ padding: 3 }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Alerts</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Description sx={{ fontSize: 1, color: 'fg.muted' }}>
              Updating alert rules is temporarily disabled.
            </PageHeader.Description>
          </PageHeader>
        </Box>
        {restricted && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              gap: 3,
              marginTop: 4,
            }}
          >
            <Icons.Alert
              sx={{
                width: 20,
                height: 20,
                color: 'fg.muted',
              }}
            />
            <Text sx={{ fontSize: 3, color: 'fg.muted' }}>
              You do not have permission to view this page.
            </Text>
          </Box>
        )}
        {!restricted && (
          <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], width: '100%', height: '100%', marginTop: 4 }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], gap: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', borderRadius: 2, border: '1px solid', minHeight: 400, borderColor: 'border.default', gap: 3, padding: 3, width: '100%' }}>
                  <Text sx={{ fontSize: 2, fontWeight: 'bold', color: 'fg.default' }}>
                    Create a new alert rule
                  </Text>

                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <FormControl required>
                      <FormControl.Label>Alert rule type</FormControl.Label>
                      <Select sx={{ width: '100%' }} value={alertTypeValue} onChange={handleAlertTypeChange}>
                        <Select.Option value='choose_alert_type' disabled>Choose alert type</Select.Option>
                        <Select.Option value='device_goes_offline'>Device goes offline</Select.Option>
                        <Select.Option value='device_comes_online'>Device comes online</Select.Option>
                      </Select>
                      {validationAlertTypeResult === 'empty' && (
                        <FormControl.Validation variant='error'>Alert type cannot be empty</FormControl.Validation>
                      )}
                      {validationAlertTypeResult === 'valid' && (
                        <FormControl.Validation variant='success'>Valid alert type</FormControl.Validation>
                      )}
                    </FormControl>
                    <FormControl required>
                      <FormControl.Label>Notify after</FormControl.Label>
                      <Select
                        sx={{ width: '100%' }}
                        disabled={alertTypeValue === 'device_comes_online'}
                        value={notifyAfterValue}
                        onChange={handleNotifyAfterChange}
                      >
                        <Select.Option value='choose_notify_after'>Choose to be notified after</Select.Option>
                        <Select.Option value='15'>15 minutes</Select.Option>
                        <Select.Option value='30'>30 minutes</Select.Option>
                        <Select.Option value='60'>1 hour</Select.Option>
                        <Select.Option value='240'>4 hours</Select.Option>
                        <Select.Option value='720'>12 hours</Select.Option>
                        <Select.Option value='1440'>1 day</Select.Option>
                        <Select.Option value='4320'>3 days</Select.Option>
                        <Select.Option value='10080'>7 days</Select.Option>
                      </Select>
                      {validationNotifyAfterResult === 'empty' && (
                        <FormControl.Validation variant='error'>Notify after cannot be empty</FormControl.Validation>
                      )}
                      {validationNotifyAfterResult === 'valid' && (
                        <FormControl.Validation variant='success'>Valid notify after</FormControl.Validation>
                      )}
                    </FormControl>
                    <FormControl required id='name'>
                      <FormControl.Label>Alert name</FormControl.Label>
                      <TextInput value={nameValue} onChange={handleInputChange} name='name' placeholder='Name' width='100%' />
                      {validationResult === 'empty' && (
                        <FormControl.Validation variant='error'>Name cannot be empty</FormControl.Validation>
                      )}
                      {validationResult === 'tooLong' && (
                        <FormControl.Validation variant='error'>Name cannot be longer than 255 characters</FormControl.Validation>
                      )}
                      {validationResult === 'tooShort' && (
                        <FormControl.Validation variant='error'>Name cannot be less than 3 characters</FormControl.Validation>
                      )}
                      {validationResult === 'validName' && (
                        <FormControl.Validation variant='success'>Valid name</FormControl.Validation>
                      )}
                      <FormControl.Caption>
                        Please enter a name (3 to 255 characters).
                      </FormControl.Caption>
                    </FormControl>
                    <FormControl required>
                      <FormControl.Label>Email list</FormControl.Label>
                      <TextInputWithTokens
                        sx={{ width: '100%', maxWidth: '450px', overflow: 'auto' }}
                        tokens={tokens}
                        onTokenRemove={onTokenRemove}
                        onChange={onInputChange}
                        onKeyPress={onInputKeyPress}
                        value={inputValue}
                        placeholder='Type and press enter to add emails'
                        leadingVisual={Icons.Email}
                      />
                      {validationEmailsResult === 'empty' && (
                        <FormControl.Validation variant='error'>Email list cannot be empty</FormControl.Validation>
                      )}
                      {validationEmailsResult === 'invalid' && (
                        <FormControl.Validation variant='error'>Email is invalid</FormControl.Validation>
                      )}
                      {validationEmailsResult === 'valid' && (
                        <FormControl.Validation variant='success'>Email list is valid</FormControl.Validation>
                      )}
                    </FormControl>
                    <FormControl required>
                      <FormControl.Label>Device IDs</FormControl.Label>
                      {isLoadingDevices && <Spinner size='small' />}
                      {!isLoadingDevices && (
                        <>
                          <Button
                            variant='default'
                            ref={buttonRef}
                            trailingAction={() => (
                              <Label variant='accent'>
                                <Icons.Devices sx={{ width: 14, height: 14, mr: 1 }} />
                                {selectedDeviceIds.length}
                              </Label>
                            )}
                            onClick={() => {
                              handleOpen();
                            }}
                            sx={{ width: '100%', '[data-component=buttonContent]': { justifyContent: 'start' } }}
                          >
                            Select devices for alert
                          </Button>
                          <SelectPanel
                            title={title}
                            open={isOpen}
                            variant='anchored'
                            anchorRef={buttonRef}
                            onCancel={() => {
                              setIsOpen(!isOpen);
                            }}
                            onClearSelection={onClearSelection}
                          >
                            <SelectPanel.Header>
                              <SelectPanel.SearchInput onChange={onSearchInputChange} />
                            </SelectPanel.Header>

                            {itemsToShow.length === 0 && !fetchError && (
                              <SelectPanel.Message variant='empty' title={notFound}>
                                Try a different search term
                              </SelectPanel.Message>
                            )}

{itemsToShow.length > 0 && !isLoading && !fetchError && (
  <List
    height={200}
    itemCount={itemsToShow.length}
    itemSize={35}
    width='100%'
  >
    {({ index, style }) => {
      const device = itemsToShow[index];
      return (
        <ActionList selectionVariant="multiple">
          <ActionList.Item
            key={device.id}
            onSelect={() => onDeviceSelect(device.id)}
            selected={selectedDeviceIds.includes(device.id)}
            style={style}
          >
            <ActionList.LeadingVisual>
              <Box
                sx={{
                  width: 14,
                  height: 14,
                  borderRadius: '100%',
                }}
                style={{
                  backgroundColor: `#${device.color}`,
                }}
              />
            </ActionList.LeadingVisual>
            {device.name.length > 25 ? `${device.name.slice(0, 25)}...` : device.name}
          </ActionList.Item>
        </ActionList>
      );
    }}
  </List>
)}

                            {isLoading && (
                              <Box sx={{ display: 'flex', color: 'fg.default', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                                <Spinner />
                              </Box>
                            )}

                            {fetchError && (
                              <SelectPanel.Message variant='error' title='Error fetching devices'>
                                Unable to fetch devices
                              </SelectPanel.Message>
                            )}
                          </SelectPanel>
                          {validationDeviceIdsResult === 'empty' && (
                            <FormControl.Validation variant='error'>Devices cannot be empty</FormControl.Validation>
                          )}
                          {validationDeviceIdsResult === 'valid' && (
                            <FormControl.Validation variant='success'>Device list is valid</FormControl.Validation>
                          )}
                        </>
                      )}
                    </FormControl>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 3 }}>
                      <Button
                        variant='primary'
                        disabled={isLoading}
                        onClick={() => {
                          handleSubmitCreateAlert();
                        }}
                        sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '65px' }}
                      >
                        {isLoading ? (
                          <Box sx={{ display: 'flex', justifyContent: 'center', width: '47px' }}>
                            <Spinner size='small' />
                          </Box>
                        ) : (
                          <Text>Submit</Text>
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', borderRadius: 2, border: '1px solid', borderColor: 'border.default', gap: 3, padding: 3, width: '100%' }}>
                  <Text sx={{ fontSize: 2, fontWeight: 'bold', color: 'fg.default' }}>
                    Your alerts
                  </Text>
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                    {isLoadingAlerts && (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                        <Spinner />
                      </Box>
                    )}

                    {!isLoadingAlerts && alerts && (
                      <ActionList>
                        {alerts.map(alert => (
                          <ActionList.Item
                            key={alert.id}
                            onClick={() => {
                              setShowAlertDetails(true);
                              setSelectedAlertId(alert.id);
                              handleFetchAlert(alert.id);
                            }}
                          >
                            <ActionList.LeadingVisual>
                              <Icons.Unread />
                            </ActionList.LeadingVisual>
                            {alert.name} <Label>{alert.alert_type}</Label>
                            <ActionList.TrailingVisual>
                              <Icons.ArrowRight />
                            </ActionList.TrailingVisual>
                          </ActionList.Item>
                        ))}
                      </ActionList>
                    )}
                  </Box>
                </Box>
              </Box>
              {showAlertDetails && selectedAlert && (
                <>
                  {isLoadingAlert && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', height: '500px' }}>
                      <Spinner />
                    </Box>
                  )}
                  {selectedAlert && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', borderRadius: 2, border: '1px solid', borderColor: 'border.default', gap: 3, padding: 3, width: '100%' }}>
                      <Text sx={{ fontSize: 2, fontWeight: 'bold', color: 'fg.default' }}>
                        Edit alert
                      </Text>
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                        <Box>
                          <FormControl required disabled>
                            <FormControl.Label>
                              Alert rule type
                            </FormControl.Label>
                            <Select sx={{ width: '100%' }}>
                              <Select.Option value={selectedAlert.data.alert_rule.alert_type}>
                                {selectedAlert.data.alert_rule.alert_type === 'device_goes_offline'
                                  ? 'Device goes offline'
                                  : selectedAlert.data.alert_rule.alert_type === 'device_comes_online'
                                  ? 'Device comes online'
                                  : 'Unknown alert type'}
                              </Select.Option>
                            </Select>
                          </FormControl>
                          <FormControl required disabled>
                            <FormControl.Label>Notify after</FormControl.Label>
                            <Select sx={{ width: '100%' }}>
                              <Select.Option value={selectedAlert.data.alert_rule.notify_after}>
                                {selectedAlert.data.alert_rule.notify_after} minutes
                              </Select.Option>
                            </Select>
                          </FormControl>
                          <FormControl required disabled>
                            <FormControl.Label>Alert name</FormControl.Label>
                            <TextInput sx={{ width: '100%' }} value={selectedAlert.data.alert_rule.name} />
                          </FormControl>
                          <FormControl required disabled>
                            <FormControl.Label>Email list</FormControl.Label>
                            <TextInputWithTokens
                              sx={{ width: '100%', maxWidth: '1000px', overflow: 'auto' }}
                              tokens={selectedAlert.data.alert_rule.emails.map((email, index) => ({
                                id: index,
                                text: email,
                              }))}
                              onTokenRemove={onTokenRemove}
                              onChange={onInputChange}
                              onKeyPress={onInputKeyPress}
                              value={inputValue}
                              placeholder='Type and press enter to add emails'
                              leadingVisual={Icons.Email}
                            />
                          </FormControl>
                          <FormControl required disabled>
                            <FormControl.Label>Device IDs</FormControl.Label>
                            <TextInputWithTokens
                              sx={{ width: '100%', maxWidth: '1000px', overflow: 'auto' }}
                              tokens={selectedAlert.data.alert_rule.devices.map((deviceId, index) => ({
                                id: index,
                                text: deviceId,
                              }))}
                              onTokenRemove={onTokenRemove}
                              onChange={onInputChange}
                              onKeyPress={onInputKeyPress}
                              value={inputValue}
                              placeholder='Type and press enter to add devices'
                              leadingVisual={Icons.Devices}
                            />
                          </FormControl>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 3 }}>
                            <Button variant='danger' onClick={onButtonClick}>
                              Delete
                            </Button>
                            <Button variant='primary' disabled>
                              Update
                            </Button>
                          </Box>
                          <Box sx={{ backgroundColor: 'border.default', height: '1px', width: '100%', marginTop: 3, marginBottom: 3 }}></Box>
                          <Text sx={{ fontSize: 2, fontWeight: 'bold', color: 'fg.default' }}>
                            Notification logs
                          </Text>
                          <ActionList>
                            {selectedAlert.data.logs.map(log => (
                              <ActionList.Item key={log.id}>
                                <ActionList.LeadingVisual>
                                  <Icons.Alert />
                                </ActionList.LeadingVisual>
                                Device <Label>{log.device_id}</Label> {log.alert_type === 'device_goes_offline' ? 'went offline' : log.alert_type === 'device_comes_online' ? 'comes back online' : 'Unknown action'} at <Label>{log.notified_at}</Label>. Message sent to <Label>{log.recipient}</Label>.
                              </ActionList.Item>
                            ))}
                          </ActionList>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
