import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { router } from "@/lib/router"; // Import your route configuration
import axios from "axios";

export function useUpdateTitle() {
  const location = useLocation();

  useEffect(() => {
    // Function to recursively find the title in route definitions
    const findTitle = (routes, pathname, defaultTitle = "Default Title") => {
      for (const route of routes) {
        if (route.path === pathname) {
          return route.title || defaultTitle;
        }
        if (route.children) {
          const title = findTitle(route.children, pathname, route.title);
          if (title) return title;
        }
      }
      return defaultTitle;
    };

    // Update the document title using the title from the route configuration
    const title = findTitle(router.routes, location.pathname);
    document.title = title;
  }, [location]);
}

// Utility function to delay execution for a given amount of time
const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Function to perform a GET request with retry logic, excluding 403 errors from retries
export const axiosGetWithRetry = async (
  url,
  options = {},
  retries = 3,
  backoff = 300,
) => {
  for (let attempt = 0; attempt <= retries; attempt++) {
    try {
      const response = await axios.get(url, options);
      return response; // If request is successful, return the response
    } catch (error) {
      const isServerError = error.response && error.response.status >= 500;
      const isForbidden = error.response && error.response.status === 403;
      const isLastAttempt = attempt === retries;

      if (isForbidden) {
        // Immediately throw the error if a 403 Forbidden response is received
        throw error;
      } else if (isServerError && !isLastAttempt) {
        // Wait for a calculated delay based on the attempt number before retrying
        await wait(backoff * Math.pow(2, attempt));
      } else {
        // If it's not a server error or we've reached the max retries, or another client error, throw the error
        throw error;
      }
    }
  }
};
