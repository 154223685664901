import React, { useState, useEffect, useCallback } from 'react'
import { Text, Box, ActionList, ActionMenu, IconButton, Tooltip, PageHeader, Label, LinkButton, Spinner, Dialog, Button, FormControl, TextInput } from '@primer/react'
import { Hidden } from '@primer/react/drafts'
import { DataTable, Table } from '@primer/react/drafts'
import { getApps, createApp, deleteApp } from '@/lib/calls'
import { Icons } from '@/components/icons'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

const PAGE_SIZE = 10

export function AppsPage() {
  const [pageIndex, setPageIndex] = useState(0)
  const [apps, setApps] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [appToDelete, setAppToDelete] = useState(null)
  const [newApp, setNewApp] = useState({ name: '', url: '' })

  const fetchApps = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await getApps()
      setApps(response.data)
      setError(null)
    } catch (error) {
      setError(error.response?.status === 403 ? 'restricted' : 'fetchError')
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchApps()
  }, [fetchApps])

  const handleCreateApp = async () => {
    try {
      setIsLoading(true)
      await createApp(newApp.name, newApp.url)
      toast.custom(t => <CustomToast message='App added successfully' type='success' />)
      setIsCreateDialogOpen(false)
      fetchApps()
    } catch (error) {
      handleApiError(error, 'Error adding new app')
    }
  }

  const handleDeleteApp = async () => {
    try {
      setIsLoading(true)
      await deleteApp(appToDelete)
      toast.custom(t => <CustomToast message='App deleted successfully' type='success' />)
      setIsDeleteDialogOpen(false)
      fetchApps()
    } catch (error) {
      handleApiError(error, 'Error deleting app')
    }
  }

  const handleApiError = (error, defaultMessage) => {
    const message = error.response?.status === 429 ? 'Rate limit exceeded. Please try again later.' : defaultMessage
    toast.custom(t => <CustomToast message={message} type='error' />)
    console.error(defaultMessage, error)
    setIsLoading(false)
  }

  const validateInput = (name, value) => {
    if (value.length === 0) return 'empty'
    if (value.length > 255) return 'tooLong'
    if (name === 'name' && value.length < 3) return 'tooShort'
    if (name === 'url' && !value.match(/^(http|https):\/\/[^ "]+$/)) return 'invalidUrl'
    return 'valid'
  }

  const renderContent = () => {
    if (isLoading) return <Spinner />
    if (error === 'restricted') return <RestrictedAccessMessage />
    if (error === 'fetchError') return <FetchErrorMessage />
    if (apps.length === 0) return <NoAppsMessage onAddApp={() => setIsCreateDialogOpen(true)} />
    return <AppsTable apps={apps} onDeleteApp={handleOpenDeleteDialog} pageIndex={pageIndex} setPageIndex={setPageIndex} />
  }

  const handleOpenDeleteDialog = (appId) => {
    setAppToDelete(appId)
    setIsDeleteDialogOpen(true)
  }

  return (
    <Box sx={{ padding: 3 }}>
      <PageHeader>
        <PageHeader.TitleArea>
          <PageHeader.Title as="h2">Apps</PageHeader.Title>
        </PageHeader.TitleArea>
        <PageHeader.Description sx={{ fontSize: 1, color: 'fg.muted' }}>
          Apps are third-party web applications that can be used in your playlists instead of regular media files.
        </PageHeader.Description>
        <PageHeader.Actions>
          <Hidden when={['narrow']}>
            <LinkButton variant='default' onClick={() => setIsCreateDialogOpen(true)}>
              Create new app
            </LinkButton>
          </Hidden>
          <Hidden when={['regular', 'wide']}>
            <LinkButton variant='default' onClick={() => setIsCreateDialogOpen(true)}>
              New
            </LinkButton>
          </Hidden>
        </PageHeader.Actions>
      </PageHeader>

      <Box sx={{ marginTop: 4 }}>
        {renderContent()}
      </Box>

      <CreateAppDialog
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onSubmit={handleCreateApp}
        newApp={newApp}
        setNewApp={setNewApp}
        validateInput={validateInput}
        isLoading={isLoading}
      />

      <DeleteAppDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={handleDeleteApp}
        isLoading={isLoading}
      />
    </Box>
  )
}

const RestrictedAccessMessage = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', mt: 4 }}>
    <Text sx={{ fontSize: 4, fontWeight: 'bold' }}>You don't have access to this content.</Text>
    <Text sx={{ fontSize: 1, color: 'fg.muted' }}>
      <Icons.Alert /> Insufficient permissions to manage content.
    </Text>
  </Box>
)

const FetchErrorMessage = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', mt: 4 }}>
    <Text sx={{ fontSize: 4, fontWeight: 'bold' }}>An error occurred fetching content.</Text>
    <Text sx={{ fontSize: 1, color: 'fg.muted' }}>
      <Icons.Alert /> Please try again later
    </Text>
  </Box>
)

const NoAppsMessage = ({ onAddApp }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', mt: 4 }}>
    <Text sx={{ fontSize: 4, fontWeight: 'bold' }}>You don't have any apps.</Text>
    <Text sx={{ fontSize: 1, color: 'fg.muted' }}>
      You can add a new app by clicking the button below.
    </Text>
    {/* <LinkButton variant='primary' onClick={onAddApp} sx={{ mt: 3 }}>
      Add new app
    </LinkButton> */}
  </Box>
)

const AppsTable = ({ apps, onDeleteApp, pageIndex, setPageIndex }) => (
  <Table.Container>
    <Table.Title as='h2' id='apps'>Apps</Table.Title>
    <DataTable
      aria-labelledby='apps'
      data={apps.slice(pageIndex * PAGE_SIZE, (pageIndex + 1) * PAGE_SIZE)}
      columns={[
        {
          header: 'Name',
          field: 'apps.name',
          renderCell: row => (
            <Tooltip aria-label={row.name}>
              <LinkButton variant='invisible'>
                {row.name.length > 25 ? `${row.name.slice(0, 25)}...` : row.name}
              </LinkButton>
            </Tooltip>
          ),
          rowHeader: true,
        },
        {
          header: 'URL',
          field: 'apps.url',
          renderCell: row => (
            <Tooltip aria-label={row.url}>
              <Label sx={{ fontSize: 1, color: 'fg.muted' }}>
                <Icons.LinkIcon sx={{ mr: 1 }} />
                {row.url.length > 25 ? `${row.url.slice(0, 25)}...` : row.url}
              </Label>
            </Tooltip>
          ),
        },
        {
          header: 'Actions',
          field: 'actions',
          renderCell: row => (
            <ActionMenu>
              <ActionMenu.Anchor>
                <IconButton variant='invisible' icon={Icons.Menu} aria-label='' />
              </ActionMenu.Anchor>
              <ActionMenu.Overlay width='medium'>
                <ActionList>
                  <ActionList.Item variant='danger' onSelect={() => onDeleteApp(row.id)}>
                    Delete app
                  </ActionList.Item>
                </ActionList>
              </ActionMenu.Overlay>
            </ActionMenu>
          ),
        },
      ]}
    />
    <Table.Pagination
      aria-label="Pagination for Apps"
      pageSize={PAGE_SIZE}
      totalCount={apps.length}
      pageIndex={pageIndex}
      onChange={({ pageIndex }) => setPageIndex(pageIndex)}
    />
  </Table.Container>
)

const CreateAppDialog = ({ isOpen, onClose, onSubmit, newApp, setNewApp, validateInput, isLoading }) => (
  <Dialog isOpen={isOpen} onDismiss={onClose} aria-labelledby='create-app-dialog-label'>
    <Dialog.Header>Add a new app</Dialog.Header>
    <Box p={3}>
      <Text id='create-app-dialog-label' color='fg.muted'>
        Apps are third-party web applications that can be used instead of regular media files for playlists.
      </Text>
      <Box mt={3} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <FormControl required>
          <FormControl.Label>Name</FormControl.Label>
          <TextInput
            block
            value={newApp.name}
            onChange={(e) => setNewApp({ ...newApp, name: e.target.value })}
          />
          <InputValidation name="name" value={newApp.name} validateInput={validateInput} />
        </FormControl>
        <FormControl required>
          <FormControl.Label>URL</FormControl.Label>
          <TextInput
            block
            value={newApp.url}
            onChange={(e) => setNewApp({ ...newApp, url: e.target.value })}
          />
          <InputValidation name="url" value={newApp.url} validateInput={validateInput} />
        </FormControl>
      </Box>
      <Box display='flex' mt={3} justifyContent='flex-end'>
        <Button onClick={onClose} sx={{ mr: 1 }} variant='invisible'>
          Cancel
        </Button>
        <Button
          variant='primary'
          disabled={isLoading}
          onClick={onSubmit}
          sx={{ width: '65px', justifyContent: 'center' }}
        >
          {isLoading ? <Spinner size='small' /> : 'Submit'}
        </Button>
      </Box>
    </Box>
  </Dialog>
)

const DeleteAppDialog = ({ isOpen, onClose, onDelete, isLoading }) => (
  <Dialog isOpen={isOpen} onDismiss={onClose} aria-labelledby='delete-app-dialog-label'>
    <Dialog.Header>Delete app</Dialog.Header>
    <Box p={3}>
      <Text id='delete-app-dialog-label' color='fg.muted' fontSize={1}>
        This action can't be undone. Are you sure you want to delete this app?
      </Text>
      <Box display='flex' mt={3} justifyContent='flex-end'>
        <Button onClick={onClose} sx={{ mr: 1 }} variant='invisible'>
          Cancel
        </Button>
        <Button
          variant='danger'
          disabled={isLoading}
          onClick={onDelete}
          sx={{ width: '120px', justifyContent: 'center' }}
        >
          {isLoading ? <Spinner size='small' /> : 'Delete app'}
        </Button>
      </Box>
    </Box>
  </Dialog>
)

const InputValidation = ({ name, value, validateInput }) => {
  const validationResult = validateInput(name, value)
  const messages = {
    empty: `${name.charAt(0).toUpperCase() + name.slice(1)} cannot be empty`,
    tooLong: `${name.charAt(0).toUpperCase() + name.slice(1)} cannot be longer than 255 characters`,
    tooShort: 'Name cannot be less than 3 characters',
    invalidUrl: 'URL is invalid',
    valid: `Valid ${name}`
  }

  return (
    <>
      <FormControl.Validation variant={validationResult === 'valid' ? 'success' : 'error'}>
        {messages[validationResult]}
      </FormControl.Validation>
      <FormControl.Caption>
        Please enter a valid {name}{name === 'name' ? ' (3 to 255 characters)' : ''}.
      </FormControl.Caption>
    </>
  )
}