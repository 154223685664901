import React from 'react'
import { Icons } from '@/components/icons'
import { Link } from 'react-router-dom'
import { Label } from '@primer/react'
import { DataTable } from '@primer/react/drafts'

export const TableProofOfPlay = ({ data }) => {
  const columns = [
    {
      header: 'Date',
      field: 'date',
      renderCell: row => new Date(row.date).toLocaleDateString(),
    },
    {
      header: 'Playlist',
      field: 'playlist_name',
      renderCell: row => (
        <Link to={row.playlist_url}>
          <Label variant='done'>
            <Icons.Play sx={{ mr: 1 }} /> {row.playlist_name}
          </Label>
        </Link>
      ),
    },
    {
      header: 'Schedule',
      field: 'schedule_name',
      renderCell: row => (
        <Link to={row.schedule_url}>
          <Label size='small' variant='sponsors'>
            <Icons.Calendar sx={{ mr: 1 }} /> {row.schedule_name}
          </Label>
        </Link>
      ),
    },
    {
      header: 'File',
      field: 'file_name',
      renderCell: row => (
        <Link to={row.file_url}>
          <Label size='small' variant='attention'>
            <Icons.FileMedia sx={{ mr: 1 }} /> {row.file_name}
          </Label>
        </Link>
      ),
    },
    {
      header: 'Play count',
      field: 'play_count',
      renderCell: row => <Label size='small'>{row.play_count}</Label>,
    },
    {
      header: 'Total duration',
      field: 'total_duration',
      renderCell: row => `${row.total_duration} seconds`,
    },
    {
      header: 'Last play',
      field: 'last_play',
      renderCell: row => new Date(row.last_play).toLocaleString(),
    },
    {
      header: 'Unique devices',
      field: 'unique_devices',
      renderCell: row => (
        <Label size='small' variant='accent'>
          <Icons.Device sx={{ mr: 1 }} /> {row.unique_devices}
        </Label>
      ),
    },
  ]

  return (
    <DataTable
      aria-labelledby='proofOfPlayTable'
      aria-describedby='Table showing proof of play data'
      data={data}
      // data={metricsProofOfPlayMock}
      columns={columns}
    />
  )
}
