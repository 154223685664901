import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Text,
  Box,
  Label,
  SubNav,
  FormControl,
  PageHeader,
  TextInput,
  Button,
  Select,
  ActionList,
  Spinner,
} from '@primer/react';
import { SelectPanel } from '@primer/react/drafts';
import { debounce } from 'lodash';
import { Icons } from '@/components/icons';
import { Link } from 'react-router-dom';
import { getDeviceGroups, getDeviceGroup, createSyncRule } from '@/lib/calls';
import { toast } from 'react-hot-toast';
import { CustomToast } from './custom-toast';

export function SyncPage() {
  const buttonRef = useRef(null);
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [restartValue, setRestartValue] = useState('hourly');

  const handleOpen = async () => {
    setIsOpen(!isOpen);
    setIsLoading(true);
    try {
      const devices = await getDeviceGroup(selectedDeviceGroup.id);
      if (devices) {
        setDevices(devices.data.data.devices);
      }
      setIsLoading(false);
    } catch (error) {
      setFetchError(error);
      setIsLoading(false);
      console.error('Error fetching devices', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeviceSelect = deviceId => {
    if (!selectedDeviceIds.includes(deviceId))
      setSelectedDeviceIds([...selectedDeviceIds, deviceId]);
    else setSelectedDeviceIds(selectedDeviceIds.filter(id => id !== deviceId));
  };

  const onClearSelection = () => {
    setSelectedDeviceIds([]);
  };

  const handleSubmitCreateSyncRule = async () => {
    try {
      setIsLoading(true);

      const preparePayload = selectedDeviceIds.map(deviceId => ({
        id: deviceId,
      }));

      await createSyncRule(
        nameValue,
        restartValue,
        selectedDeviceGroup.id,
        preparePayload
      );

      setIsLoading(false);
      toast.custom(t => <CustomToast message='Sync rule created' type='success' />);

      setNameValue('');
      setDeviceGroupValue('');
      setValidationResult('empty');
      setValidationDeviceGroupResult('empty');
      setSelectedDeviceIds([]);
      setRestartValue('hourly');

    } catch (error) {
      setIsLoading(false);
      toast.custom(t => <CustomToast message='Error could not create sync rule' type='error' />);
      console.error('Error creating sync rule:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    setIsOpen(!isOpen);
  };

  const [filteredDevices, setFilteredDevices] = useState([]);
  const [query, setQuery] = useState('');

  const handleScroll = useCallback(() => {
    if (!isLoading && isOpen) {
      // Check if the user has scrolled to the bottom
      if (buttonRef.current.scrollHeight - buttonRef.current.scrollTop === buttonRef.current.clientHeight) {
        setIsLoading(true);
        // Load more items here
        setIsLoading(false);
      }
    }
  }, [isLoading, isOpen]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(query => {
      if (query === '') setFilteredDevices(devices);
      else {
        setFilteredDevices(
          devices
            .map(device => {
              if (device.name.toLowerCase().startsWith(query))
                return { priority: 1, device };
              else if (device.name.toLowerCase().includes(query))
                return { priority: 2, device };
              else return { priority: -1, device };
            })
            .filter(result => result.priority > 0)
            .map(result => result.device)
        );
      }
    }, 300),
    [devices]
  );

  const onSearchInputChange = event => {
    const query = event.currentTarget.value.toLowerCase();
    setQuery(query);
    debouncedSearch(query);
  };

  const sortingFn = (itemA, itemB) => {
    const initialSelectedIds = selectedDeviceGroup.devices.map(item => item.id);
    if (initialSelectedIds.includes(itemA.id) && initialSelectedIds.includes(itemB.id)) return 1;
    else if (initialSelectedIds.includes(itemA.id)) return -1;
    else if (initialSelectedIds.includes(itemB.id)) return 1;
    else return 1;
  };

  const itemsToShow = query ? filteredDevices : devices.sort(sortingFn);

  const title = <Text sx={{ color: 'fg.default' }}>Select devices</Text>;
  const notFound = <Text sx={{ color: 'fg.default' }}>No devices found</Text>;

  const [deviceGroups, setDeviceGroups] = useState([]);
  const [isLoadingDeviceGroup, setIsLoadingDeviceGroup] = useState(true);
  const [restricted, setRestricted] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [validationResult, setValidationResult] = useState('empty');
  const [deviceGroupValue, setDeviceGroupValue] = useState('');
  const [validationDeviceGroupResult, setValidationDeviceGroupResult] = useState('');

  useEffect(() => {
    const fetchDeviceGroups = async () => {
      try {
        setIsLoadingDeviceGroup(true);
        const response = await getDeviceGroups();
        setDeviceGroups(response.data);
        setDeviceGroupValue('choose_device_group');
        setIsLoadingDeviceGroup(false);
      } catch (error) {
        if (error?.response?.status === 403) {
          setRestricted(true);
        }
        setFetchError(error);
        console.error(error);
      }
    };
    fetchDeviceGroups();
  }, []);

  const handleInputChange = e => {
    setNameValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (nameValue.length === 0) {
      setValidationResult('empty');
    } else if (nameValue.length > 255) {
      setValidationResult('tooLong');
    } else if (nameValue.length < 3) {
      setValidationResult('tooShort');
    } else {
      setValidationResult('validName');
    }
  }, [nameValue]);

  const handleDeviceGroupChange = event => {
    setDeviceGroupValue(event.target.value);
    if (event.target.value === '') {
      setValidationDeviceGroupResult('empty');
    } else {
      setValidationDeviceGroupResult('valid');
    }
  };

  useEffect(() => {
    const fetchDeviceGroup = async () => {
      try {
        setIsLoading(true);
        const response = await getDeviceGroup(deviceGroupValue);
        const deviceGroupData = response.data.data.devices.map(device => ({
          name: device.name,
          text: device.name,
          id: device.id,
        }));
        setSelectedDeviceGroup({
          name: response.data.data.name,
          devices: deviceGroupData,
          id: deviceGroupValue,
        });
        setIsLoading(false);
      } catch (error) {
        setFetchError(error);
        console.error(error);
      }
    };

    if (deviceGroupValue !== 'choose_device_group' && deviceGroupValue !== '') {
      fetchDeviceGroup();
    }
  }, [deviceGroupValue]);

  return (
    <>
      <Box sx={{}}>
        <Box sx={{ padding: 3 }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Sync</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Description sx={{ fontSize: 1, color: 'fg.muted' }}>
              Synchronize rendering on a subset of devices under a device group.
            </PageHeader.Description>
          </PageHeader>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], width: '100%', height: '100%', marginTop: 4 }}>
          <Box sx={{ width: '100%', height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 6 }}>
                <SubNav>
                  <SubNav.Links>
                    <SubNav.Link href='#' selected>
                      New sync rule
                    </SubNav.Link>
                    <SubNav.Link as={Link} to='/dashboard/sync/rules'>
                      Sync rules
                    </SubNav.Link>
                  </SubNav.Links>
                </SubNav>

                {restricted && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      position: 'relative',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: 3,
                      mt: 4,
                    }}
                  >
                    <Text sx={{ fontSize: 4, fontWeight: 'bold', textAlign: 'center' }}>
                      You don't have access to this content.
                    </Text>
                    <Text sx={{ fontSize: 1, color: 'fg.muted' }}>
                      <Icons.Alert /> Insufficient permissions to manage devices.
                    </Text>
                  </Box>
                )}

                {isLoading && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      position: 'relative',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: 3,
                      mt: 4,
                    }}
                  >
                    <Spinner />
                  </Box>
                )}

                {fetchError && !restricted && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      position: 'relative',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: 3,
                      mt: 4,
                    }}
                  >
                    <Text sx={{ fontSize: 4, fontWeight: 'bold', textAlign: 'center' }}>
                      An error occurred fetching content.
                    </Text>
                    <Text sx={{ fontSize: 1, color: 'fg.muted' }}>
                      <Icons.Alert /> Please try again later.
                    </Text>
                  </Box>
                )}

                {!restricted && !isLoading && !fetchError && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: ['100%', '50%'] }}>
                    <FormControl required id='name'>
                      <FormControl.Label>Sync rule name</FormControl.Label>
                      <TextInput
                        value={nameValue}
                        onChange={handleInputChange}
                        name='name'
                        placeholder='Name'
                        width='100%'
                      />
                      {validationResult === 'empty' && (
                        <FormControl.Validation variant='error'>Name cannot be empty</FormControl.Validation>
                      )}
                      {validationResult === 'tooLong' && (
                        <FormControl.Validation variant='error'>Name cannot be longer than 255 characters</FormControl.Validation>
                      )}
                      {validationResult === 'tooShort' && (
                        <FormControl.Validation variant='error'>Name cannot be less than 3 characters</FormControl.Validation>
                      )}
                      {validationResult === 'validName' && (
                        <FormControl.Validation variant='success'>Valid name</FormControl.Validation>
                      )}
                      <FormControl.Caption>Please enter a name (3 to 255 characters).</FormControl.Caption>
                    </FormControl>
                    <FormControl required>
                      <FormControl.Label>Group</FormControl.Label>
                      {deviceGroups.length > 0 ? (
                        <>
                          <Select
                            sx={{ width: '100%' }}
                            value={deviceGroupValue}
                            onChange={handleDeviceGroupChange}
                          >
                            <Select.Option value='choose_device_group' disabled>
                              Choose device group
                            </Select.Option>
                            {deviceGroups.map(deviceGroup => (
                              <Select.Option key={deviceGroup.id} value={deviceGroup.id}>
                                {deviceGroup.name}
                              </Select.Option>
                            ))}
                          </Select>
                          {validationDeviceGroupResult === 'empty' && (
                            <FormControl.Validation variant='error'>Device group cannot be empty</FormControl.Validation>
                          )}
                          {validationDeviceGroupResult === 'valid' && (
                            <FormControl.Validation variant='success'>Valid device group</FormControl.Validation>
                          )}
                        </>
                      ) : (
                        <Text sx={{ color: 'attention.emphasis' }}> <Icons.Alert /> No device groups found</Text>
                      )}
                    </FormControl>

                    <FormControl required id='autocompleteLabel'>
                      <FormControl.Label>Synced devices</FormControl.Label>
                      {isLoadingDeviceGroup && <Spinner size='small' />}
                      {!isLoadingDeviceGroup && (
                        <>
                          <Button
                            disabled={!selectedDeviceGroup}
                            variant='default'
                            ref={buttonRef}
                            trailingAction={() => (
                              <Label variant='accent'>
                                <Icons.Devices sx={{ width: 14, height: 14, mr: 1 }} />
                                {selectedDeviceIds.length}
                              </Label>
                            )}
                            onClick={() => {
                              handleOpen();
                            }}
                            sx={{ width: '100%', '[data-component=buttonContent]': { justifyContent: 'start' } }}
                          >
                            Select devices to sync
                          </Button>

                          <SelectPanel
                            title={title}
                            onSubmit={onSubmit}
                            open={isOpen}
                            variant='anchored'
                            anchorRef={buttonRef}
                            onCancel={() => {
                              setIsOpen(!isOpen);
                            }}
                            onClearSelection={onClearSelection}
                          >
                            <SelectPanel.Header>
                              <SelectPanel.SearchInput onChange={onSearchInputChange} />
                            </SelectPanel.Header>

                            {itemsToShow.length === 0 && !fetchError && (
                              <SelectPanel.Message variant='empty' title={notFound}>
                                Try a different search term
                              </SelectPanel.Message>
                            )}

                            {itemsToShow.length > 0 && !isLoading && !fetchError && (
                              <Box sx={{ height: '200px', overflowY: 'auto' }} onScroll={handleScroll}>
                                <ActionList>
                                  {itemsToShow.map(device => (
                                    <ActionList.Item
                                      key={device.id}
                                      onSelect={() => onDeviceSelect(device.id)}
                                      selected={selectedDeviceIds.includes(device.id)}
                                    >
                                      <ActionList.LeadingVisual>
                                        <Box sx={{ width: 14, height: 14, borderRadius: '100%' }} style={{ backgroundColor: `#${device.color}` }} />
                                      </ActionList.LeadingVisual>
                                      {device.name.length > 25 ? `${device.name.slice(0, 25)}...` : device.name}
                                    </ActionList.Item>
                                  ))}
                                </ActionList>
                              </Box>
                            )}

                            {isLoading && (
                              <Box sx={{ display: 'flex', color: 'fg.default', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                                <Spinner />
                              </Box>
                            )}

                            {fetchError && (
                              <SelectPanel.Message variant='error' title='Error fetching devices'>
                                Unable to fetch devices
                              </SelectPanel.Message>
                            )}
                          </SelectPanel>
                        </>
                      )}
                    </FormControl>

                    <FormControl required>
                      <FormControl.Label>Restart</FormControl.Label>
                      <Select
                        value={restartValue}
                        onChange={e => setRestartValue(e.target.value)}
                        sx={{ width: '100%' }}
                      >
                        <Select.Option value='hourly'>Hourly</Select.Option>
                        <Select.Option value='daily'>Daily</Select.Option>
                        <Select.Option value='never'>Never</Select.Option>
                      </Select>
                    </FormControl>

                    <Box sx={{ display: 'flex', gap: 3, justifyContent: 'flex-end', mt: 4 }}>
                      <Button
                        variant='primary'
                        disabled={isLoading}
                        onClick={() => {
                          handleSubmitCreateSyncRule();
                        }}
                        sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '65px' }}
                      >
                        {isLoading ? (
                          <Box sx={{ display: 'flex', justifyContent: 'center', width: '47px' }}>
                            <Spinner size='small' />
                          </Box>
                        ) : (
                          <Text>Submit</Text>
                        )}
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
