import { useEffect, useCallback, useRef, useState } from 'react'
import { useAuth } from '@/contexts/AuthContext'
import {
  ActionList,
  ActionMenu,
  Avatar,
  Text,
  Box,
  Truncate,
  Dialog,
  Button,
  FormControl,
  TextInput,
  Label,
  Spinner,
} from '@primer/react'
import { Icons } from '@/components/icons'
import { swapOrganization, createOrganization } from '@/lib/calls'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

export function OrgSwitcher() {
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const buttonRef = useRef()
  const onDialogClose = useCallback(() => {
    if (!isLoading) {
      setIsOpen(false)
    }
  }, [isLoading])
  //   const onDialogClose = useCallback(() => setIsOpen(false), []);

  const [value, setValue] = useState('')
  const [validationResult, setValidationResult] = useState('')

  const handleInputChange = e => {
    setValue(e.currentTarget.value)
  }

  const handleSubmit = async () => {
    if (validationResult === 'validName') {
      try {
        setIsLoading(true)
        await createOrganization(value)
        toast.custom(t => (
          <CustomToast message='Organization created' type='success' />
        ))
        setIsLoading(false)
        // Handle success - e.g., show a success message
        onDialogClose() // Close the dialog if successful
      } catch (error) {
        setIsLoading(false)
        // Handle errors - e.g., show error message
        if (error.response.status === 500) {
          if (
            error.response.data.detailed_msg ===
            'the owner must have at least one active subscription'
          ) {
            toast.custom(t => (
              <CustomToast message='Subscription required' type='error' />
            ))
          }
        }

        toast.custom(t => (
          <CustomToast message='Error creating new organization' type='error' />
        ))

        // console.log(error);
        console.error('Error creating organization:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    if (value.length === 0) {
      setValidationResult('empty')
    } else if (value.length > 255) {
      setValidationResult('tooLong')
    } else if (value.length < 3) {
      setValidationResult('tooShort')
    } else {
      setValidationResult('validName')
    }
  }, [value])

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'canvas.overlay',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <Spinner size='medium' />
        </Box>
      )}
      <ActionMenu>
        <ActionMenu.Button>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '195px', // Adjust the width as needed
            }}
          >
            <Avatar
              size='14'
              src={`https://avatar.vercel.sh/${user.current_org.org.id}.png`}
              sx={{ flexShrink: 0 }}
            />
            <Box sx={{ mx: 2, flex: '1 1 auto', minWidth: 0 }}>
              <Truncate title={user.current_org.org.name}>
                {user.current_org.org.name}
              </Truncate>
            </Box>
            <Text
              sx={{
                fontSize: 0,
                color: 'fg.muted',
                flexShrink: 0,
              }}
            >
              {user.current_org.org.owner_id === user.data.id
                ? 'Owner'
                : 'User'}
            </Text>
          </Box>
        </ActionMenu.Button>
        <ActionMenu.Overlay width='medium'>
          <ActionList role='menu' showDividers aria-label='Organizations'>
            <ActionList.Group title='Organizations' selectionVariant='single'>
              {user.orgs.map(org => (
                <ActionList.Item
                  key={org.id}
                  selected={org.id === user.current_org.org.id}
                  onSelect={() => {
                    swapOrganization(user.data.id, org.id, false)
                    setIsLoading(true)
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      // width: '300px' // Adjust the width as needed
                    }}
                  >
                    {/* {org.id === user.current_org.org.id ? ( */}
                    {/*   <Box */}
                    {/*     // size='16' */}
                    {/*     sx={{ */}
                    {/*       color: 'success.fg', */}
                    {/*       height: '20px', */}
                    {/*       width: '5px', */}
                    {/*       borderRadius: 1, */}
                    {/*       backgroundColor: 'accent.fg', */}
                    {/*       mr: '20px', */}
                    {/*     }} */}
                    {/*   ></Box> */}
                    {/* ) : ( */}
                    {/*   <Box sx={{ width: '24px' }} /> */}
                    {/* )} */}

                    <Avatar
                      size='14'
                      src={`https://avatar.vercel.sh/${org.id}.png`}
                      sx={{ flexShrink: 0 }}
                    />
                    <Box sx={{ mx: 2, flex: '1 1 auto', minWidth: 0 }}>
                      <Truncate title={org.name}>{org.name}</Truncate>
                    </Box>
                    {org.stripe_subscription_started_at && (
                      <Label
                        variant='done'
                        sx={{
                          ml: 1,
                          mr: 2,
                        }}
                      >
                        PRO{' '}
                      </Label>
                    )}

                    <Text
                      sx={{
                        fontSize: 0,
                        color: 'fg.muted',
                        flexShrink: 0,
                        mr: 2,
                      }}
                    >
                      {org.owner_id === user.data.id ? 'Owner' : 'User'}
                    </Text>
                  </Box>
                </ActionList.Item>
              ))}
              {/* <pre>{JSON.stringify(authProvider, null, 2)}</pre> */}
              <ActionList.Divider />
              <ActionList.Item onClick={() => setIsOpen(true)}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                  }}
                >
                  <Icons.Add size='24' />
                  <Text>Create new organization</Text>
                </Box>
              </ActionList.Item>
            </ActionList.Group>
          </ActionList>
        </ActionMenu.Overlay>
      </ActionMenu>
      {isOpen && (
        <Dialog
          isOpen={isOpen}
          onDismiss={onDialogClose}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Create new organization</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' color='fg.muted'>
              A new organization will help you collaborate and manage devices
              content in another isolated environment.
            </Text>
            <Box mt={3}>
              <FormControl required>
                <FormControl.Label>Name</FormControl.Label>
                <TextInput block value={value} onChange={handleInputChange} />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'tooLong' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be longer than 255 characters
                  </FormControl.Validation>
                )}
                {validationResult === 'tooShort' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be less than 3 characters
                  </FormControl.Validation>
                )}
                {validationResult === 'validName' && (
                  <FormControl.Validation variant='success'>
                    Valid name
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a name (3 to 255 characters).
                </FormControl.Caption>
              </FormControl>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogClose}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleSubmit()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
              {/* <Button onClick={handleSubmit} variant="primary">Submit</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  )
}
