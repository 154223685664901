import { Flash } from '@primer/react'

export function CustomToast({ message, type }) {
  return (
    <>
      {type === 'error' && (
       <Flash variant="danger">{message}</Flash>
      )}
      {type === 'success' && (
        <Flash variant="success">{message}</Flash>
      )}
      {type === 'warning' && (
        <Flash variant="warning">{message}</Flash>
      )}
    </>
  )
}
