import React, { useState, useRef, useEffect } from 'react'
import {
  Text,
  Box,
  Button,
  FormControl,
  TextInput,
  PageHeader,
  Spinner,
  Dialog,
  LinkButton,
  RelativeTime,
} from '@primer/react'
import { Hidden } from '@primer/react/drafts'
import { putNewApiKey, getApiKeys, deleteApiKey } from '@/lib/calls'
import { Icons } from '@/components/icons'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

export function ApiKeysPage() {
  const [isOpenDeleteApiKeyDialog, setIsOpenDeleteApiKeyDialog] =
    useState(false)
  const onDialogCloseDeleteApiKeyDialog = () =>
    setIsOpenDeleteApiKeyDialog(false)
  const [isLoadingDeleteApiKey, setIsLoadingDeleteApiKey] = useState(false)
  const [apiKeyIdToDelete, setApiKeyIdToDelete] = useState(null)

  const handleSubmitDeleteApiKey = async () => {
    setIsLoadingDeleteApiKey(true)
    try {
      await deleteApiKey(apiKeyIdToDelete)
      setIsLoadingDeleteApiKey(false)
      setIsOpenDeleteApiKeyDialog(false)
      toast.custom(t => <CustomToast message='Key deleted' type='success' />)
      setIsLoadingDeleteApiKey(false)
      setReloadContent(!reloadContent)
    } catch (error) {
      setIsLoadingDeleteApiKey(false)
      setIsOpenDeleteApiKeyDialog(false)
      toast.custom(t => (
        <CustomToast message='Error could not delete api key' type='error' />
      ))
      console.error('Error deleting api key:', error)
    }
  }

  const buttonRef = useRef(null)
  const [reloadContent, setReloadContent] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)
  const returnFocusRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [apiKey, setApiKey] = useState('')
  const [apiKeys, setApiKeys] = useState([])
  const [fetchError, setFetchError] = useState(null)

  const handleIsOpen = async () => {
    // console.log('inisopen')
    setIsLoading(true)
    setCopiedToClipboard(false)
    setIsOpen(true)
    try {
      const response = await putNewApiKey()
      // console.log(response.data)
      setApiKey(response.data)
      setIsLoading(false)
      setReloadContent(!reloadContent)
    } catch (error) {
      // console.log(error)
      setFetchError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await getApiKeys()
        // console.log(response.data)
        setApiKeys(response.data)
        setIsLoading(false)
      } catch (error) {
        // console.log(error)
        setFetchError(true)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [reloadContent])

  return (
    <>
      <Box sx={{}}>
      <Box sx={{
        padding: 3
      }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Developers API</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Actions>
              <Hidden when={['narrow']}>
              <Button
              variant='default'
              data-testid='trigger-button'
              ref={returnFocusRef}
              onClick={() => handleIsOpen()}
            >
              New api key
            </Button>
              </Hidden>
              <Hidden when={['regular', 'wide']}>
              <Button
              variant='default'
              data-testid='trigger-button'
              ref={returnFocusRef}
              onClick={() => handleIsOpen()}
            >
              New
            </Button>
              </Hidden>
            </PageHeader.Actions>
          </PageHeader>
        </Box> 
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            width: '100%',
            height: '100%',
            marginTop: 4,
            // backgroundColor: 'canvas.default',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              // backgroundColor: 'green'
            }}
          >
            {/* <EmptyState /> */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Box>
                {/* <pre>{JSON.stringify(apiKeys, null, 2)}</pre> */}
                {isLoading && !fetchError && (
                  <Box
                    sx={{
                      display: 'flex',
                      color: 'fg.default',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 200,
                    }}
                  >
                    <Spinner />
                  </Box>
                )}

                {fetchError && !isLoading && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      position: 'relative',
                      // backgroundColor: 'canvas.default',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: 3,
                      mt: 4,
                    }}
                  >
                    <Text
                      sx={{
                        fontSize: 4,
                        fontWeight: 'bold',
                        // color: 'danger.',
                        textAlign: 'center',
                      }}
                    >
                      An error occured fetching content.
                    </Text>
                    <Text
                      sx={{
                        fontSize: 1,
                        color: 'fg.muted',
                      }}
                    >
                      <Icons.Alert /> Please try again later.
                    </Text>
                  </Box>
                )}

                {apiKeys.length === 0 && !fetchError && !isLoading && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      position: 'relative',
                      // backgroundColor: 'canvas.default',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: 3,
                    }}
                  >
                    <Text
                      sx={{
                        fontSize: 4,
                        fontWeight: 'bold',
                      }}
                    >
                      No api keys found.
                    </Text>
                    <Text
                      sx={{
                        fontSize: 1,
                        color: 'fg.muted',
                      }}
                    >
                      Generate a new api key.
                    </Text>
                  </Box>
                )}

                {apiKeys.length > 0 && !fetchError && !isLoading && (
                  <Box
                    sx={{
                      // backgroundColor: 'canvas.inset',
                      maxHeight: '500px',
                      overflow: 'auto',
                    }}
                  >
                    {apiKeys.map(key => (
                      <Box
                        key={key.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                          mt: 3,
                          width: '100%',
                          // backgroundColor: 'canvas.inset',
                          borderRadius: 2,
                          border: '1px solid',
                          borderColor: 'border.default',
                          p: 3,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'start',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Icons.Key
                              sx={{
                                color: 'fg.muted',
                              }}
                            />
                            <Text
                              sx={{
                                fontSize: 14,
                                fontWeight: 'semibold',
                                // color: 'fg.default',
                              }}
                            >
                              Expire
                              {new Date(key.expires_at) < new Date()
                                ? 'd'
                                : 's'}{' '}
                              <RelativeTime date={new Date(key.expires_at)} />
                            </Text>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 2,
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              variant='danger'
                              onClick={() => {
                                setIsOpenDeleteApiKeyDialog(true)
                                setApiKeyIdToDelete(key.id)
                              }}
                            >
                              <Icons.Trash />
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    // backgroundColor: "danger.emphasis",
                    alignItems: 'start',
                    mt: 4,
                    gap: 2,
                  }}
                >
                  <LinkButton
                    href='https://docs.signgrid.io/api-v1/api-documentation'
                    variant='invisible'
                  >
                    API guide
                  </LinkButton>

                  <LinkButton
                    href='https://api.signgrid.io/api/v1/docs'
                    variant='invisible'
                  >
                    Swagger UI
                  </LinkButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        returnFocusRef={returnFocusRef}
        aria-labelledby='dialog-label'
        sx={{
          width: '635px',
        }}
      >
        <Dialog.Header>Key generated</Dialog.Header>
        <Box p={3}>
          <Text id='dialog-label' color='fg.muted' fontSize={1}>
            Your api key for this organization was generated successfully. This
            is the last time you can see the key. Make sure to keep it safe.
          </Text>
          {/* {deviceIdToDelete} */}
          <Box mt={3}>
            {isLoading && <Spinner />}
            {fetchError && (
              <Box
                color='danger.fg'
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                <Icons.Alert />
                <Text>An error occurred while fetching the api key</Text>
              </Box>
            )}
            {!isLoading && !fetchError && apiKey && (
              <>
                <FormControl>
                  <FormControl.Label visuallyHidden>API key</FormControl.Label>
                  {/* <pre>{JSON.stringify(apiKey.api_key, null, 2)}</pre> */}
                  <TextInput
                    readOnly
                    width='100%'
                    trailingAction={
                      <TextInput.Action
                        onClick={() => {
                          // setValue()
                          // copy to clipboard api key
                          navigator.clipboard.writeText(apiKey.api_key)
                          setCopiedToClipboard(true)
                        }}
                        icon={Icons.Copy}
                        aria-label='Clear input'
                        sx={{ color: 'fg.subtle' }}
                      />
                    }
                    value={apiKey.api_key}
                  // onChange={handleChange}
                  />
                  {copiedToClipboard && (
                    <FormControl.Validation variant='success'>
                      Copied to clipboard
                    </FormControl.Validation>
                  )}
                </FormControl>
              </>
            )}
          </Box>
          <Box display='flex' mt={3} justifyContent='flex-end'>
            <Button
              onClick={() => setIsOpen(false)}
              sx={{ mr: 1 }}
              variant='invisible'
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Dialog>
      {isOpenDeleteApiKeyDialog && (
        <Dialog
          isOpen={isOpenDeleteApiKeyDialog}
          onDismiss={onDialogCloseDeleteApiKeyDialog}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Delete api key</Dialog.Header>
          <Box p={3}>
            <Text fontSize={1} id='dialog-label' color='fg.muted'>
              This action can't be undone. Are you sure you want to delete this
              api key?
            </Text>
            {/* {deviceIdToDelete} */}
            <Box mt={3}></Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogCloseDeleteApiKeyDialog}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='danger'
                disabled={isLoadingDeleteApiKey}
                onClick={() => {
                  handleSubmitDeleteApiKey()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '120px', // Set a fixed width
                }}
              >
                {isLoadingDeleteApiKey ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Delete api key</Text>
                )}
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  )
}
