import { Icons } from '@/components/icons'

export const sidebarNavItems = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: Icons.Graph,
  },
  {
    name: 'Downloads',
    path: '/dashboard/downloads',
    icon: Icons.Download,
  },
  {
    name: 'Content',
    path: '/dashboard/content',
    icon: Icons.FileMedia,
  },
  {
    name: 'Devices',
    path: '/dashboard/devices',
    icon: Icons.Device,
  },
  {
    name: 'Device groups',
    path: '/dashboard/device-groups',
    icon: Icons.Devices,
  },
  {
    name: 'Playlists',
    path: '/dashboard/playlists',
    icon: Icons.Play,
  },
  {
    name: 'Schedules',
    path: '/dashboard/schedules',
    icon: Icons.Calendar,
  },
  {
    name: 'Sync',
    path: '/dashboard/sync',
    icon: Icons.Sync,
  },
  {
    name: 'Apps',
    path: '/dashboard/apps',
    icon: Icons.Apps,
  },
  {
    name: 'Alerts',
    path: '/dashboard/alerts',
    icon: Icons.Unread,
  },
]

export const sidebarSettingsNavItems = [
  {
    name: 'Account settings',
    path: '/dashboard/settings/account',
    icon: Icons.Person,
  },
  {
    name: 'Billing',
    path: '/dashboard/settings/billing',
    icon: Icons.CreditCard,
  },
  {
    name: 'Team management',
    path: '/dashboard/settings/team',
    icon: Icons.People,
  },
  {
    name: 'Roles & Permissions',
    path: '/dashboard/settings/roles',
    icon: Icons.Shield,
  },
  {
    name: 'Organization',
    path: '/dashboard/settings/organization',
    icon: Icons.People,
  },
  {
    name: 'Developers',
    path: '/dashboard/settings/developers/api',
    icon: Icons.Code,
  },
]
