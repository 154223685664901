import Stripe from 'stripe'

const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY
const stripeApiVersion = process.env.REACT_APP_STRIPE_API_VERSION

if (!stripeApiKey) {
  throw new Error('Missing STRIPE_API_KEY environment variable')
}

if (!stripeApiVersion) {
  throw new Error('Missing STRIPE_API_VERSION environment variable')
}

// console.log('Initializing stripe with api key:', stripeApiKey)
// console.log('Initializing stripe with api version:', stripeApiVersion)
// console.log(new Stripe(stripeApiKey, { apiVersion: stripeApiVersion }))
// console.log(new Stripe(stripeApiKey))

export const stripe = new Stripe(stripeApiKey, {
  apiVersion: stripeApiVersion,
  typescript: true,
})
