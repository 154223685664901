import React from 'react'
import { LineChart } from '@tremor/react'
import '../output.css'

interface DeviceCountByDay {
  date: string
  device_count: number
  org_id: string
  event_id: number
}

interface MetricsEnrollmentsProps {
  data: DeviceCountByDay[]
}

const getLast7Days = () => {
  return Array.from(
    { length: 7 },
    (_, index) =>
      new Date(Date.now() - 86400000 * index).toISOString().split('T')[0]
  ).reverse()
}

const processChartData = (data: DeviceCountByDay[]) => {
  const last7Days = getLast7Days()

  // Initialize an object to hold counts of device_count for each day
  const countsByDay: Record<string, number> = last7Days.reduce((acc, day) => {
    acc[day] = 0
    return acc
  }, {})

  data.forEach(({ date, device_count }) => {
    const day = date.split('T')[0]
    if (countsByDay.hasOwnProperty(day)) {
      countsByDay[day] = device_count
    }
  })

  // Convert the object to the format expected by LineChart
  return Object.entries(countsByDay).map(([date, count]) => ({
    date,
    Enrollments: count,
  }))
}

export const MetricsEnrollments: React.FC<MetricsEnrollmentsProps> = ({
  data,
}) => {
  const chartData = processChartData(data)

  return (
    <>
      <LineChart
        data={chartData}
        index='date'
        categories={['Enrollments']}
        colors={['blue']}
      />
    </>
  )
}
