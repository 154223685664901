import React, { useEffect, useState } from 'react'
// import ErrorBoundary from './ErrorBoundary';
import { ThemeProvider, BaseStyles, Spinner, Box } from '@primer/react'
import { theme } from '@primer/react'
import {
  // Link,
  RouterProvider,
} from 'react-router-dom'
import { router } from '@/lib/router'

function App() {
  const [colorMode, setColorMode] = useState('day')

  useEffect(() => {
    document.body.style.backgroundColor =
      theme.colorSchemes.dark.colors.canvas.subtle
  }, [])

  useEffect(() => {
    // Attempt to fetch existing theme preference from localStorage
    const storedThemePreference = localStorage.getItem('themePreference')

    if (storedThemePreference) {
      setColorMode(storedThemePreference)
      document.body.style.backgroundColor =
        storedThemePreference === 'night'
          ? theme.colorSchemes.dark.colors.canvas.subtle
          : theme.colorSchemes.light.colors.canvas.subtle // Adjust this according to the actual theme object structure
    } else {
      // Set default theme here if not set in localStorage
      localStorage.setItem('themePreference', 'day')
      document.body.style.backgroundColor =
        theme.colorSchemes.light.colors.canvas.subtle
    }
  }, [])

  return (
    <ThemeProvider colorMode={colorMode}>
      <BaseStyles>
        <Box
          sx={{
            display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            height: '100vh',
            width: '100vw',
            backgroundColor: 'canvas.default',
            color: 'fg.default',
          }}
        >
          <RouterProvider
            router={router}
            fallbackElement={
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  backgroundColor: 'canvas.subtle',
                  justifyContent: 'center',
                  placeItems: 'center',
                }}
              >
                <Spinner size='small' />
              </Box>
            }
          />
        </Box>
      </BaseStyles>
    </ThemeProvider>
  )
}

export default App
