import { useEffect, useCallback, useRef, useState } from 'react'
import {
  Text,
  Box,
  Button,
  Dialog,
  FormControl,
  TextInput,
  Select,
  PageHeader,
  Tooltip,
  AvatarToken,
  Label,
  ActionMenu,
  ActionList,
  IconButton,
  Spinner,
} from '@primer/react'
import { Link } from 'react-router-dom'
import { Icons } from '@/components/icons'
import {
  inviteUser,
  updateUserRole,
  deleteUserFromOrganization,
} from '@/lib/calls'
import { currentAuthenticatedUser } from '@/lib/auth'
import {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
} from '@/components/ui/card-primer'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

export function TeamPage() {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const buttonRef = useRef()
  const onDialogClose = useCallback(() => {
    if (!isLoading) {
      setIsOpen(false)
    }
  }, [isLoading])
  const [selectedRoleId, setSelectedRoleId] = useState('')
  const [selectedRole, setSelectedRole] = useState('choose_role')
  const [localProvider, setLocalProvider] = useState(null)
  const [reloadTable, setReloadTable] = useState(false)

  const [isOpenDeleteUser, setIsOpenDeleteUser] = useState(false)
  const onDialogDeleteUserClose = useCallback(() => {
    if (!isLoading) {
      setIsOpenDeleteUser(false)
    }
  }, [isLoading])

  const [isOpenChangeUserRole, setIsOpenChangeUserRole] = useState(false)
  const onDialogChangeUserRoleClose = useCallback(() => {
    if (!isLoading) {
      setIsOpenChangeUserRole(false)
    }
  }, [isLoading])

  const [deletedUserId, setDeletedUserId] = useState('')
  const [orgId, setOrgId] = useState('')
  const [changedUserId, setChangedUserId] = useState('')

  // const [userRoleChanged, setUserRoleChanged] = useState(false);

  const [value, setValue] = useState('')
  const [validationResult, setValidationResult] = useState('')

  const [roleValidationResult, setRoleValidationResult] = useState('')

  const handleOpenDeleteUserDialog = (userId, orgId) => {
    // alert(userId);
    // console.log(userId)
    setDeletedUserId(userId)
    setOrgId(orgId)
    setIsOpenDeleteUser(true)
  }

  const handleSubmitDeleteUser = async () => {
    try {
      setIsLoading(true)
      await deleteUserFromOrganization(deletedUserId, orgId)
      toast.custom(t => <CustomToast message='User deleted' type='success' />)
      setIsOpenDeleteUser(false)
      setReloadTable(true)
      setIsLoading(false)
      // Handle success - e.g., show a success message
    } catch (error) {
      // Handle errors - e.g., show error message
      setIsLoading(false)
      toast.custom(t => (
        <CustomToast message='Error deleting user' type='error' />
      ))

      // console.log(error)
      console.error('Error deleting user:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOpenChangeUserRoleDialog = userId => {
    // alert(userId);
    // console.log(userId)
    setChangedUserId(userId)
    setSelectedRole('choose_role')
    setSelectedRoleId('')
    // setRoleValidationResult('');
    setIsOpenChangeUserRole(true)
  }

  const handleChangeUserRole = event => {
    // alert('change user role')
    // console.log(event)
    // const selectedRole = event.target.value;
    setSelectedRole(event.currentTarget.value)
    setSelectedRoleId(event.currentTarget.value)
    setRoleValidationResult('validSelection')
    // setIsOpenChangeUserRole(false);
  }

  const handleSubmitChangeUserRole = async () => {
    if (roleValidationResult === 'validSelection') {
      // alert("should submit")
      handlePatchUserRole(selectedRoleId, changedUserId)
    }
  }

  const handleOpenInviteDialog = () => {
    setValue('')
    setSelectedRole('choose_role')
    setSelectedRoleId('')
    // setValidationResult('');
    setIsOpen(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await currentAuthenticatedUser()
        setLocalProvider(user)
        // console.log(user)
      } catch (err) {
        console.error('Error fetching user data:', err)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (reloadTable) {
        try {
          const user = await currentAuthenticatedUser()
          setLocalProvider(user)
          setReloadTable(false)
        } catch (err) {
          console.error('Error reloading data:', err)
        }
      }
    }

    fetchData()
  }, [reloadTable])

  // const isValidEmail = (email) => {
  //   const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  //   return emailRegex.test(email);
  // };

  const isValidEmail = email => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
    return emailRegex.test(email)
  }

  const handleRoleChange = event => {
    // console.log(event)
    // const selectedRole = event.target.value
    setSelectedRole(event.currentTarget.value)
    setSelectedRoleId(event.currentTarget.value)
    setRoleValidationResult('validSelection')
    // console.log(selectedRole)
  }

  const handleInputChange = e => {
    setValue(e.currentTarget.value)
  }

  const handleSubmit = async (roleId, orgId) => {
    if (validationResult === 'validEmail') {
      try {
        if (roleId === '') {
          return toast.custom(t => (
            <CustomToast message='Please choose a role' type='error' />
          ))
        }

        setIsLoading(true)
        // console.log(roleId, orgId, value)
        await inviteUser(value, roleId, orgId)
        toast.custom(t => (
          <CustomToast message='Invitation sent' type='success' />
        ))
        // Handle success - e.g., show a success message
        setIsLoading(false)
        onDialogClose() // Close the dialog if successful
      } catch (error) {
        // Handle errors - e.g., show error message
        setIsLoading(false)
        toast.custom(t => (
          <CustomToast message='Error creating a new invitation' type='error' />
        ))

        // console.log(error)
        console.error('Error creating organization:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handlePatchUserRole = async (roleId, userId) => {
    try {
      if (roleId === '') {
        return toast.custom(t => (
          <CustomToast message='Please choose a role' type='error' />
        ))
      }

      setIsLoading(true)

      await updateUserRole(roleId, userId)
      setReloadTable(true)
      toast.custom(t => <CustomToast message='Role changed' type='success' />)
      setIsLoading(false)

      setIsOpenChangeUserRole(false)

      // setUserRoleChanged(true);
      // Handle success - e.g., show a success message
    } catch (error) {
      setIsLoading(false)
      // Handle errors - e.g., show error message

      toast.custom(t => (
        <CustomToast message='Error could not change role' type='error' />
      ))

      // console.log(error)
      console.error('Error changing role:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    // console.log(selectedRoleId)
    if (selectedRoleId === '') {
      setRoleValidationResult('noSelection')
    } else {
      setRoleValidationResult('validSelection')
    }
    if (value.length === 0) {
      setValidationResult('empty')
    } else if (!isValidEmail(value)) {
      setValidationResult('wrongFormat')
    } else {
      setValidationResult('validEmail')
    }
  }, [value, selectedRoleId])

  return (
    <>
      <Box sx={{}}>
      <Box sx={{ padding: 3 }}>
        <PageHeader>
          <PageHeader.TitleArea>
            <PageHeader.Title as="h2">Team management</PageHeader.Title>
          </PageHeader.TitleArea>
          <PageHeader.Actions>
          <Button variant='primary' onClick={() => handleOpenInviteDialog()}>
              Invite user
            </Button>
          </PageHeader.Actions>
        </PageHeader>
        </Box>
        <Box
          sx={{
            display: 'flex',
            /* flexDirection: ["column", "row"], */
            width: '100%',
            height: '100%',
            marginTop: 4,
            // backgroundColor: 'canvas.default',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            {!localProvider ? (
              <Card>
                <CardHeader>
                  <CardTitle></CardTitle>
                  <CardDescription></CardDescription>
                </CardHeader>
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // height: '382px',
                    }}
                  >
                    <Spinner />
                  </Box>
                </CardContent>
                <CardFooter></CardFooter>
              </Card>
            ) : (
              <>
                <Card
                  sx={{
                    width: '100%',
                    // height: '450px',
                    overflow: 'auto',
                  }}
                >
                  <CardHeader>
                    <CardTitle>Users</CardTitle>

                    <CardDescription>Your team members</CardDescription>
                  </CardHeader>
                  <CardContent>
                    {localProvider.user?.current_org.members.map(
                      (member, idx) => (
                        <Box
                          key={idx}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            // justifyContent: '',
                            flexDirection: 'row',
                            mt: 2,
                            gap: 3,
                          }}
                        >
                          {/* <pre>{JSON.stringify(member, null, 2)}</pre> */}
                          <Box
                            as='form'
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              // flexDirection: 'row',
                              gap: 1,
                            }}
                          >
                            <AvatarToken
                              size='large'
                              text={`${member.user.email}`}
                              avatarSrc={member.user.picture}
                            />
                            {member.user.username && (
                              <Tooltip aria-label={member.user.username}>
                                <Label size='small' variant='accent'>
                                  {member.user.username
                                    ? member.user.username.length > 15
                                      ? member.user.username.slice(0, 12) +
                                      '...'
                                      : member.user.username
                                    : ''}
                                </Label>
                              </Tooltip>
                            )}
                            <Tooltip aria-label={member.role.name}>
                              <Label size='small' variant='success'>
                                {member.role.name
                                  ? member.role.name.length > 30
                                    ? member.role.name.slice(0, 30) + '...'
                                    : member.role.name
                                  : 'No role'}
                              </Label>
                            </Tooltip>
                          </Box>

                          <ActionMenu>
                            <ActionMenu.Anchor>
                              {/* <Button variant="invisible"><Icons.Menu /></Button> */}
                              <IconButton
                                variant='invisible'
                                icon={Icons.Menu}
                                aria-label=''
                              />
                            </ActionMenu.Anchor>
                            <ActionMenu.Overlay width='medium'>
                              <ActionList>
                                <ActionList.Item
                                  onSelect={() =>
                                    handleOpenChangeUserRoleDialog(
                                      member.user.id
                                    )
                                  }
                                >
                                  Change role
                                </ActionList.Item>
                                <ActionList.Divider />
                                <ActionList.Item
                                  variant='danger'
                                  onSelect={() =>
                                    handleOpenDeleteUserDialog(
                                      member.user.id,
                                      localProvider.user?.current_org.org.id
                                    )
                                  }
                                >
                                  Delete user
                                </ActionList.Item>
                              </ActionList>
                            </ActionMenu.Overlay>
                          </ActionMenu>
                        </Box>
                      )
                    )}
                  </CardContent>
                  <CardFooter></CardFooter>
                </Card>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {isOpen && (
        <Dialog
          isOpen={isOpen}
          onDismiss={onDialogClose}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Invite a new user</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' sx={{ color: 'fg.muted' }} fontSize={1}>
              Invited users will have access to the resources you have assigned
              based on the selected role. Invitation expires in 30 minutes.
            </Text>
            <Box mt={3}>
              <FormControl required>
                <FormControl.Label>Email</FormControl.Label>
                <TextInput block value={value} onChange={handleInputChange} />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Email cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'wrongFormat' && (
                  <FormControl.Validation variant='error'>
                    Email is invalid
                  </FormControl.Validation>
                )}
                {validationResult === 'validEmail' && (
                  <FormControl.Validation variant='success'>
                    Valid email
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a valid email address.
                </FormControl.Caption>
              </FormControl>
              <FormControl sx={{ mt: 2 }} required>
                <FormControl.Label>Role</FormControl.Label>
                {localProvider &&
                  localProvider.user?.current_org.roles.length === 1 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                    }}
                  >
                    <Text sx={{ color: 'attention.emphasis' }}>
                      {' '}
                      <Icons.Alert /> Additional role required. Go to
                    </Text>
                    <Link to='/dashboard/settings/roles'>
                      Roles & Permissions
                    </Link>
                  </Box>
                ) : (
                  <>
                    <Select
                      value={selectedRole}
                      onChange={handleRoleChange}
                      sx={{
                        width: '100%',
                      }}
                    >
                      <Select.Option value='choose_role' disabled>
                        Choose role
                      </Select.Option>
                      {localProvider &&
                        localProvider.user?.current_org.roles.map(role => (
                          <Select.Option key={role.id} value={role.id}>
                            {role.name}
                          </Select.Option>
                        ))}
                    </Select>
                    {roleValidationResult === 'noSelection' && (
                      <FormControl.Validation variant='error'>
                        Please select a role
                      </FormControl.Validation>
                    )}
                    {roleValidationResult === 'validSelection' && (
                      <FormControl.Validation variant='success'>
                        Role selected
                      </FormControl.Validation>
                    )}
                  </>
                )}
              </FormControl>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogClose}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleSubmit(
                    selectedRoleId,
                    localProvider.user?.current_org.org.id
                  )
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
              {/* <Button onClick={() => (handleSubmit(selectedRoleId, localProvider.user.current_org.org.id))} variant="primary">Send invitation</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}

      {isOpenDeleteUser && (
        <Dialog
          isOpen={isOpenDeleteUser}
          onDismiss={onDialogDeleteUserClose}
          // returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Are you sure?</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' sx={{ color: 'fg.muted' }} fontSize={1}>
              This action cannot be undone.
            </Text>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                variant='invisible'
                onClick={onDialogDeleteUserClose}
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button
                variant='danger'
                disabled={isLoading}
                onClick={() => {
                  handleSubmitDeleteUser()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '120px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Delete user</Text>
                )}
              </Button>
              {/* <Button type="submit" onClick={handleSubmitDeleteUser} variant="danger">Delete user</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}

      {isOpenChangeUserRole && (
        <Dialog
          isOpen={isOpenChangeUserRole}
          onDismiss={onDialogChangeUserRoleClose}
          // returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Change user role</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' sx={{ color: 'fg.muted' }} fontSize={1}>
              User roles allow the user to access various resources.
            </Text>
            <FormControl required>
              <FormControl.Label>Role</FormControl.Label>
              <Select
                value={selectedRole}
                onChange={handleChangeUserRole}
                sx={{
                  width: '100%',
                }}
              >
                <Select.Option value='choose_role' disabled>
                  Choose role
                </Select.Option>
                {localProvider &&
                  localProvider.user?.current_org.roles.map(role => (
                    <Select.Option key={role.id} value={role.id}>
                      {role.name}
                    </Select.Option>
                  ))}
              </Select>
              {roleValidationResult === 'noSelection' && (
                <FormControl.Validation variant='error'>
                  Please select a role
                </FormControl.Validation>
              )}
              {roleValidationResult === 'validSelection' && (
                <FormControl.Validation variant='success'>
                  Role selected
                </FormControl.Validation>
              )}
            </FormControl>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                variant='invisible'
                onClick={onDialogChangeUserRoleClose}
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleSubmitChangeUserRole()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
              {/* <Button type="submit" onClick={handleSubmitChangeUserRole} variant="primary">Change role</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  )
}
