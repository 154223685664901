/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useRef } from 'react'
import { Text, Box, NavList } from '@primer/react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  FormControl,
  TextInput,
  Button,
  PageHeader,
  Select,
  Spinner,
  AnchoredOverlay,
  Autocomplete,
  Tooltip,
  Dialog,
} from '@primer/react'
import { Icons } from './icons'
import {
  getSchedules,
  getDeviceGroups,
  getPlaylists,
  createSchedule,
  patchSchedule,
  deleteSchedule,
} from '@/lib/calls'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

export function SchedulesPage() {
  const [isOpen, setIsOpen] = useState(false)

  const inputRef = useRef()
  const scrollContainerRef = useRef()
  const [menuArgs] = useState({})
  const [textInputArgs] = useState({})

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleAutocompleteSelect = selectedItems => {
    if (selectedItems.length > 0) {
      const selectedItem = selectedItems[0]
      // handleItemClick(false, null, selectedItem.id)
      // alert(selectedItem.id)
      handleScheduleClick(selectedItem.id)
    }
  }


  const buttonRef = useRef()

  const [nameValue, setNameValue] = useState('')
  const [validationResult, setValidationResult] = useState('empty')

  const [validationStartDateTimeResult, setValidationStartDateTimeResult] =
    useState('empty')
  const [validationEndDateTimeResult, setValidationEndDateTimeResult] =
    useState('empty')
  const [startValue, setStartValue] = useState('')
  const [endValue, setEndValue] = useState('')

  const [deviceGroupValue, setDeviceGroupValue] = useState(
    'choose_device_group'
  )
  const [playlistValue, setPlaylistValue] = useState('choose_playlist')
  const [validationDeviceGroupResult, setValidationDeviceGroupResult] =
    useState('empty')
  const [validationPlaylistResult, setValidationPlaylistResult] =
    useState('empty')

  const handleInputChange = event => {
    setNameValue(event.target.value)
    if (event.target.value === '') {
      setValidationResult('empty')
    } else if (event.target.value.length > 255) {
      setValidationResult('tooLong')
    } else if (event.target.value.length < 3) {
      setValidationResult('tooShort')
    } else {
      setValidationResult('validName')
    }
  }

  const handleStartDateChange = event => {
    // console.log(event.target.value)
    setStartValue(event.target.value)
    if (event.target.value === '') {
      // setValidationDateTimeResult('empty');
      setValidationStartDateTimeResult('empty')
    } else {
      setValidationStartDateTimeResult('valid')
    }
  }

  const handleEndDateChange = event => {
    // console.log(event.target.value)
    setEndValue(event.target.value)
    if (event.target.value === '') {
      setValidationEndDateTimeResult('empty')
    } else {
      setValidationEndDateTimeResult('valid')
    }
  }

  const handleDeviceGroupChange = event => {
    // console.log(event.target.value)
    setDeviceGroupValue(event.target.value)
    if (event.target.value === '') {
      setValidationDeviceGroupResult('empty')
    } else {
      setValidationDeviceGroupResult('valid')
    }
  }

  const handlePlaylistChange = event => {
    // console.log(event.target.value)
    setPlaylistValue(event.target.value)
    if (event.target.value === '') {
      setValidationPlaylistResult('empty')
    } else {
      setValidationPlaylistResult('valid')
    }
  }

  const findSchedule = id => {
    return schedules.find(schedule => schedule.id === id)
  }

  const [reloadContent, setReloadContent] = useState(false)
  const [restricted, setRestricted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [paramError, setParamError] = useState(false)
  const navigate = useNavigate()
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const scheduleIdParam = query.get('schedule_id')

  const [schedulePreview, setSchedulePreview] = useState(false)
  const [scheduleId, setScheduleId] = useState(null)
  const [deviceGroups, setDeviceGroups] = useState([])
  const [playlists, setPlaylists] = useState([])

  const [schedules, setSchedules] = useState([])

  const handleScheduleClick = id => {
    setScheduleId(id)
    setSchedulePreview(true)
    navigate('?schedule_id=' + id)
  }
  // const schedulesMock = [
  //   { id: 1, name: "Schedule 1" },
  //   { id: 2, name: "Schedule 2" },
  //   { id: 3, name: "Schedule 3" },
  //   { id: 4, name: "Schedule 4" },
  //   { id: 5, name: "Schedule 5" },
  //   { id: 6, name: "Schedule 6" },
  //   { id: 7, name: "Schedule 7" },
  //   { id: 8, name: "Schedule 8" },
  //   { id: 9, name: "Schedule 9" },
  //   { id: 10, name: "Schedule 10" },
  //   { id: 11, name: "Schedule 11" },
  //   { id: 12, name: "Schedule 12" },
  //   { id: 13, name: "Schedule 13" },
  //   { id: 14, name: "Schedule 14" },
  //   { id: 15, name: "Schedule 15" },
  //   { id: 16, name: "Schedule 16" },
  //   { id: 17, name: "Schedule 17" },
  //   { id: 18, name: "Schedule 18" },
  //   { id: 19, name: "Schedule 19" },
  //   { id: 20, name: "Schedule 20" },
  //   { id: 21, name: "Schedule 21" },
  //   { id: 22, name: "Schedule 22" },
  //   { id: 23, name: "Schedule 23" },
  //   { id: 24, name: "Schedule 24" },
  // ];

  // const deviceGroups = [
  //   { id: 1, title: "Device group 1" },
  //   { id: 2, title: "Device group 2" },
  //   { id: 3, title: "Device group 3" },
  // ];

  // const playlists = [
  //   { id: 1, title: "Playlist 1" },
  //   { id: 2, title: "Playlist 2" },
  //   { id: 3, title: "Playlist 3" },
  // ];

  const [isOpenDeleteScheduleDialog, setIsOpenDeleteScheduleDialog] =
    useState(false)
  const onDialogCloseDeleteScheduleDialog = useCallback(() => {
    if (!isLoading) {
      setIsOpenDeleteScheduleDialog(false)
    }
  }, [isLoading])

  const handleSubmitCreateSchedule = async () => {
    if (
      validationResult === 'validName' &&
      validationStartDateTimeResult === 'valid' &&
      validationEndDateTimeResult === 'valid' &&
      validationDeviceGroupResult === 'valid' &&
      validationPlaylistResult === 'valid'
    ) {
      // alert(1);
      try {
        setIsLoading(true)
        const schedule = {
          name: nameValue,
          start: startValue + ':00.000Z',
          end: endValue + ':00.000Z',
          groupId: deviceGroupValue,
          playlistId: playlistValue,
        }
        const response = await createSchedule(schedule)
        toast.custom(t => (
          <CustomToast message='Schedule created' type='success' />
        ))
        // console.log(response.msg);
        setReloadContent(!reloadContent)
        // Delay setting isLoading to false and navigating
        setTimeout(() => {
          // Extract the ID using RegExp
          const idMatch = response.msg.match(/id\s([a-f\d-]+)/)
          if (idMatch && idMatch[1]) {
            // Navigate to the new URL with the extracted ID
            navigate('?schedule_id=' + idMatch[1])
          } else {
            console.error(
              'Failed to extract schedule ID from response message.'
            )
          }
        }, 1000) // Delay for 1 second

        // navigate("")
      } catch (error) {
        console.error(error)
        if (error.response.status === 403) {
          setRestricted(true)
        }
        toast.custom(t => (
          <CustomToast message='Error creating schedule' type='error' />
        ))
        // setFetchError(true);
        setIsLoading(false)
      } finally {
        // setIsLoading(false);
      }
    }
  }

  const handleSubmitUpdateSchedule = async () => {
    if (
      validationResult === 'validName' &&
      validationStartDateTimeResult === 'valid' &&
      validationEndDateTimeResult === 'valid' &&
      validationDeviceGroupResult === 'valid' &&
      validationPlaylistResult === 'valid'
    ) {
      // alert(1);
      try {
        setIsLoading(true)
        const schedule = {
          name: nameValue,
          start: startValue + ':00.000Z',
          end: endValue + ':00.000Z',
          groupId: deviceGroupValue,
          playlistId: playlistValue,
        }
        const response = await patchSchedule(schedule, scheduleId)
        toast.custom(t => (
          <CustomToast message='Schedule updated' type='success' />
        ))
        console.log(response)
        setIsLoading(false)
        setReloadContent(true)
      } catch (error) {
        console.error(error)
        if (error?.response?.status === 403) {
          setRestricted(true)
        }
        toast.custom(t => (
          <CustomToast message='Error updating schedule' type='error' />
        ))
        // setFetchError(true);
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleSubmitDeleteSchedule = async () => {
    try {
      setIsLoading(true)
      await deleteSchedule(scheduleId)
      // const response = await deleteSchedule(scheduleId)
      toast.custom(t => (
        <CustomToast message='Schedule deleted' type='success' />
      ))
      // console.log(response)
      setReloadContent(!reloadContent)
      setIsOpenDeleteScheduleDialog(false)
      navigate('?schedule_id=new')
      // window.location.href("?schedule_id=new");
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      if (error.response.status === 403) {
        setRestricted(true)
      }
      toast.custom(t => (
        <CustomToast message='Error deleting schedule' type='error' />
      ))
      // setFetchError(true);
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  // Helper function to format the date in the desired format
  const formatDate = dateStr => {
    // Parse the date
    const date = new Date(dateStr)
    // Format the date as "YYYY-MM-DDTHH:mm"
    return date.toISOString().slice(0, 16)
  }

  useEffect(() => {
    if (scheduleIdParam) {
      if (scheduleIdParam === 'new') {
        setNameValue('')
        setStartValue('')
        setEndValue('')
        setDeviceGroupValue('choose_device_group')
        setPlaylistValue('choose_playlist')
        setValidationResult('empty')
        setValidationStartDateTimeResult('empty')
        setValidationEndDateTimeResult('empty')
        setValidationDeviceGroupResult('empty')
        setValidationPlaylistResult('empty')
        // setReloadContent(true);
        setScheduleId(null)
        setSchedulePreview(true)
        return
      }

      const currentSchedule = findSchedule(scheduleIdParam)
      // console.log(currentSchedule)

      if (currentSchedule) {
        const adjustedStartValue = formatDate(currentSchedule.start_date)
        const adjustedEndValue = formatDate(currentSchedule.end_date)
        setStartValue(adjustedStartValue)
        setEndValue(adjustedEndValue)

        setNameValue(currentSchedule.name)
        // setStartValue(currentSchedule.start_date);
        setValidationResult('validName')
        setValidationStartDateTimeResult('valid')
        setValidationEndDateTimeResult('valid')
        setValidationDeviceGroupResult('valid')
        setValidationPlaylistResult('valid')
        // setEndValue(currentSchedule.end_date);
        setDeviceGroupValue(currentSchedule.group_id)
        setPlaylistValue(currentSchedule.playlist_id)
      } else {
        // navigate('/404');

        setParamError(true)
        // setFetchError(true);
      }

      setScheduleId(scheduleIdParam)
      setSchedulePreview(true)
    }
  }, [scheduleIdParam, schedules, paramError, reloadContent])

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        setIsLoading(true)
        const schedules = await getSchedules()
        // console.log(schedules)
        setSchedules(schedules.data)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        if (error?.response?.status === 403) {
          setRestricted(true)
        }
        setFetchError(true)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    }
    const fetchDeviceGroups = async () => {
      try {
        setIsLoading(true)
        const deviceGroups = await getDeviceGroups()
        // console.log(deviceGroups)
        setDeviceGroups(deviceGroups.data)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        if (error?.response?.status === 403) {
          setRestricted(true)
        }
        setFetchError(true)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    }
    const fetchPlaylists = async () => {
      try {
        setIsLoading(true)
        const playlists = await getPlaylists()
        // console.log(playlists)
        setPlaylists(playlists.data)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        if (error?.response?.status === 403) {
          setRestricted(true)
        }
        setFetchError(true)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    }

    fetchSchedules()
    fetchDeviceGroups()
    fetchPlaylists()
    setParamError(false)
  }, [reloadContent])

  return (
    <>
      <Box sx={{}}>
      <Box sx={{
        padding: 3
      }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Schedules</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Description sx={{
              fontSize: 1,
              color: 'fg.muted',
            }}>
              Schedules are used to assign playlists to device groups for a specific time period. 
            </PageHeader.Description>
          </PageHeader>
        </Box>
        
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            width: '100%',
            height: '100%',
            marginTop: 4,
            // backgroundColor: 'canvas.default',
          }}
        >
          {restricted && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                justifyItems: 'center',
                position: 'relative',
                // backgroundColor: 'canvas.default',
                alignContent: 'center',
                alignItems: 'center',
                gap: 3,
                mt: 4,
              }}
            >
              <Text
                sx={{
                  fontSize: 4,
                  fontWeight: 'bold',
                  // color: 'danger.',
                  textAlign: 'center',
                }}
              >
                You don't have access to this content.
              </Text>
              <Text
                sx={{
                  fontSize: 1,
                  color: 'fg.muted',
                }}
              >
                <Icons.Alert /> Insufficient permissions to manage playlists.
              </Text>
              {/* <LinkButton variant="primary" as="a" href="/dashboard/devices">
              Retry
            </LinkButton> */}
              {/* <LinkButton variant="invisible" href="#">
              Installation instructions
            </LinkButton> */}
            </Box>
          )}

          {isLoading && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                justifyItems: 'center',
                position: 'relative',
                // backgroundColor: 'canvas.default',
                alignContent: 'center',
                alignItems: 'center',
                gap: 3,
                mt: 4,
              }}
            >
              <Spinner />
            </Box>
          )}

          {fetchError && !restricted && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                justifyItems: 'center',
                position: 'relative',
                // backgroundColor: 'canvas.default',
                alignContent: 'center',
                alignItems: 'center',
                gap: 3,
                mt: 4,
              }}
            >
              <Text
                sx={{
                  fontSize: 4,
                  fontWeight: 'bold',
                  // color: 'danger.',
                  textAlign: 'center',
                }}
              >
                An error occured fetching content.
              </Text>
              <Text
                sx={{
                  fontSize: 1,
                  color: 'fg.muted',
                }}
              >
                <Icons.Alert /> Please try again later.
              </Text>
              {/* <LinkButton variant="primary" as="a" href="/dashboard/devices">
              Retry
            </LinkButton> */}
              {/* <LinkButton variant="invisible" href="#">
              Installation instructions
            </LinkButton> */}
            </Box>
          )}

          {paramError && !restricted && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                justifyItems: 'center',
                position: 'relative',
                // backgroundColor: 'canvas.default',
                alignContent: 'center',
                alignItems: 'center',
                gap: 3,
                mt: 4,
              }}
            >
              <Text
                sx={{
                  fontSize: 4,
                  fontWeight: 'bold',
                  // color: 'danger.',
                  textAlign: 'center',
                }}
              >
                An error occured fetching content.
              </Text>
              <Text
                sx={{
                  fontSize: 1,
                  color: 'fg.muted',
                }}
              >
                <Icons.Alert /> Please try again later.
              </Text>
              {/* <LinkButton variant="primary" as="a" href="/dashboard/devices">
              Retry
            </LinkButton> */}
              {/* <LinkButton variant="invisible" href="#">
              Installation instructions
            </LinkButton> */}
            </Box>
          )}

          {!isLoading && !restricted && !fetchError && !paramError && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                // backgroundColor: 'green'
              }}
            >
              {/* <EmptyState /> */}
              {/* <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}> */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: ['column', 'column', 'row'],
                  width: '100%',
                  height: '100%',
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    // backgroundColor: 'danger.muted',
                    minHeight: '400px',
                    width: ['100%', '100%', '350px'],
                    minWidth: ['100%', '100%', '300px'],
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    border: '1px solid',
                    borderColor: 'border.default',
                    borderRadius: 2,
                  }}
                >
                  <AnchoredOverlay
          open={isOpen}
          onOpen={handleOpen}
          onClose={() => setIsOpen(false)}
          width='large'
          height='xsmall'
          focusTrapSettings={{
            initialFocusRef: inputRef,
          }}
          side='inside-top'
          renderAnchor={props => (
            <Box sx={{
              padding: 3,
            }}>
            <Button {...props} sx={{
              width: '100%',
            }} trailingVisual={Icons.SearchIcon}>
              Find schedules
            </Button>
            </Box>
          )}
        >
          <Autocomplete>
            <Box display='flex' flexDirection='column' height='100%'>
              <Box
                borderWidth={0}
                borderBottomWidth={1}
                borderColor='border.default'
                borderStyle='solid'
              >
                <Autocomplete.Input
                  ref={inputRef}
                  sx={{
                    display: 'flex',
                    border: '0',
                    paddingX: 3,
                    paddingY: 1,
                    boxShadow: 'none',
                    ':focus-within': {
                      border: '0',
                      boxShadow: 'none',
                    },
                  }}
                  {...textInputArgs}
                  size={textInputArgs.inputSize}
                  block
                />
              </Box>
              <Box overflow='auto' flexGrow={1}>
                <Autocomplete.Menu
                  items={schedules.map(item => ({
                    text: item.name,
                    id: item.id,
                  }))}
                  selectedItemIds={[]}
                  customScrollContainerRef={scrollContainerRef}
                  aria-labelledby='autocompleteLabel'
                  {...menuArgs}
                  onSelectedChange={handleAutocompleteSelect}
                />
              </Box>
            </Box>
          </Autocomplete>
        </AnchoredOverlay>
                  <NavList>
                    <NavList.Item
                      key='new'
                      aria-current={scheduleIdParam === 'new'}
                      onClick={() => handleScheduleClick('new')}
                    >
                      <Icons.Add />
                      <Text
                        sx={{
                          ml: 1,
                        }}
                      >
                        New schedule
                      </Text>
                    </NavList.Item>
                    <NavList.Item defaultOpen={true}>
                      All schedules
                      <NavList.SubNav>
                        {schedules.length > 0 &&
                          schedules.map(schedule => (
                            <NavList.Item
                              key={schedule.id}
                              aria-current={
                                scheduleIdParam === schedule.id.toString()
                              }
                              onClick={() => handleScheduleClick(schedule.id)}
                            >
                              <Tooltip aria-label={schedule.name}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    gap: 1,
                                  }}
                                >
                                  <Icons.Calendar />
                                  <Text>
                                    {schedule.name.length > 25
                                      ? `${schedule.name.slice(0, 25)}...`
                                      : schedule.name}
                                  </Text>
                                </Box>
                              </Tooltip>
                            </NavList.Item>
                          ))}
                      </NavList.SubNav>
                    </NavList.Item>
                  </NavList>
                </Box>

                {scheduleIdParam !== 'new' &&
                  !restricted &&
                  !fetchError &&
                  !paramError &&
                  !isLoading &&
                  !schedulePreview && (
                    <Box
                      sx={{
                        height: '100%',
                        minHeight: '400px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // backgroundColor: 'accent.muted',

                        border: '1px solid',
                        borderColor: 'border.default',
                        borderRadius: 2,
                      }}
                    >
                      <Text color='fg.muted' fontSize={1}>
                        No schedule selected
                      </Text>
                    </Box>
                  )}

                <Box
                  sx={
                    {
                      // backgroundColor: 'attention.muted',
                    }
                  }
                >
                  {schedulePreview && (
                    <Box
                      sx={{
                        height: '100%',
                        // width: ['100%', '100%', 'calc(100% - 350px)'],
                        width: '100%',
                        border: '1px solid',
                        borderColor: 'border.default',
                        borderRadius: 2,
                        padding: 3,
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: '300px',
                        }}
                      >
                        <Text
                          sx={{
                            fontSize: 2,
                            fontWeight: 'bold',
                            color: 'fg.default',
                          }}
                        >
                          {scheduleId && scheduleId !== 'new'
                            ? 'Edit schedule'
                            : 'Create new schedule'}
                        </Text>
                      </Box>
                      <Box>
                        <Box>
                          {/* Schedules are used to assign playlists to device groups for a specific time period. */}

                          {scheduleId && scheduleId !== 'new' ? (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: ['column', 'column', 'row'],
                                gap: 3,
                                mb: 3,
                              }}
                            >
                              <Text
                                sx={{
                                  fontSize: 1,
                                  color: 'fg.muted',
                                }}
                              >
                                Your updates will be applied instantly at the
                                given time of the schedule. Devices will remain
                                on the last playlist until the new schedule
                                starts.
                              </Text>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: ['column', 'column', 'row'],
                                gap: 3,
                                mb: 3,
                              }}
                            >
                              <Text
                                sx={{
                                  fontSize: 1,
                                  color: 'fg.muted',
                                }}
                              >
                                The schedule will be available instantly for the
                                given device group at the specified time.
                              </Text>
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            mt: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                          }}
                        >
                          <FormControl required id='name'>
                            <FormControl.Label htmlFor='name'>
                              Name
                            </FormControl.Label>
                            <TextInput
                              value={nameValue}
                              onChange={handleInputChange}
                              name='name'
                              placeholder='Name'
                              sx={{
                                width: '100%',
                              }}
                            />
                            {validationResult === 'empty' && (
                              <FormControl.Validation variant='error'>
                                Name cannot be empty
                              </FormControl.Validation>
                            )}
                            {validationResult === 'tooLong' && (
                              <FormControl.Validation variant='error'>
                                Name cannot be longer than 255 characters
                              </FormControl.Validation>
                            )}
                            {validationResult === 'tooShort' && (
                              <FormControl.Validation variant='error'>
                                Name cannot be less than 3 characters
                              </FormControl.Validation>
                            )}
                            {validationResult === 'validName' && (
                              <FormControl.Validation variant='success'>
                                Valid name
                              </FormControl.Validation>
                            )}
                            <FormControl.Caption>
                              Please enter a name (3 to 255 characters).
                            </FormControl.Caption>
                          </FormControl>

                          <FormControl required id='start-date-time'>
                            <FormControl.Label htmlFor='end-date-time'>
                              Start date time
                            </FormControl.Label>
                            <TextInput
                              type='datetime-local'
                              name='start-date-time'
                              // defaultValue={startValue}
                              // defaultChecked={true}
                              onChange={handleStartDateChange}
                              value={startValue}
                              sx={{
                                width: '100%',
                              }}
                            />
                            {validationStartDateTimeResult === 'empty' && (
                              <FormControl.Validation variant='error'>
                                Start date time cannot be empty
                              </FormControl.Validation>
                            )}
                            {validationStartDateTimeResult === 'valid' && (
                              <FormControl.Validation variant='success'>
                                Valid start date time
                              </FormControl.Validation>
                            )}
                          </FormControl>

                          <FormControl required id='end-date-time'>
                            <FormControl.Label htmlFor='start-date-time'>
                              End date time
                            </FormControl.Label>
                            <TextInput
                              type='datetime-local'
                              name='end-date-time'
                              onChange={handleEndDateChange}
                              value={endValue}
                              sx={{
                                width: '100%',
                              }}
                            />
                            {validationEndDateTimeResult === 'empty' && (
                              <FormControl.Validation variant='error'>
                                End date time cannot be empty
                              </FormControl.Validation>
                            )}
                            {validationEndDateTimeResult === 'valid' && (
                              <FormControl.Validation variant='success'>
                                Valid end date time
                              </FormControl.Validation>
                            )}
                          </FormControl>

                          <FormControl required>
                            <FormControl.Label>Device group</FormControl.Label>
                            {deviceGroups.length > 0 ? (
                              <>
                                <Select
                                  value={deviceGroupValue}
                                  onChange={handleDeviceGroupChange}
                                  sx={{
                                    width: '100%',
                                  }}
                                >
                                  <Select.Option
                                    value='choose_device_group'
                                    disabled
                                  >
                                    Choose device group
                                  </Select.Option>
                                  {deviceGroups.map(deviceGroup => (
                                    <Select.Option
                                      key={deviceGroup.id}
                                      value={deviceGroup.id}
                                    >
                                      {deviceGroup.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                                {validationDeviceGroupResult === 'empty' && (
                                  <FormControl.Validation variant='error'>
                                    Device group cannot be empty
                                  </FormControl.Validation>
                                )}
                                {validationDeviceGroupResult === 'valid' && (
                                  <FormControl.Validation variant='success'>
                                    Valid device group
                                  </FormControl.Validation>
                                )}
                              </>
                            ) : (
                              <Text sx={{ color: 'attention.emphasis' }}>
                                {' '}
                                <Icons.Alert /> No device groups found
                              </Text>
                            )}
                          </FormControl>

                          <FormControl required>
                            <FormControl.Label>Playlist</FormControl.Label>
                            {playlists.length > 0 ? (
                              <>
                                <Select
                                  value={playlistValue}
                                  onChange={handlePlaylistChange}
                                  sx={{
                                    width: '100%',
                                  }}
                                >
                                  <Select.Option
                                    value='choose_playlist'
                                    disabled
                                  >
                                    Choose playlist
                                  </Select.Option>
                                  {playlists.map(playlist => (
                                    <Select.Option
                                      key={playlist.playlist.id}
                                      value={playlist.playlist.id}
                                    >
                                      {playlist.playlist.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                                {validationPlaylistResult === 'empty' && (
                                  <FormControl.Validation variant='error'>
                                    Playlist cannot be empty
                                  </FormControl.Validation>
                                )}
                                {validationPlaylistResult === 'valid' && (
                                  <FormControl.Validation variant='success'>
                                    Valid playlist
                                  </FormControl.Validation>
                                )}
                              </>
                            ) : (
                              <Text sx={{ color: 'attention.emphasis' }}>
                                {' '}
                                <Icons.Alert /> No playlist found
                              </Text>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            // backgroundColor: 'canvas.default',
                            width: '100%',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 2,
                            }}
                          >
                            {scheduleId && scheduleId !== 'new' ? (
                              <Button
                                variant='danger'
                                onClick={() =>
                                  setIsOpenDeleteScheduleDialog(true)
                                }
                              >
                                Delete
                              </Button>
                            ) : null}
                            {/* <Button variant="primary">Save</Button> */}
                            {/* <Text>{scheduleId}</Text>  */}
                            {scheduleId && scheduleId !== 'new' ? (
                              <Button
                                variant='primary'
                                disabled={isLoading}
                                onClick={() => {
                                  handleSubmitUpdateSchedule()
                                }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                  alignItems: 'center',
                                  width: '65px', // Set a fixed width
                                }}
                              >
                                {isLoading ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      width: '47px',
                                    }}
                                  >
                                    <Spinner size='small' />
                                  </Box>
                                ) : (
                                  <Text>Update</Text>
                                )}
                              </Button>
                            ) : (
                              <Button
                                variant='primary'
                                disabled={isLoading}
                                onClick={() => {
                                  handleSubmitCreateSchedule()
                                }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                  alignItems: 'center',
                                  width: '65px', // Set a fixed width
                                }}
                              >
                                {isLoading ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      width: '47px',
                                    }}
                                  >
                                    <Spinner size='small' />
                                  </Box>
                                ) : (
                                  <Text>Submit</Text>
                                )}
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>

              {/* <PageLayout>
  <PageLayout.Pane
    position="start"
    width={{ min: '256px', default: 'medium', max: '400px' }} // Example widths
    sx={{
      '@media (max-width: 768px)': {
        width: '100%', // Make the pane full width on small screens
      }
    }}
  >
    aaa
  </PageLayout.Pane>
  <PageLayout.Content
    sx={{
      '@media (max-width: 768px)': {
        marginTop: '20px', // Add space between pane and content on small screens
      }
    }}
  >
    asdf
  </PageLayout.Content>
</PageLayout> */}
            </Box>
          )}
        </Box>
      </Box>
      {isOpenDeleteScheduleDialog && (
        <Dialog
          isOpen={isOpenDeleteScheduleDialog}
          onDismiss={onDialogCloseDeleteScheduleDialog}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Delete schedule</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' color='fg.muted'>
              This action can't be undone. Are you sure you want to delete this
              schedule?
            </Text>
            <Box mt={3}></Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogCloseDeleteScheduleDialog}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='danger'
                disabled={isLoading}
                onClick={() => {
                  handleSubmitDeleteSchedule()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '120px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Delete schedule</Text>
                )}
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  )
}
