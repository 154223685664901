import { Flash, Text } from "@primer/react";

export default function FlashMessage({ message, variant }) {
  return (
    <Flash variant={variant} sx={{
      textAlign: 'center'
    }}>
      <Text>{message}</Text>
    </Flash>
  );
}
