import { Box, Text, ProgressBar } from '@primer/react'
// import PropTypes from 'prop-types'

function KPICard({
  title,
  message,
  showProgressBar,
  progressBarItem,
  progressBarTotal,
  itemColor,
  totalColor,
}) {
  const progressBar = showProgressBar ? (
    <ProgressBar
      sx={{
        width: '100%',
        height: '5px',
        backgroundColor: totalColor, // Using totalColor for the background color
      }}
      aria-valuenow={(progressBarItem / progressBarTotal) * 100}
      aria-label={`${title} progress`}
    >
      <ProgressBar.Item
        progress={Math.round((progressBarItem / progressBarTotal) * 100)}
        sx={{ backgroundColor: itemColor }} // Using itemColor for the progress item
      />
    </ProgressBar>
  ) : null

  return (
    <Box
      sx={{
        p: 3,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'border.default',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '110px', // Set a minimum height of 200px
        gap: 2,
      }}
    >
      <Text
        sx={{
          fontSize: 1,
          color: 'fg.muted',
        }}
      >
        {title}
      </Text>
      <Text
        sx={{
          fontSize: 3,
          fontWeight: 'bold',
        }}
      >
        {message}
      </Text>
      {progressBar}
    </Box>
  )
}

// KPICard.propTypes = {
//   title: PropTypes.string.isRequired,
//   message: PropTypes.string.isRequired,
//   showProgressBar: PropTypes.bool,
//   progressBarItem: PropTypes.number,
//   progressBarTotal: PropTypes.number,
//   itemColor: PropTypes.string, // Validate itemColor as a string
//   totalColor: PropTypes.string, // Validate totalColor as a string
// }

export default KPICard
